import { fetchZhanData } from "@/api/fetch-res";
import {
  IActivityInfoParams,
  IActivityParams,
  IActivityCollectionParams,
  IActivitySelectionParams,
} from "@/types/activity";

function fetchActivity(params: IActivityParams): Promise<any> {
  return fetchZhanData("/square/v1/activity", params, {});
}

function fetchInfo(params: IActivityInfoParams): Promise<any> {
  return fetchZhanData(`/square/v1/activity/${params.id}`, params, {});
}

function fetchSelection(params: IActivitySelectionParams): Promise<any> {
  return fetchZhanData(`/square/v1/selection`, params, {});
}

function fetchCollection(params: IActivityCollectionParams): Promise<any> {
  return fetchZhanData(`/square/v1/collection`, params, {});
}

export default {
  fetchActivity,
  fetchInfo,
  fetchSelection,
  fetchCollection,
};
