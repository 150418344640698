// 管理上传列表，控制同事上传数
class EventEmitter {
  events: any;

  constructor() {
    // 事件对象，存放订阅的名字和事件
    this.events = {};
  }
  // 订阅事件的方法
  // 本地上传 localupload, etc.
  on (eventName: string, callback: any) {
    if (!this.events[eventName]) {
      this.events[eventName] = [callback]
    } else  {
      this.events[eventName].push(callback)
    }
    // console.log("after on, current event", this.getAllEvent())
  }
  // 触发事件的方法
  emit (eventName: string, idx: number, arg: any) {
    // 修改了遍历发布流程，自定义执行对应关系的cb, 异步执行
    if (this.events[eventName]) {
      setTimeout(() => {
        this.events[eventName][idx](arg)
      }, 0);
    }
  }
  // 移除订阅事件
  off (eventName: string, idx: number) {
    if (this.events[eventName]) {
      this.events[eventName] = this.events[eventName].filter((cb: any, _idx: number) => {
        return _idx !== idx
      })
    }
    // console.log("after off, current event", this.getAllEvent())
  }
  // 获取事件
  getEvent (eventName: string) {
    return this.events[eventName]
  }

  getAllEvent () {
    return this.events
  }
}

export default new EventEmitter()
