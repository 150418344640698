import { getStore } from '@/plugins/vuex-store'

/**
 * 获取用户信息
 * @param {array} infos - 需要的用户信息
 * @return {Promise}
 * 支持以下参数
 *  userId
    sessionId
    userNick
    userName
    peerid 如果在客户端，获取的是客户端peerid，web端为账号构造的
    thunder 迅雷版本号
    thunderComponent 迅雷浏览器组件版本号
 */
export default function getUserInfo (infos = ['peerid', 'userid', 'version', 'sessionid', 'isNative']) {
  const storeUserInfo = getStore().state.user.curUser
  const userInfo = Object.assign({}, storeUserInfo, {
    version: storeUserInfo.thunder || '',
    userid: storeUserInfo.userId || '0',
    sessionid: storeUserInfo.sessionId || '',
    peerid: storeUserInfo.peerId || 'AAAAAAAAAAAAAAAA',
    isNative: getStore().state.isNative
  })

  return Promise.resolve(infos.map(key => userInfo[key]))
}

        export function pubiosrKDWbtIUVnTpEfBdo0fF3PBsXeqvp (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'E4', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/code-res.ts').pubuDUfUGVxR1fOp2USDhn12dJ7EGtFuEUl;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubK3SoOTRhrpzVVEp8ChcnoDtpl4msjxNn (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/xl-client-common.ts').pubzVGs9ZmrmDR5OHl1CwZQBoIEyMn60q7K;
            const nextPart2 = require('@/utils/get-user-info.ts').pubT4MHvP2bhixEZGSAiRrsXgtICHjP6C72;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubSv9lyrGHrAVmzX9ERgdHxCFU4rMiUk6y (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/drive-util.ts').pubM0zPj5j0j5GbxqeAmix23kivMLl8DFPH;
            const nextPart2 = require('@/utils/error-message.ts').pubIkA1Wp2YsEm7TIaqxB9XMID6xBhPf1fO;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pub8wnLCtE9dDsXIqktQaqyaG1i3F6NvkWR (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/sso.ts').pubjl6XrBVE4VOCQtNzROe5jKiA85uc4XUF;
            const nextPart2 = require('@/utils/static-icons.ts').pubi67JkFsgtI31GSRlloTU4yi0qw8uGzcb;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubq8RTtTUv7BxDO1VnUqXCB4QqMgdQr3EZ (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/xl-client-common.ts').pubz8M4JXE8Vx4WzwHP2xytA6fQpVLfcwS7;
            const nextPart2 = require('@/utils/util.ts').pubrNFYdsrWDnCSLwZ1Hv2BH57JuXYTCSsZ;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubTrt4O1LnEugqo8OmC0HxVVpNGiRY8ChE (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/stat.ts').pub6qb0CJD2WLkArMj67AQoOjfY2CAJLID5;
            const nextPart2 = require('@/utils/sso.ts').pubLEaOMTafq0KfpCvYq26FrULoY3ILDTeu;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubVX8DtiSwaoxASLTHoaETCE2yoRjHPWW4 (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'a4RDdG46iBqA51GI5zTV5B5y', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/static-icons.ts').pubOZ88sJ1EGqZO0EhjrWpVZxWt2IHoW0wY;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubVrSiFxyN11ikpGJuNgGt5qfrVzLLIR2J (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: '1n', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/static-icons.ts').pubc3HFOwfwiVkU3ObWVtDuFaokdziCZSIz;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pub2UasERqZIXkJyjTPNtAaLw16QtZsdWyN (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'pAI8YsyKUx6t5rdylMfAafr1nBSe07TUkFEZuPcmTb2', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/filters.ts').pubSFMTnWKgVA8x3wCh2WpSaWkRbAMRoEWU;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
          export function pubZJLWXTe17i9jjjD5vAHtIQYlp8Zg5vWD (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: 'Lw6QiCYeN+Sb1dBd6WDihgHae9wT', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/error-message.ts').pubFIQZrMs6O47aEglEI61fgS0RrprzuNY7;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }