import { Middleware } from '@nuxt/types'

const userAgentMiddleware: Middleware = (context: any) => {
  console.log('----user-agent context------', context)
  // Add the userAgent property to the context
  context.userAgent = process.server
    ? context.req.headers['user-agent']
    : navigator.userAgent
  console.log('----user-agent------', context)
}

export default userAgentMiddleware
