import { render, staticRenderFns } from "./MobilePay.vue?vue&type=template&id=c3f2c69e&"
import script from "./MobilePay.vue?vue&type=script&lang=ts&"
export * from "./MobilePay.vue?vue&type=script&lang=ts&"
import style0 from "@/assets/css/pay.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/var/static/components/Header.vue').default})
