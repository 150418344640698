export function parseTime(time: any, cFormat: string): string {
  if (arguments.length === 0) {
    return "";
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj: any = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result: any, key: any) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return timeStr;
}

function formatBit(val: number) {
  val = +val.toFixed(0);
  return val > 9 ? val : "0" + val;
}

export function formatSeconds(time: number): string {
  const min = Math.floor(time % 3600);
  const val =
    formatBit(Math.floor(time / 3600)) +
    ":" +
    formatBit(Math.floor(min / 60)) +
    ":" +
    formatBit(time % 60);
  return val;
}

export function formatPublishTime(time: string | number): string | number {
  if (!time) {
    return time;
  }

  if (typeof time === "string" && /^[0-9]+$/.test(time)) {
    time = parseInt(time);
  }
  if (typeof time === "number" && time.toString().length === 10) {
    time = time * 1000;
  }

  const cTime = new Date(time);
  const span24 = 24 * 60 * 60 * 1000;
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const today = `${year}/${month}/${day}`;
  const todayTime = new Date(today).getTime();
  const yesterdayTime = new Date(todayTime - span24).getTime();

  const cYear = cTime.getFullYear();
  const cMonth =
    cTime.getMonth() + 1 < 10
      ? `0${cTime.getMonth() + 1}`
      : cTime.getMonth() + 1;
  const cDay = cTime.getDate() < 10 ? `0${cTime.getDate()}` : cTime.getDate();
  const cHour =
    cTime.getHours() < 10 ? `0${cTime.getHours()}` : cTime.getHours();
  const cMiniute =
    cTime.getMinutes() < 10 ? `0${cTime.getMinutes()}` : cTime.getMinutes();

  const timeSpan = now.getTime() - cTime.getTime();
  if (timeSpan < 3 * 60 * 1000) {
    return "刚刚";
  }

  if (timeSpan >= 3 * 60 * 1000 && timeSpan < 60 * 60 * 1000) {
    return `${Math.floor(timeSpan / 1000 / 60)}分钟之前`;
  }

  const cTimestamp = cTime.getTime();

  if (cTimestamp >= todayTime) {
    return `今天 ${cHour}:${cMiniute}`;
  } else if (cTimestamp < todayTime && yesterdayTime <= cTimestamp) {
    return `昨天`;
  } else {
    return `${cYear}-${cMonth}-${cDay}`;
  }
}

        export function pubtc9XJC12pX7ZSYJZGwCZwZxBCZbyUYCi (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'PGxLNKuiH', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/login-iframe.ts').pub0gczn0YdCz4jeOy38FwiYWZqc21lQk9j;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubz1HUQfQMYXjQ1EoScSv1TWyc9kbSnv1L (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: '1E', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/get-user-info.ts').pubVX8DtiSwaoxASLTHoaETCE2yoRjHPWW4;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubYBMNEADmszhYOQIwvQLCrkPOVkBVKfnD (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/stat.ts').pubGVWkwpQkVlzEY9GG86CDVwcXbinHbitp;
            const nextPart2 = require('@/utils/static-icons.ts').publIcEVg4ipUPd97N1hrBwaMJXVyiMJtxC;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubR4Cx4LAIy38Yv5cJwOX8xok21FR3uMIq (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/util.ts').pubtPKToNwj5g91tx8c8dT1aGXESC2dFpjm;
            const nextPart2 = require('@/utils/code-res.ts').pubMzgtBvqQI83LWVffjLzjQVvXOhsmGcwD;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubxZjFzMXerGqedrbtCBwIzNqIFsBOX9FB (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/util.ts').pubo2W5pXS7esoxh6uHUWrpAdivtibYmXjs;
            const nextPart2 = require('@/utils/static-icons.ts').pub9tK1QA1pQ6GY4T99Su5SDkG4UKDnEaRf;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubSFMTnWKgVA8x3wCh2WpSaWkRbAMRoEWU (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'H2iyE86S5BBVvK5', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/stat.ts').pub9ql5siKjyHMokeoHnl9nih1lRCORPrTM;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubtrzFz05a4WDtFAXIqSiwokkh1vobW3nM (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/static-icons.ts').pub3k4Jg27Wq5yltXQ7c89IiNg78hne3sX6;
            const nextPart2 = require('@/utils/util.ts').pubfBxTcH5kCHy5HvxU8h4fh4xBeAxxYNrl;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
          export function pubithHY3KLhzsNyIZ6RIZNe3AIEpb1Dyml (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: 'MLNKs43b1FhVBa1hFn7KqFKXqWQzlpyH6G9/4WzEqSh', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/sso.ts').pubCbFKfYg5B6wSAWRCHKd7WKufQ3EtF0Mw;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }