
















import Vue from "vue";
import SiteHeader from "@/components/Header.vue";

export default Vue.extend({
  components: {
    SiteHeader,
  },
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    message() {
      if (this.error.statusCode === 404) {
        return "您访问的网站飞到了外太空";
      }
      return this.error.message || "您访问的网站飞到了外太空";
    },
  },
  mounted() {},
  methods: {},
});
