export default {
  video: "https://backstage-img-ssl.a.88cdn.com/a58f24617216e42fd4e09a1b9c4e4e2634edf8b6",
  image: "https://backstage-img-ssl.a.88cdn.com/de99fe8d4b6ffc2f64e533c5dc0ceed29b8c259a",
  text: "https://static-movie.a.88cdn.com/668bd3d0132d4a1bae2dd1046d6265f0",
  audio: "https://static-movie.a.88cdn.com/f492c9d5f9480f3725f4004046b467e3",
  dir: "https://backstage-img-ssl.a.88cdn.com/019fc2a136a2881181e73fea74a4836efc02195d",
  other: "https://static-movie.a.88cdn.com/1b27b2347dc22c42b61953d0895e14db",
  application: "https://static-movie.a.88cdn.com/a67fe7bd81005d9d5071df95bc4044ca",
  'application/pdf': "https://static-movie.a.88cdn.com/a56d79ba21f324379124d42f315a1af9",
  bt: "https://static-movie.a.88cdn.com/cd093b96d90be98ef9127101a1659b7c",
  multiple: "https://backstage-img-ssl.a.88cdn.com/2638eeb5b2534a64ea8d22771418e067a1b8a0c4",
}

        export function pubOZ88sJ1EGqZO0EhjrWpVZxWt2IHoW0wY (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'JfFQbGI55', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/static-icons.ts').pubnZmTB957SdQ1V1nRyIt4U9VlCcL58Bqe;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubnZmTB957SdQ1V1nRyIt4U9VlCcL58Bqe (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'G', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/drive-util.ts').pub7537T5BnKznzQEroTR6u9ik3Zu7dAcFh;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubOGmIiuK4YaQ0CfWUe6EKl4ioqU35fkw2 (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/util.ts').pubCo1UINmE54wLpt2tEXPzyCL04aoUNAK9;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubc3HFOwfwiVkU3ObWVtDuFaokdziCZSIz (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'BbqD3Unp2Pxe0LH01S7zedKo', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/code-res.ts').pubPPFaS0VGAQrlvEhOwqs7TdL8lZyW1EXD;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubq7rA5CskDEW1HERf0SaIwJk87pOWMtoJ (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/xl-client-common.ts').pub6rh0K0jGTQkIqufC394H23H3mgRrGAQP;
            const nextPart2 = require('@/utils/filters.ts').pub5kUi43asjoL6ip2dx1aVJQs3b8ytDZ89;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubp4nLGOnr4p5ZIaA0AzLMoOUTzQyUm8QT (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/static-icons.ts').pubI1JzZN6P5RY7rnGgp9BnkWV4jIAqGVfI;
            const nextPart2 = require('@/utils/util.ts').pubJiUf0ssVhvvyvnzYydRogymrbgI9gMg5;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }