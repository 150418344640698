import Vue from 'vue'
// import { Dialog, Radio, Input, Tabs, Tooltip, Message, Checkbox } from '@xunlei/thunder-ui-vue'
import ThunderUIVue from '@xunlei/thunder-ui-vue'

// Vue.use(Dialog)
// Vue.use(Radio)
// Vue.use(Input)
// Vue.use(Tabs)
// Vue.use(Tooltip)
// Vue.use(Message)
// Vue.use(Checkbox)
Vue.use(ThunderUIVue)
