




























import Vue from "vue";
import { parseTime } from "@/utils/filters";

export default Vue.extend({
  name: "vip-pay-header",
  props: {},
  data() {
    return {};
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    isVip() {
      return this.curUser.vipData && this.curUser.vipData.isVip;
    },
    expiredAt() {
      return this.curUser.vipData && this.curUser.vipData.expiredAt;
    },
  },
  mounted() {},
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    parseTime(time: string) {
      return parseTime(time, "{y}-{m}-{d}");
    },
  },
});
