import axios from 'axios'
import { fetchDataForDrive, fetchDataWithShoulei } from '@/api/fetch-res'

function getShare (params: any, withCaptcha: any, withCredentials: any): Promise<any> {
  return fetchDataForDrive('share', params, { withCaptcha, withCredentials })
}

function getShareMinProgramQrCode(data: any): Promise<any> {
  return fetchDataWithShoulei('/xlppc.wedrive.api/api/wechat/qrcode', data, {
    responseType: 'blob',
    method: 'post'
  })
}

function postShare (params: any): Promise<any> {
  return fetchDataForDrive('share', params, {method: 'post'})
}

function getDetail (params: any, withCredentials: any): Promise<any> {
  return fetchDataForDrive('share/detail', params, { withCredentials })
}

function getPlayUrl (params: any): Promise<any> {
  return fetchDataForDrive('share/play_url', params)
}

function postRestore (params: any): Promise<any> {
  return fetchDataForDrive('share/restore', params, {method: 'post'})
}

function getWechatConfig ({ url }: any): Promise<any> {
  return axios({
    url: `https://weixinapi-m-ssl.xunlei.com/wx/sign/js_api?page_url=${url || location.href}`,
    method: 'GET'
  })
}

function getFileInfo (params: any): Promise<any> {
  return fetchDataForDrive('share/file_info', params)
}

function checkRestore (params: any): Promise<any> {
  return fetchDataForDrive('tasks', params)
}

function checkRestoreStatus (id: string): Promise<any> {
  return fetchDataForDrive('tasks/' + id)
}

// 已分享的分享列表
function getSharedList (params: any, withCaptcha: any, withCredentials: any): Promise<any> {
  return fetchDataForDrive('share/list', params, { withCaptcha, withCredentials })
}

// 删除已分享的链接
function deleteShare (params: any): Promise<any> {
  return fetchDataForDrive('share/delete', params, {method: 'post'})
}


export default {
  getShare,
  postShare,
  getDetail,
  getPlayUrl,
  postRestore,
  getWechatConfig,
  getFileInfo,
  checkRestore,
  checkRestoreStatus,
  getSharedList,
  deleteShare,
  getShareMinProgramQrCode
}
