










import Vue from "vue";
import LazyImg from "@/components/LazyImg.vue";
import constants from "@/utils/constants";

export default Vue.extend({
  name: "avatar",
  components: {
    LazyImg,
  },
  props: {
    src: {
      type: String,
      default: "",
    },
    fallback: {
      type: String,
      default: constants.DEFAULT_AVATAR_URL,
    },
    alt: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      defaultAvatar: constants.DEFAULT_AVATAR_URL,
    };
  },
});
