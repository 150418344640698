import { LogLevel } from "@xbase/sdk";

const SITE_HOST = "zhan.xunlei.com";
const host = process.client ? location.host : SITE_HOST;
const API_ENV = process.env.API_ENV || "production";
const isDev = API_ENV !== "production" && API_ENV !== "preview";

console.log("⚙️ global api env >>>", isDev);

const clientConfig = {
  "zhan.xunlei.com": {
    clientId: isDev ? "YJoupAyXbQMUCAVX" : "YLSHjcKieOUmrku7",
    packageName: "zhan.xunlei.com",
    signKey: isDev ? "Sa)skt^syM" : "Sa)skt^syM",
    algVersion: "1",
    funName: "pubjrflzL41lLWkM1DcU2seka6c4S2mGD5z",
    funFile: "error-message",
  },
  "xlfe.zhan.ssr": {
    clientId: isDev ? "YKxkSPho1vLFlHtx" : "YLSonsKieOUmsJqG",
    packageName: "xlfe.zhan.ssr",
    signKey: isDev ? "TM^V)STgbL" : "TM^V)STgbL",
    algVersion: "1",
  },
};

export const apiOriginMap = {
  development: "https://dev-xluser-ssl.xunlei.com",
  testing: "https://dev-xluser-ssl.xunlei.com",
  preview: "https://xluser-ssl.xunlei.com",
  production: "https://xluser-ssl.xunlei.com",
};

export const riskOriginMap = {
  development: "https://risk-account-ssl.office.k8s.xunlei.cn",
  testing: "https://risk-account-ssl.office.k8s.xunlei.cn",
  preview: "https://risk-account-ssl.office.k8s.xunlei.cn",
  // production: "https://risk-account-ssl.xunlei.com",
  production: "https://risk-account-ssl.office.k8s.xunlei.cn",
};

const logLevel = !isDev ? LogLevel["OFF"] : LogLevel["OFF"];

export const serverClientConfig = clientConfig["xlfe.zhan.ssr"];
export const riskApiOrigin = riskOriginMap[API_ENV];

let apiOrigins = [
  "https://xluser-ssl.xunlei.com",
  "https://xluser2-ssl.xunlei.com",
  "https://xluser3-ssl.xunlei.com",
];

if (isDev) {
  apiOrigins = [apiOriginMap[API_ENV]];
}

export default {
  apiOrigins,
  clientId: clientConfig[host].clientId,
  packageName: clientConfig[host].packageName,
  signKey: clientConfig[host].signKey,
  algVersion: clientConfig[host].algVersion,
  authorizePage: `https://i.xunlei.com/center/account/personal/oauth/`,
  scope: "profile offline pan sso user",
  funName: clientConfig[host].funName,
  funFile: clientConfig[host].funFile,
  logLevel: logLevel,
  signOutURI: "https://zhan.xunlei.com/?sso_sign_out=", // TODO: 退出简页
};
