


























import Vue from "vue";
import eventEmitter from "@/utils/eventEmitter";
import eventMap from "@/utils/event-name";
import ssoMixin from "@/mixins/sso";

export default Vue.extend({
  mixins: [ssoMixin],
  data() {
    return {
      list: [
        {
          icon: "z-icon-layers",
          title: "100W+资源下载",
          text: "100W+海量精品付费资源下载",
        },
        {
          icon: "z-icon-dashboard",
          title: "专属通道",
          text: "迅雷专属下载通道",
        },
        {
          icon: "z-icon-crown",
          title: "会员标识",
          text: "尊享独特会员标识",
        },
      ],
    };
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    isVip() {
      return this.curUser.vipData && this.curUser.vipData.isVip;
    },
    payParams() {
      return this.$store.state.vip.payParams;
    },
  },
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    onVipPay() {
      if (!this.hasLogin) {
        this.popLogin("vip");
        return;
      }

      eventEmitter.emit(eventMap.SHOW_PAY_DIALOG, 0, {});
    },
    panelPayStat() {
      this.stat("xiaozhan_vip_topbutton_hover_pay_click", {
        referfrom: "v_wap_xiaozhan_ggong_top_button_panel",
        aidfrom: this.payParams.aidfrom,
      });

      this.$store.commit("vip/SET_PAY_PARAMS", {
        referfrom: "v_wap_xiaozhan_ggong_top_button_panel",
      });
    },
  },
});
