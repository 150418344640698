import Vue from "vue";
import PhoneAuthVue from "@/components/PhoneAuth.vue";

interface PluginOptions {}

const PhoneAuth: any = {};

PhoneAuth.install = function (Vue: any, options?: PluginOptions) {
  const PhoneAuthInstance = Vue.extend(PhoneAuthVue);
  let currentDialog: any = null;
  const initInstance = () => {
    currentDialog = new PhoneAuthInstance();
    const dialogElement = currentDialog.$mount().$el;
    document.body.appendChild(dialogElement);
  };

  Vue.prototype.$phoneAuth = {
    showDialog(options: any) {
      if (!currentDialog) {
        initInstance();
      }

      currentDialog.params = options;
      return currentDialog
        .showDialog()
        .then((val: string) => {
          currentDialog = null;
          return Promise.resolve(val);
        })
        .catch((err: any) => {
          currentDialog = null;
          return Promise.reject(err);
        });
    },
  };
};

Vue.use(PhoneAuth);
