import { SSO } from "@xbase/sso";
import config from "@/xbase-sdk.config";
import genDeviceInfo from "@/utils/gen-device-info";

const defaultInstance = {
  signIn: () => {},
  signOut: () => {},
  request: () => {},
  init: () => {},
  auth: {} as any,
  oAuth2Client: {} as any,
  signInByIFrame: () => {},
};

const sso = process.client
  ? (async () => {
      const signInRedirectURI = `https://zhan.xunlei.com/blank/?sign_redirect_to=${encodeURIComponent(
        window.location.href
      )}`;

      const deviceInfo = await genDeviceInfo();
      const ssoConfig = {
        ...config,
        statInfo: {
          deviceId: deviceInfo.deviceID,
        },
        signInRedirectURI,
      };

      console.log("🔥 new sso config >>>", ssoConfig);
      return new SSO(ssoConfig);
    })()
  : Promise.resolve(defaultInstance);

export default sso;

        export function pubnOUyboxiqaLdkWwghFWEKFOiUvT9iu4q (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: '2twa0YQO0M3g6iThlEBjgE72f', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/util.ts').pubqI8uIvzeyQVdERuxAhvqscS3ggGmx71M;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubOt14oknEzMlhHJ5IMCI6ZD2AnQ5ysGgq (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/get-user-info.ts').pubB2ZiORYmGQNkE3WIXgClRktcXJrxx1R7;
            const nextPart2 = require('@/utils/drive-util.ts').pubhORkelNYdCEQMfilT1jxZVSK7V6NPMoJ;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubugVCaG9eAg3JkjVJQbNqz4b4tDv7Xwtd (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'EHJxAURVs4EzgRqiNkxTiJiIRZsM', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/get-user-info.ts').pubiosrKDWbtIUVnTpEfBdo0fF3PBsXeqvp;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubuZTmb82z7TKX1nvz9PJtqq0xSkkQl0wR (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'Dl9NjhGJLtnMQOd', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/captcha.ts').pubESB2K4NhoJyni8adUYsndLSnGa9OW42Y;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubbPoXLJFIP8qhnih0enW8d2I55WrCutPa (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'HGDVGJdSy0lfK8cncqhcTneRWW', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/error-message.ts').pub4yPoE2HBDD3NaxaPHz37M1gLIfs75a73;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pub4poLQMbLgdwTud9a3hjEbQdjcJyCB4AN (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: '09b9hQr8BbbYKyqLIhTO9RkejPuq3jq69YOgUClbAoP', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/login-iframe.ts').pubL7qFBM8Q6JR7PN4nu0vqTXyvQWmhKH57;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubtK8l4aXT4qT9icazT4xUZZiBz1hBr57O (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'GnjLJy2dmGHsCqCwvDCp1Hg6tNwla8IgObLh25j1v8cpKL', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/static-icons.ts').pubOGmIiuK4YaQ0CfWUe6EKl4ioqU35fkw2;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubhz9CZqk0Gz1K60u4ljCu2lgyXiOcJn8d (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/code-res.ts').pub8anNjJ8AzaOZWH0Ae3XbYO8DC0KG27xY;
            const nextPart2 = require('@/utils/drive-util.ts').pubEq41TJDpO3afElPmIfgfGSoQAzSIs7pp;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubCBhwqBUx58vRNQcO98SxnIxVzYKECGRB (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/code-res.ts').pub1XxoCPpOYZGdVpZQb8SGdpM4Wnu8QIfR;
            const nextPart2 = require('@/utils/code-res.ts').pubSmhPPIoo5SLFoySlp1l9gjzTLt3skx1Y;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
          export function pubGq0mW3rApZHr0vAJZbU1ZqyPDswAWPLt (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: 'ak36UqBTOx9hiYrBqlCT8u0z', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/code-res.ts').pubXPPflLMGJBf0CXQb54HoACSmAcIIZpEW;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }
          export function pubCbFKfYg5B6wSAWRCHKd7WKufQ3EtF0Mw (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: 'YGvm+PdblFQz8t0', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/error-message.ts').pubYiAEFMCBBWkq4cuuKo9hH8ecFHvaAOn6;
              const nextPart2 = require('@/utils/static-icons.ts').pubnZmTB957SdQ1V1nRyIt4U9VlCcL58Bqe;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }
          export function pubRLKRxhmaRUGSgaZulBQ6DQ9HurKFrkYa (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/error-message.ts').pubWkZbdKe1amHQ2yF9qK4iYMUmcfSDrF3h;
              const nextPart2 = require('@/utils/util.ts').puba3vZHvGzvQBtvsbmUD0mtrep646qzieE;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }