import config from "@/config";
import { fetchJSONP } from "@/api/fetch-res";
import {
  IPayQrcodeParams,
  ICreateOrderParams,
  IScanStatusParams,
  IPayOrderStatusParams,
} from "@/types/vip";
import jsLoader from "@/utils/js-loader";

// 获取会员配置信息
const fetchVipTypes = async () => {
  return jsLoader(
    `https://act-vip-ssl.xunlei.com/source/config/${config.vip.actid}.js`
  );
};

// 生成支付二维码
const genPayQrcode = async (params: IPayQrcodeParams) => {
  return fetchJSONP(
    "https://agent-paycenter-ssl.xunlei.com/payorder/v3/SuperQrcode",
    {
      qs: { ...params },
    }
  );
};

// 查询扫码结果
const checkQrcodeStatus = async (params: IScanStatusParams) => {
  return fetchJSONP(
    `https://agent-paycenter-ssl.xunlei.com/vippay/qrcodeIssucc`,
    {
      qs: { id: params.id },
    }
  );
};

// 查询订单状态
const checkOrderIdStatus = async (params: IPayOrderStatusParams) => {
  return fetchJSONP(
    "https://agent-paycenter-ssl.xunlei.com/payorder/v3/Issucc",
    {
      qs: { ...params },
    }
  );
};

// 移动端调用支付前下单接口
const createPayOrder = async (params: ICreateOrderParams) => {
  return fetchJSONP(
    "https://agent-paycenter-ssl.xunlei.com/payorder/v3/Order",
    {
      method: "POST",
      qs: { ...params },
    }
  );
};

export default {
  fetchVipTypes,
  genPayQrcode,
  checkQrcodeStatus,
  checkOrderIdStatus,
  createPayOrder,
};
