import sso from "@/utils/sso";
import { sleep, getCookie, setCookie, isMacThunder } from "@/utils/util";
import { getSsoStore, getSsoUser, getIOSUserInfo } from "@/api/user";
import { VipRawInfo } from "@/types/user";

export default async function ({
  store,
  app,
  redirect,
  route,
  query,
  req,
}: any) {
  if (process.client) {
    try {
      // 安卓环境
      const { isAndroidNative, sendMessage, isIOSNative, checkMessage } =
        await import("@xunlei/universal-native-api");

      if (isAndroidNative) {
        const ret = await sendMessage(
          {
            platform: "android",
            name: "xlGetUserInfo",
            params: { forceLogin: 0 },
          },
          { callbackTimeout: 600000 }
        );

        console.log("安卓登陆", ret);
        if (!ret.isLogin) {
          return;
          return sendMessage({ platform: "android", name: "xlCloseWindow" });
        }

        const user = ret.userInfo;

        setCookie("cur_userid_xz", user.userID, {
          maxAge: 60 * 60 * 24 * 7,
        });

        return store.commit("user/SET_CURRENT_USER", {
          userId: user.userID,
          sessionId: user.sessionID,
          portrait_url: user.avatarURL,
          nick_name: user.nickName,
          vipData: {}, // TODO: VIP
          xlVipData: {},
          peerid: getCookie("peerid") || getCookie("deviceid"),
        });
      }

      // 新版iOS手雷
      if (isIOSNative) {
        const checkAjax = await checkMessage({
          platform: "ios",
          name: "xlAjax",
        });

        if (checkAjax && checkAjax.exist) {
          try {
            const [err, res] = await sendMessage(
              {
                platform: "ios",
                name: "userInfo",
              },
              { callbackTimeout: 1000 * 10 }
            );

            console.log("ios-userInfo: ", err, res);
            if (res && (res.authorize || res.sessionId)) {
              // 已登录
              const userId = String(res.userId);
              const Authorize = "Bearer " + res.authorize;
              const { avatar = "", vip } = await getIOSUserInfo(userId, {
                headers: {
                  Authorization: Authorize,
                },
              });

              console.log("getIOSUserInfo", avatar, vip);
              const peerid = res.peerId;

              setCookie("cur_userid_xz", userId, {
                maxAge: 60 * 60 * 24 * 7,
              });

              return store.commit("user/SET_CURRENT_USER", {
                userId,
                sessionId: res.sessionId,
                portrait_url: avatar,
                nick_name: res.nickName,
                peerid,
                vipData: {
                  isVip: res.isVip,
                  vipType: res.vipType,
                  vipLevel: res.vipLevel,
                },
              });
            }
          } catch (e) {
            console.log("ios native get-user-info error >>>", e);
            return;
          }
        }

        return;
      }

      const isLogined = await (await sso).init();

      console.log(
        `🔒 ${isLogined ? "✔️" : "❌"} auth isLogined >>>`,
        isLogined,
        route.query
      );
      if (query.sso_sign_out === "") {
        // i.xunlei.com/xluser/auth 静默退出登录逻辑 临时方案
        await sleep(60000);
      } else {
        // 未登录的情况，由于ios 13以上的ipad暂时无法提前判断，所以mounted后会对platform进行矫正，再执行处理逻辑
        if (isLogined) {
          if (window.native) {
            const xlpc = require("@/utils/xl-pc").default;
            try {
              const isNativeLogined = await xlpc.checkIsLogined();
              console.log(
                "web VS pc login status [logined]: ",
                isNativeLogined
              );
              if (!isNativeLogined) {
                // 如果客户端未登录，页面则主动退出
                await (await sso).signOut(window.location.href);
                return;
              }
            } catch (e: any) {
              console.log("sync client logout status error: ", e);
            }
          }

          if (isMacThunder()) {
            try {
              const { macApi } = require("@xunlei/universal-native-api");
              const userInfoRes = await macApi.call("getUserInfo");

              if (!userInfoRes || !userInfoRes.sessionId) {
                await (await sso).signOut(window.location.href);
                return;
              }
            } catch (e) {
              console.log("sync mac logout status error: ", e);
            }
          }

          if (
            route.name === "login" &&
            route.query &&
            route.query.redirect_uri
          ) {
            // 页面需要登录-跳转到登录页-登录成功后回调原地址-例如 我的分享
            window.location.href = `https://zhan.xunlei.com${route.query.redirect_uri}`;
          } else if (route.name === "blank") {
            let routeUrl = "https://zhan.xunlei.com/";
            if (route.query && route.query.sign_redirect_to) {
              routeUrl = decodeURIComponent(route.query.sign_redirect_to);
            }
            window.location.href = routeUrl;
          }
          const ssoStore = await getSsoStore();

          let user: any = {};
          try {
            user = await getSsoUser();
            console.log("sso user info >>>", user, ssoStore);
            // console.log('user', user)
          } catch (err) {
            console.error(`user error: ${JSON.stringify(err)}`);
          }

          const vipData = {
            vipType: 0,
            isVip: false,
            vipLevel: 0,
            expiredAt: "",
          };

          const xlVipData = {
            vipType: 0,
            isVip: false,
            vipLevel: 0,
            expiredAt: "",
          };

          if (user.vips && user.vips.length) {
            const xzVipInfo = user.vips.filter((item: VipRawInfo) => {
              return item.id && item.id.indexOf("vip201_") > -1;
            });

            if (xzVipInfo.length) {
              const vids = xzVipInfo[0].id.split("_");
              vipData.expiredAt = xzVipInfo[0].expires_at;
              vipData.isVip = Number(vids[1]) === 1;
              vipData.vipLevel = vids[2];
              vipData.vipType = vids[3];
            }

            // 迅雷VIP，接口偶现vip0_拼接方式，等待fix
            const xlVipInfo = user.vips.filter((item: VipRawInfo) => {
              return (
                item.id &&
                (item.id.indexOf("vip2_") > -1 || item.id.indexOf("vip0_") > -1)
              );
            });

            if (xlVipInfo.length) {
              const vids = xlVipInfo[0].id.split("_");
              xlVipData.expiredAt = xlVipInfo[0].expires_at;
              xlVipData.isVip = Number(vids[1]) === 1;
              xlVipData.vipLevel = vids[2];
              xlVipData.vipType = vids[3];
            }
          }

          const newUserData = {
            userId: ssoStore.user_id || ssoStore.sub,
            // 内网账号下sessionid和access_token的互用有问题，线上环境可以
            sessionId: getCookie("sessionid") || ssoStore.access_token,
            accessToken: ssoStore.access_token,
            refresh_token: ssoStore.refresh_token,
            portrait_url: user.picture,
            nick_name: user.name,
            vipData,
            xlVipData,
            peerid: getCookie("peerid") || getCookie("deviceid"), // TODO: 此处如果是客户端内
          };

          store.commit("user/SET_CURRENT_USER", newUserData);
          setCookie("cur_userid_xz", newUserData.userId, {
            maxAge: 60 * 60 * 24 * 7,
          });

          if (query.code_link) {
            delete query.code_link;
            redirect(route.path, route.query);
          }
        } else {
          if (window.native) {
            const xlpc = require("@/utils/xl-pc").default;
            try {
              const isNativeLogined = await xlpc.checkIsLogined();
              console.log(
                "web VS pc login status [not logined]: ",
                isNativeLogined
              );

              if (isNativeLogined) {
                await (await sso).signIn();
              }
            } catch (e: any) {
              console.log("sync client login status error: ", e);
            }
            return;
          }

          if (isMacThunder()) {
            try {
              const { macApi } = require("@xunlei/universal-native-api");
              const userInfoRes = await macApi.call("getUserInfo");

              if (userInfoRes && userInfoRes.sessionId) {
                await (await sso).signIn();
                return;
              }
            } catch (e) {
              console.log("sync mac login status error: ", e);
            }
          }

          // when app opens web page
          const regExp = /^(http[s]?):\/\/i\.xunlei\.com[\S]+/g;
          const codeLink = query.code_link;
          const from = query.from;
          const channel = query.channel;
          if (codeLink && regExp.test(decodeURIComponent(codeLink))) {
            window.location.href = codeLink;
            return;
          }

          if (
            (from && from === "app") ||
            (channel && (channel === "ios" || channel === "android"))
          ) {
            await (await sso).signIn();
          }
        }
      }
    } catch (error) {
      console.log("sso error >>>", error);
    }
  }
}
