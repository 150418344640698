import axios from "axios";
import { fetchDataWithShoulei } from "@/api/fetch-res";
import { getGCID } from "@/utils/aliyun";

export default {
  async uploadPicture(file: any, progressCallback: any, serviceType: any) {
    try {
      const gcid = await getGCID(file);
      const data = await fetchDataWithShoulei(
        `/xlppc.pic.api/api/v2/${serviceType}/upload`,
        {
          gcid,
          content_type: file.type,
          _m: "post",
        }
      );
      if (data.code === 20001) {
        // exist
        return data.data.url;
      } else if (data.code === 0) {
        return axios({
          url: data.data.upload.url,
          headers: data.data.upload.headers,
          method: "put",
          data: file,
          onUploadProgress: progressCallback,
        }).then((res: any) => {
          return data.data.url;
        });
      } else {
        return Promise.reject("上传图片失败");
      }
    } catch (error) {
      throw error;
    }
  },
  transferPics(url: string, serviceType: string) {
    return fetchDataWithShoulei(
      `/xlppc.pic.api/api/v2/${serviceType}/upload_from_url`,
      {
        url,
        _m: "post",
      }
    );
  },
};
