import { IObjectKeys } from '@/types/common';

export const privilegeCode = {
  'REJECTED': '用户无资格申请或使用此特权',
  'UNSUBMITTED': '用户有资格但需要用户主动提交申请',
  'REVIEWING': '用户已提交申请, 待管理员审核',
  'ACCEPTED': '已授权'
}

export const eventTypeCode = {
  'TYPE_PLAY': '播放视频或音频',
  'TYPE_VIEW': '查看文件',
  'TYPE_CREATE': '创建文件',
  'TYPE_UPDATE': '更新文件',
  'TYPE_DOWNLOAD': '下载文件',
  'TYPE_DELETE': '删除文件',
  'TYPE_UPLOAD': '上传文件',
  'TYPE_RESTORE': '转存文件'
}

export const taskPhaseCode = {
  'PHASE_TYPE_PENDING': '等待添加...',
  'PHASE_TYPE_RUNNING': '正在添加',
  'PHASE_TYPE_ERROR': '添加失败',
  'PHASE_TYPE_COMPLETE': '已存到云盘'
}

export const taskPhaseCodeReport = {
  'PHASE_TYPE_PENDING': 'add_wait',
  'PHASE_TYPE_RUNNING': 'adding',
  'PHASE_TYPE_ERROR': 'add_fail',
  'PHASE_TYPE_COMPLETE': 'add_finish'
}

export const shareStatusCode: any = {
  'OK': 0,
  'PASS_CODE_EMPTY': 1,
  'PASS_CODE_ERROR': 2,
  'MAX_RESTORE_COUNT': 3,
  'EXPIRED': 4,
  'DELETED': 5,
  'SENSITIVE': 6,
  'SENSITIVE_WORD': 6,
  'SENSITIVE_RESOURCE': 6,
  'AUDITING': 7,
  'NOT_FOUND': 8
}

export const shareStatusTxt = ['OK', '需要提取码', '提取码错误', '来晚了，该文件已超出分享次数', '来晚了，该文件已过期', '抱歉，该分享已被作者删除', '该分享内容可能因为涉及侵权、色情、反动、低俗等信息，无法访问！', '您访问的链接内容正在审核中...<br>请稍后访问，链接即将生效', '当前分享不存在，请检查链接']

export const feedAuditStatus: IObjectKeys = {
  'OK': '审核通过',
  'AUDITING': '审核中',
  'REJECT': '审核驳回',
  'INVALID': '已失效'
}
        export function pubuDUfUGVxR1fOp2USDhn12dJ7EGtFuEUl (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'O2OiXLimEzA9wtbxJXAeU7Sa', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/filters.ts').pubtc9XJC12pX7ZSYJZGwCZwZxBCZbyUYCi;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubOZTZ8njDqnXu1bOwI0PqzChcwOgYmONq (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/captcha.ts').pubMNGzd8I1Ibi92RmGCG28SAIrF9BowCfr;
            const nextPart2 = require('@/utils/get-user-info.ts').pub87nLdVbHtdpGEaFuWCwWcjXh7UBTHlvX;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pub6wuJOXjP40BycR6071q8bCxm7V242whl (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/util.ts').pubcgBIj5gIABeRFGHTDY4oF3HLePDXSV4u;
            const nextPart2 = require('@/utils/stat.ts').pub9HOZjzGtOU2pfzu7hR7LTmt7m8LiLEyb;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubPPFaS0VGAQrlvEhOwqs7TdL8lZyW1EXD (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'i6JzOQH5S', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/stat.ts').pubEd2bF2Ur3oue2WhKTbjjor3aoBqbsf9K;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubmSg3aiWzUHnlUakiYBf67R2wQ8LceZa0 (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'NglzFbDRm57RbmXdnkR5W', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/get-user-info.ts').pub2UasERqZIXkJyjTPNtAaLw16QtZsdWyN;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
          export function pubXPPflLMGJBf0CXQb54HoACSmAcIIZpEW (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: 'jsVSGNP3E', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/login-iframe.ts').pubOhRArtmsDzEjmhjiv90TG2wSMPX9DcgS;
              const nextPart2 = require('@/utils/get-user-info.ts').pub2UasERqZIXkJyjTPNtAaLw16QtZsdWyN;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }
          export function pubBoAsbiU0aZDJLyNnolGpUQQOdg4GPhHR (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: 'gZlcEXI7wJn6Y9pNwbvGa', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/filters.ts').pubithHY3KLhzsNyIZ6RIZNe3AIEpb1Dyml;
              const nextPart2 = require('@/utils/sso.ts').pubbPoXLJFIP8qhnih0enW8d2I55WrCutPa;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }