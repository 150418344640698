import axios from "axios";
import md5 from "blueimp-md5";
import qs from "qs";
import jsonp from "then-jsonp";
import getUserInfo from "@/utils/get-user-info";
import { errorMap } from "@/utils/error-message";
import { getCookie, random, isMacThunder } from "@/utils/util";
import Crequest from "@/utils/captcha";
import config from "@/config";

if (process.server) {
  axios.defaults.timeout = 5000;
} else if (process.client) {
  axios.defaults.timeout = 30000;
  axios.interceptors.request.use(function (config) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error("offline"));
    }
    return config;
  });
}

const serverEnv =
  (typeof global !== "undefined" && (global as any).serverEnv) || {};

const API_ENV = process.env.API_ENV || "production";

console.log("⚙️ fetch-res: serverEnv, API_ENV >>>", serverEnv, API_ENV);

const SL_API: any = {
  development: "https://api-xl9-ssl.xunlei.com/sl_dev",
  testing: "https://api-xl9-ssl.xunlei.com/sl_dev",
  preview: "https://api-xl9-ssl.xunlei.com/sl",
  production: "https://api-xl9-ssl.xunlei.com/sl",
};

const SHOULEI_API: any = {
  development: "https://test-api-shoulei-ssl.xunlei.com",
  testing: "https://test-api-shoulei-ssl.xunlei.com",
  preview: "https://api-shoulei-ssl.xunlei.com",
  production: "https://api-shoulei-ssl.xunlei.com",
};

const SHOULEI_API_SSR: any = {
  development: "https://test-api-shoulei-ssl.xunlei.com",
  testing: "http://square.xlppc.svc",
  preview: "https://api-shoulei-ssl.xunlei.com",
  production: "http://square.xlppc.svc:8080",
};

const DRIVE_API: any = {
  development: "https://api-alpha-drive.office.k8s.xunlei.cn/drive/v1/",
  testing: "https://api-alpha-drive.office.k8s.xunlei.cn/drive/v1/",
  preview: "https://api-pan.xunlei.com/drive/v1/",
  production: "https://api-pan.xunlei.com/drive/v1/",
};

const ACCOUNT_API: any = {
  development: "https://dev-xluser-ssl.xunlei.com",
  testing: "https://dev-xluser-ssl.xunlei.com",
  preview: "https://xluser-ssl.xunlei.com",
  production: "https://xluser-ssl.xunlei.com",
};

const ssoClient = process.client ? require("@/utils/ssoClient").default : {};

const isAndroidNative = process.client
  ? require("@xunlei/universal-native-api").isAndroidNative
  : false;
const isIOSNative = process.client
  ? require("@xunlei/universal-native-api").isIOSNative
  : false;
const isNative = isAndroidNative || isIOSNative;

export const fetchJSONP = async function (
  url: string,
  option: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    jsonp("GET", url, option, function (err: any, res: any) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
};

/**
 * 转换 header 参数
 */
function headerStringsToObject(headerStrings: any) {
  return headerStrings.reduce((prev: any, current: any) => {
    const [key, value] = current.split(":");
    prev[key] = value;
    return prev;
  }, {});
}

/**
 * 获取数据接口（直接连接服务端API，不走Node.js网关）
 * @param {string} url - 接口地址
 * @param {object} data - 数据
 * @param {object} option - 选项（method：请求方法'get'或'post'；headers：放入请求头的内容）
 */
export async function fetchDataWithShoulei(
  url: string,
  data: any = {},
  option: any = {}
) {
  url = option.env
    ? `${SHOULEI_API[option.env]}${url}`
    : `${SHOULEI_API[API_ENV]}${url}`;

  const parsedData = await parseData(data, option);
  const { method, params, userInfo } = parsedData;

  const cookie = (!process.server && document.cookie) || "";

  const headers: any = Object.assign(
    {},
    {
      "Peer-Id": userInfo.peerId,
      Guid: userInfo.guid,
      "User-Id": userInfo.userId === "0" ? "" : userInfo.userId,
      "Session-Id": userInfo.sessionId,
      "Version-Code": process.env.APP_VERSION || "1.0.0",
      "Account-Id": config.appid,
      "Credit-Key": getCookie("creditkey", cookie),
    },
    option.headers
  );

  return axios({
    // 本地开发时可以打开proxy，使用 whistle 代理请求，可以抓包
    // proxy: {
    //   host: '127.0.0.1',
    //   port: 8899
    // },
    method,
    url,
    data,
    params,
    headers,
    ...option,
  })
    .then((res: any) => {
      // 若返回错误信息，则转换成中文的错误信息，放在 errorText
      if (
        !(
          res.data.result === "ok" ||
          res.data.result === 0 ||
          res.status === 200
        )
      ) {
        res.data.errorText = errorMap(res.data);
      }

      return res.data;
    })
    .catch((res: any) => {
      if (res instanceof Error) {
        (res as any).response = { data: { result: res.message } };
      }
      const errorObject = Object.assign({}, res.response, {
        message: errorMap(res.response && res.response.data),
      });
      return Promise.reject(errorObject);
    });
}

/**
 * 解析请求参数
 * @param {object} data - 数据
 * @param {object} option - 选项
 */
async function parseData(data: any = {}, option: any = {}) {
  option = Object.assign(
    {},
    {
      method: data._m || "get",
    },
    option
  );
  const method = option.method;

  const cookie = (!process.server && document.cookie) || "";
  let [peerId, userId = 0, version, sessionId] = await getUserInfo();
  peerId =
    peerId || getCookie("peerid", cookie) || getCookie("deviceid", cookie);

  const guid = md5(peerId || random(32));
  const userInfo = {
    userId,
    sessionId,
    version,
    guid,
    peerId,
  };
  const headerStrings = [...((data && data._h) || [])];

  delete data._h;
  delete data._m;

  let params;

  if (Object.keys(data).includes("filters")) {
    data.filters = JSON.stringify(data.filters);
  }

  params = data;
  data = null;

  return {
    method,
    params,
    headerStrings,
    userInfo,
    data,
  };
}

/**
 * 访问手雷接口
 * @param {string} url - 接口地址
 * @param {object} data - 数据
 */
export async function fetchSlRes(
  url: string,
  data: any = {},
  option: any = {}
) {
  url =
    url.indexOf("http") === 0
      ? url
      : option.env
      ? `${SL_API[option.env]}${url}`
      : `${SL_API[API_ENV]}${url}`;
  const parsedData = await parseData(data, option);
  let { method, params, headerStrings, userInfo } = parsedData;

  headerStrings = [
    ...headerStrings,
    `Peer-Id:${userInfo.peerId}`,
    `Guid:${userInfo.guid}`,
    `User-Id:${userInfo.userId}`,
    `Session-Id:${userInfo.sessionId}`,
    `Version-Code:${process.env.APP_VERSION}`, // 使用前端版本号
  ];
  const headers = headerStringsToObject(headerStrings);

  const res = await fetchByApiProxy(url, {
    method,
    params,
    headerStrings,
    headers,
    userInfo,
    data: parsedData.data,
  });
  if (option.noAutoCatch || res.result === "ok" || res.code === 0) {
    return res;
  } else {
    throw new Error("data error");
  }
}

/**
 * 通过 API 网关请求数据
 */
export function fetchByApiProxy(
  url: string,
  {
    method = "get",
    params = {},
    data = {},
    headers = {},
    headerStrings = [],
    timeout = 1000,
    userInfo,
    ignoreSessionCheck,
  }: any
) {
  if (!ignoreSessionCheck) {
    // 鉴权参数只能通过 params 传递
    params._sessid = userInfo.sessionId;
    params._uid = userInfo.userId;
  }

  // header通过参数来传避免options请求
  params._h = headerStrings;

  return axios({
    // 本地开发时可以打开proxy，使用 whistle 代理请求，可以抓包
    // proxy: {
    //   host: '127.0.0.1',
    //   port: 8899
    // },
    method,
    url,
    data,
    params,
  }).then((res) => {
    return res.data;
  });
}

/**
 * 获取云盘相关的接口：http://drive.office.k8s.xunlei.cn/?urls.primaryName=file.swagger.json#/
 * @param {string} url - 接口地址
 * @param {object} data - 数据
 * @param {object} option - 选项（method：请求方法'get'或'post'；headers：放入请求头的内容）
 */
export async function fetchDataForDrive(
  url: string,
  data: any = {},
  option: any = {}
) {
  url = option.env
    ? `${DRIVE_API[option.env]}${url}`
    : `${DRIVE_API[API_ENV]}${url}`;

  if (isNative) {
    const { request } = await require("./fetch-native");
    return request(url, data, option);
  }

  const parsedData = await parseData(data, option);
  const { method, params } = parsedData;

  let Authorization: any = null;
  if (ssoClient.isNative() && !Authorization) {
    try {
      let accessToken = await ssoClient.ssoCallFunction("getAccessToken");
      accessToken = accessToken[0];
      Authorization = "Bearer " + accessToken;
      // console.log('Authorization', accessToken)
    } catch (err) {
      return Promise.reject(`请重新登录, ${err}`);
    }
  }

  let opt: any = {
    method,
    withCredentials: option.withCredentials === false ? false : true,
    withCaptcha: option.withCaptcha !== undefined ? option.withCaptcha : true,
    headers: option.headers || {},
  };

  if (Authorization) {
    opt.headers.Authorization = Authorization;
  }

  // GET
  if (
    method.toLocaleLowerCase() !== "get" &&
    method.toLocaleLowerCase() !== "head"
  ) {
    opt = Object.assign({}, opt, {
      body: JSON.stringify(params),
    });
  } else {
    const str = qs.stringify(params);
    // 兼容url主动添加参数的情况
    url = url + (str ? "?" + str : "");
  }

  return Crequest(url, opt)
    .then((res) => res)
    .catch((res) => {
      if (res.error === "unreachable") {
        res.error_description = "网络异常，请稍后重试";
      }
      return Promise.reject(res);
    });
}

export async function fetchZhanData(
  url: string,
  data: any = {},
  option: any = {}
): Promise<any> {
  const cookie = (!process.server && document.cookie) || "";
  if (process.env.VUE_ENV !== "server") {
    url = option.env
      ? `${SHOULEI_API[option.env]}${url}`
      : `${SHOULEI_API[API_ENV]}${url}`;
  } else {
    url = option.env
      ? `${SHOULEI_API_SSR[option.env]}${url}`
      : `${SHOULEI_API_SSR[API_ENV]}${url}`;
  }

  if (isNative) {
    const { request } = await require("./fetch-native");
    return request(url, data, option);
  }

  const parsedData = await parseData(data, option);
  const { method, params } = parsedData;

  let Authorization = null;

  let sessionId = getCookie("sessionid", cookie);
  if (process.client && isMacThunder()) {
    try {
      const { macApi } = require("@xunlei/universal-native-api");
      const infoRes = await macApi.call("getUserInfo");
      if (infoRes.sessionId) {
        sessionId = infoRes.sessionId;
      }
    } catch (error) {}
  }

  const newHeaders = Object.assign(
    {
      "x-session-id": sessionId,
    },
    option.headers || {}
  );

  let opt: any = {
    method,
    withCredentials: option.withCredentials === false ? false : true,
    withCaptcha: option.withCaptcha !== undefined ? option.withCaptcha : true,
    headers: newHeaders,
  };

  if (Authorization) {
    opt.headers.Authorization = Authorization;
  }

  if (
    method.toLocaleLowerCase() !== "get" &&
    method.toLocaleLowerCase() !== "head"
  ) {
    opt = Object.assign({}, opt, {
      body: JSON.stringify(params),
    });
  } else {
    const str = qs.stringify(params);
    // 兼容url主动添加参数的情况
    url = url + (str ? "?" + str : "");
  }

  return Crequest(url, opt)
    .then((res) => res)
    .catch((res) => {
      if (res.error === "unreachable") {
        res.error_description = "网络异常，请稍后重试";
      }
      return Promise.reject(res);
    });
}

export async function fetchAccountData(
  url: string,
  data: any = {},
  option: any = {}
): Promise<any> {
  url = option.env
    ? `${ACCOUNT_API[option.env]}${url}`
    : `${ACCOUNT_API[API_ENV]}${url}`;

  console.log("fetchAccountData url >>>", url);

  if (isNative) {
    const { request } = await require("./fetch-native");
    return request(url, data, option);
  }

  const parsedData = await parseData(data, option);
  const { method, params } = parsedData;
  const newHeaders = Object.assign({}, option.headers || {});

  let opt: any = {
    method,
    withCredentials: option.withCredentials === false ? false : true,
    withCaptcha: option.withCaptcha !== undefined ? option.withCaptcha : true,
    headers: newHeaders,
  };

  if (
    method.toLocaleLowerCase() !== "get" &&
    method.toLocaleLowerCase() !== "head"
  ) {
    opt = Object.assign({}, opt, {
      body: JSON.stringify(params),
    });
  } else {
    const str = qs.stringify(params);
    url = url + (str ? "?" + str : "");
  }

  return Crequest(url, opt)
    .then((res) => res)
    .catch((res) => {
      if (res.error === "unreachable") {
        res.error_description = "网络异常，请稍后重试";
      }
      return Promise.reject(res);
    });
}
