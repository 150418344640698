import { getPlatformName } from "@/utils/util";

const getClientMetaInfo = async (options: any) => {
  const clientType = getPlatformName();
  console.log("getPlatformName clientType >>>", clientType);
  let version = "";
  let peerId = "";

  if (clientType === "pc_xl") {
    const xlpc = require("@/utils/xl-pc").default;
    if (window.native) {
      // TODO: 获取信息的统一方法
      [version, peerId] = await Promise.all([
        xlpc.getThunderVersion(),
        xlpc.getPeerID(),
      ]);
    }
  }

  if (clientType === "mac_xl") {
    const { macApi } = require("@xunlei/universal-native-api");
    const infoRes = await macApi.call("getPlatformInfo", {});

    if (infoRes) {
      version = infoRes.clientVersion;
      peerId = infoRes.peerId;
    }
  }

  if (["ios_sl", "an_sl"].includes(clientType)) {
    // TODO: 未接入
  }

  return {
    version,
    peerId,
  };
};

export { getClientMetaInfo };
