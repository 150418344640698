import qs from "qs";
import { GetterTree, ActionTree, MutationTree } from "vuex";
import { RootState } from "@/store";
import { UserModuleState } from "@/store/user";
import api from "@/api/vip";
import { getSearch, isPcPlatform } from "@/utils/util";
import {
  IScanStatusParams,
  IScanStatusResult,
  IPayQrCodeResult,
  IPayQrcodeParams,
  IPayOrderStatusParams,
  IPayOrderStatusResult,
  ICreateOrderParams,
  ICreateOrderResult,
} from "@/types/vip";

export const state = () => ({
  name: "Vip",
  payParams: {
    referfrom: "",
    aidfrom: "",
    cardID: "",
    zone_name: "",
    need_vip: "",
  },
  payQrCodeUrl: "",
  qrcodeRes: {
    qrcodeId: "",
    url: "",
    qrcode: "",
  },
});

export type VipModuleState = ReturnType<typeof state>;

export const getters: GetterTree<VipModuleState, RootState> = {
  name: (state) => state.name,
};

export const mutations: MutationTree<VipModuleState> = {
  SET_PAY_PARAMS: (state, params) => {
    state.payParams = { ...state.payParams, ...params };
  },
  SET_PAY_QRCODE_URL: (state, url) => {
    state.payQrCodeUrl = url;
  },
  SET_PAY_QRCODE_CONFIG: (state, config) => {
    state.qrcodeRes = config;
  },
};

export const actions: ActionTree<VipModuleState, UserModuleState> = {
  nuxtServerInit({ commit }, { req }) {
    console.log(req.auth);
  },
  async genPayQrcode({ commit }, params: IPayQrcodeParams) {
    return api.genPayQrcode(params).then((res): IPayQrCodeResult => {
      if (res.ret === 200) {
        commit("SET_PAY_QRCODE_CONFIG", {
          qrcodeId: res.data.id,
          url: res.data.shorturl,
          qrcode: res.data["ssl-qrcode"],
        });
      }
      return res;
    });
  },
  checkQrcodeStatus({ commit, state }, params: IScanStatusParams) {
    return api.checkQrcodeStatus(params).then((res): IScanStatusResult => {
      return res;
    });
  },
  checkOrderIdStatus({ commit, state }, params: IPayOrderStatusParams) {
    return api
      .checkOrderIdStatus(params)
      .then((res): IPayOrderStatusResult => {
        return res;
      })
      .catch((e) => {
        console.log("checkOrderIdStatus error >>>", e);
      });
  },
  createPayOrder({ commit, state, rootState }, params: ICreateOrderParams) {
    return api.createPayOrder(params).then((res): IPayQrCodeResult => {
      return res;
    });
  },
};
