import account from "@/utils/account";
import Vue from "vue";
import xss from "xss";
import md5 from "blueimp-md5";
import emojiMap from "@/static/emoji.json";
import htmlTags from "@/utils/htmlTag";

/**
 * 将一个支持callback的函数转换成支持promise的
 * @method promisefy
 * @param {Function} fn - 支持callback的函数
 * @param {Object} context - 执行环境
 * @returns {Function}
 */
export function promisefy(fn: Function, context: any) {
  return function (...args: any[]) {
    return new Promise((resolve, reject) => {
      const cb = (err: any, payload: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(payload);
        }
      };
      fn.apply(context, [...args, cb]);
    });
  };
}

export function promisefyCheck(fn: Function, context: any) {
  return function (...args: any[]) {
    return new Promise((resolve, reject) => {
      const cb = (payload: any) => {
        resolve(payload);
      };
      fn.apply(context, [...args, cb]);
    });
  };
}

/**
 * 设置cookie
 * @author @zhaobing
 * @param {String} key
 * @param {String} value
 * @param {Object} opt
 * @property {String} opt.path - 例如 '/', '/mydir') 如果没有定义，默认为'/'。
 * @property {String} opt.domain - (例如 'example.com'， '.example.com' (包括所有子域名),
 *  'subdomain.example.com') 如果没有定义，默认为当前文档位置的路径的域名部分。
 * @property {String} opt.maxAge - max-age-in-seconds (例如一年为60*60*24*365)
 * @property {String} opt.expires - 过期时间，不需要转换成UTC
 * @property {String} opt.secure - cookie只通过https协议传输，默认为false
 */
export function setCookie(
  key: string,
  value: string,
  { path = "/", domain, maxAge, expires, secure }: any = {}
) {
  let cookieStr = key + "=" + encodeURIComponent(value);

  path && (cookieStr += ";path=" + path);
  domain && (cookieStr += ";domain=" + domain);
  maxAge && (cookieStr += ";max-age=" + maxAge);
  expires && (cookieStr += ";expires=" + expires.toUTCString());
  secure && (cookieStr += ";secure=" + secure);

  document.cookie = cookieStr;
}

/**
 * @method getCookie
 * @desc 获取cookie值
 * @param name {String} - cookie字段名
 * @returns {String} - cookie值
 */
export function getCookie(name: string, cookie = "") {
  if (!cookie && process.client) {
    cookie = document.cookie;
  }
  const result =
    cookie.match(new RegExp("(^" + name + "| " + name + ")=([^;]*)")) == null
      ? ""
      : RegExp.$2;

  return result;
}

/***
 * 删除cookie中指定变量函数
 * @param {string} name Cookie名称
 */
export function deleteCookie(name: string) {
  const myDate = new Date();
  myDate.setTime(-1000); // 设置时间
  document.cookie = name + "=''; expires=" + myDate.toUTCString();
  document.cookie = name + "=''; expires=" + myDate.toUTCString() + ";path=/";
}

/**
 * @method getQueryString
 * @desc 获取链接参数的值
 * @param url {String} - 链接地址
 * @param name {String} - 参数名称
 * @returns {String} - 参数值
 */
export function getQueryString(url: string, name: string) {
  if (!url || !name) return;
  return url.match(new RegExp(`(^${name}|[?|&]${name})=([^&#]+)`))
    ? RegExp.$2
    : "";
}

/**
 * 通用登录。登录时，直接执行回调；未登录时，拉起登录框，同时将回调注册到登录事件中
 * @method shouldLogin
 * @param {function} callback - 回调函数（第一个参数：错误信息；第二个参数：客户端登录成功返回对象，非客户端返回字符串）
 * @param {Boolean} isNative - 是否在手雷客户端
 * @example
 * shouldLogin((err, userID) => {
 *   if (!err) {
 *
 *   }
 * })
 */
export function shouldLogin(callback: Function, from: string) {
  if (typeof callback !== "function") return;

  if (account.isLogined()) {
    callback(null, getCookie("userid"));
  } else {
    account
      .login(from)
      .then((uid: string) => {
        callback(null, uid);
      })
      .catch(callback);
  }
}

// 判断移动平台（微信、QQ、微博、QQ空间、UC浏览器）
export function platform(ua = window.navigator.userAgent) {
  let platform;

  if (/MicroMessenger/.test(ua)) {
    platform = "wx";
  } else if (/QQ\//.test(ua)) {
    platform = "qq";
  } else if (/\bWeibo|__weibo__\d/.test(ua)) {
    platform = "weibo";
  } else if (/Qzone\//.test(ua)) {
    platform = "qzone";
  } else if (/UCBrowser/.test(ua)) {
    platform = "uc";
  } else {
    platform = "other";
  }

  return platform;
}

export function clientType(): string {
  const isIOS = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
  if (isIOS) return "ios";
  const isAndroid = /(Android)/i.test(navigator.userAgent);
  if (isAndroid) return "android";
  if (/(Macintosh)/.test(navigator.userAgent)) return "mac";
  return "pc";
}

export function isPcPlatform(): boolean {
  const name = clientType();
  return ["mac", "pc"].includes(name);
}

export function platformType(): string {
  const ua = navigator.userAgent;
  if (/(Wechat)/i.test(ua)) {
    return "wechat";
  } else if (/(QQ\/\d)/.test(ua)) {
    return "qq";
  } else if (/(Weibo)/.test(ua)) {
    return "weibo";
  } else {
    return "browser";
  }
}

/**
 * 随机数产生
 * @param {Number} length - 随机数长度
 * @param {String} [charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'] - 随机数的字符
 * @returns {String} - 随机数
 */
export function random(
  length: number,
  charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
) {
  return Array.apply(null, new Array(length))
    .map(function () {
      return charset.charAt(Math.floor(Math.random() * charset.length));
    })
    .join("");
}

/**
 * 判断所在系统
 * @param {String} [ua = window.navigator.userAgent] - 用户代理
 * @param {String} [pf = window.navigator.platform] - 系统平台类型
 * @returns {String} os - 系统名：'iOS'|'Android'|'Windows Phone'|'macOS'|'Win2000'|'WinXP'|'Win2003'|'WinVista'|'Win7'|'Win10'|'Windows'|'Unix'|'Linux'
 */
export function detectOS(
  ua = window.navigator.userAgent,
  pf = window.navigator.platform
) {
  let os = "";

  if (/iPhone|iPad|iPod|iOS/.test(ua)) {
    os = "iOS";
  } else if (/Android/.test(ua)) {
    os = "android";
  } else if (/\bWindows Phone/.test(ua)) {
    os = "Windows Phone";
  } else if (/Mac OS/.test(ua)) {
    os = "macOS";
  } else {
    if (pf === "Win32" || pf === "Windows") {
      os = "Windows";
    } else if (pf === "X11") {
      os = "Unix";
    } else if (/Linux/.test(pf)) {
      os = "Linux";
    }
  }

  return os;
}

/**
 * 获取 Min Max 直接的随机数字
 * @param {Number} Min
 * @param {Number} Max
 */
export function getRandomNum(Min: number, Max: number) {
  const Range = Max - Min;
  const Rand = Math.random();
  return Min + Math.round(Rand * Range);
}

/**
 * base64 编码
 * @param {String} str
 */
export function base64encode(str: string) {
  const base64EncodeChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  let out, i, len;
  let c1, c2, c3;

  len = str.length;
  i = 0;
  out = "";
  while (i < len) {
    c1 = str.charCodeAt(i++) & 0xff;
    if (i == len) {
      out += base64EncodeChars.charAt(c1 >> 2);
      out += base64EncodeChars.charAt((c1 & 0x3) << 4);
      out += "==";
      break;
    }
    c2 = str.charCodeAt(i++);
    if (i == len) {
      out += base64EncodeChars.charAt(c1 >> 2);
      out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4));
      out += base64EncodeChars.charAt((c2 & 0xf) << 2);
      out += "=";
      break;
    }
    c3 = str.charCodeAt(i++);
    out += base64EncodeChars.charAt(c1 >> 2);
    out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4));
    out += base64EncodeChars.charAt(((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6));
    out += base64EncodeChars.charAt(c3 & 0x3f);
  }
  return out;
}

/**
 *
 * @param {Number} bytes 字节数
 * @param {Number} point 小数点保留的位置
 */
export function bytesToSize(bytes: number, point = 1) {
  if (bytes === 0) {
    return "0 B";
  }
  const k = 1024; // or 1024
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const val = bytes / Math.pow(k, i);
  let size = val.toPrecision(3);
  size = val.toFixed(point);
  return size + sizes[i];
}

/**
 * @method isDebugMode
 * @desc 是否是调试模式
 * @returns {Boolean}
 */
export function isDebugMode() {
  return (
    process.env.NODE_ENV !== "production" ||
    getQueryString(
      typeof window !== "undefined" ? window.location.href : "",
      "debug"
    )
  );
}

export function formatTime(dateObj: any, fmt: string) {
  const o: any = {
    "M+": dateObj.getMonth() + 1, // 月份
    "d+": dateObj.getDate(), // 日
    "h+": dateObj.getHours(), // 小时
    "m+": dateObj.getMinutes(), // 分
    "s+": dateObj.getSeconds(), // 秒
    "q+": Math.floor((dateObj.getMonth() + 3) / 3), // 季度
    S: dateObj.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (dateObj.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}

export function clientToast(message = "未知错误，请稍后再试") {
  window.XLJSWebViewBridge &&
    window.XLJSWebViewBridge.sendMessage(
      "xlShowToast",
      JSON.stringify({ message })
    );
}

/** 节流函数 保证在mustRun毫秒内,必然触发一次 handler */
export function throttle(func: Function, wait: number, mustRun: number) {
  let timeout: any = null;
  let startTime = new Date().getTime();
  return function () {
    const context = this;
    const args = arguments;
    const curTime = new Date().getTime();
    clearTimeout(timeout);
    if (curTime - startTime >= mustRun) {
      func.apply(context, args);
      startTime = curTime;
    } else {
      timeout = setTimeout(func, wait);
    }
  };
}

export function formatSize(size: number | string) {
  size = parseInt(size.toString());
  if (!size) {
    return 0;
  }
  const sizeMap = ["B", "KB", "M", "G", "T"];
  let index = 0;
  while (size > 1024) {
    size = size / 1024;
    index++;
  }
  size = String(size.toFixed(1));
  if (/(.0)/.test(size)) size = size.split(".0")[0];
  return `${size}${sizeMap[index]}`;
}

export function sleep(millisecond: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, millisecond);
  });
}

export function isMobile(ua: string) {
  var ua = ua.toLowerCase();
  if (ua.indexOf("mobi") != -1) {
    return true;
  } else {
    return false;
  }
}

// 检测有效的下载链接
export function checkValidLink(url: string) {
  return /^(thunder|magnet|http[s]?|ftp|ed2k):/i.test(url);
}

// 根据Id来更新树形结构的内容添加新数据
export function updateListByPropId(
  id: number | string,
  data: any[] = [],
  nodes: any
) {
  const newData = [...data];
  for (const key in data) {
    if (id === data[key].id) {
      Vue.set(newData[key], "nodes", nodes);
      // 赋值无效，需要子节点都变成可响应式
      // newData[key].nodes = nodes
    } else {
      if (data[key].nodes) {
        updateListByPropId(id, data[key].nodes, nodes);
      }
    }
  }
  return newData;
}

// 他人昵称简写
export function shortNickName(str = "", frontLen = 3, endLen = 2) {
  if (str.length <= 1) return str;
  else if (str.length === 2) return str.substring(0, 1) + "*";
  else if (str.length === 3)
    return str.substring(0, 1) + "*" + str.substring(2);
  else if (str.length <= 6) {
    const start = Math.floor(str.length / 2);
    return (
      str.substring(0, start - 1) + "**" + str.substring(str.length - start)
    );
  }
  const start = Array.from({ length: 3 }, () => "*").join("");
  return (
    str.substring(0, frontLen) + start + str.substring(str.length - endLen)
  );
}

export function splitLongText(
  word: string,
  size: number = 36,
  row: number = 1
) {
  // const length = word.replace(/[^\x00-\xff]/g,"01").length
  const len = word.length;
  const mid: number = parseInt((size * row * 0.5).toString(), 10);
  if (len > size * row) {
    return word.replace(/[\S\s]/gi, (match: string, index: number) => {
      if (index > size * row - 8 && index < len - 6) return "";
      else if (index >= mid && index <= mid + 2) return ".";
      else return match;
    });
  } else {
    return word;
  }
}

// 获取 deviceid
export function getDeviceID() {
  const deviceID = getCookie("deviceid");
  if (/./.test(deviceID) && deviceID.length > 32) {
    return deviceID.split(".")[1].slice(0, 32);
  } else {
    return deviceID;
  }
}

export function checkIOS() {
  const UA_IOS_REG = /\b(iPad|iPhone|iPod)\b.*? OS ([\d_]+)/;
  const isIOS13Pad =
    navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
  return UA_IOS_REG.test(navigator.userAgent) || isIOS13Pad;
}

export function hasClass(obj: HTMLElement, cls: string) {
  return obj.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

export function addClass(obj: HTMLElement, cls: string) {
  if (!hasClass(obj, cls)) obj.className += " " + cls;
}

export function removeClass(obj: HTMLElement, cls: string) {
  if (hasClass(obj, cls)) {
    var reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    obj.className = obj.className.replace(reg, " ");
  }
}

export function toggleClass(obj: HTMLElement, cls: string) {
  if (hasClass(obj, cls)) {
    removeClass(obj, cls);
  } else {
    addClass(obj, cls);
  }
}

export const once = (fn: Function) => {
  let called = false;
  return function (...args: any[]) {
    if (called) return;
    called = true;
    return fn.apply(this, args);
  };
};

export function getFileExtension(fileName: string) {
  if (typeof fileName !== "string") {
    return "";
  }
  const idx = fileName.lastIndexOf(".");
  return fileName.slice(idx);
}

// pc端获取客户端版本时，格式化参数
export const compileOpts = ({
  secret = "123456", // 默认密钥，跟联盟js跟客户端通信方式一致
  params = {},
} = {}) => {
  let componentParam = "";
  const opts: any = {};
  Object.entries(params).forEach((item: any) => {
    if (item.length === 2) {
      componentParam += `${item[0]}=${item[1]}`;
      opts[item[0]] = decodeURIComponent(item[1]);
    }
  });
  componentParam += secret;
  return {
    ...opts,
    sign: md5(componentParam),
  };
};

export function secondToDate(seconds: number) {
  let h = Math.floor(seconds / 3600).toString();
  let m = Math.floor((seconds / 60) % 60).toString();
  let s = Math.floor(seconds % 60).toString();
  return `${h.padStart(2, "0")}:${m.padStart(2, "0")}:${s.padStart(2, "0")}`;
}

export function highlightLink(text: string) {
  const reg = /(thunder|magnet|http[s]?|ftp|ed2k):[\S]+/gi;
  const that: any = this;
  text = text.replace(reg, (replacement: string) => {
    return `<em style="color: #3F85FF; word-break: break-all; text-decoration: underline;"
      class="link" data-link=${that.$xss(replacement)}>${replacement}</em>`;
  });
  return text;
}

export function debounce(fn: Function, interval: number) {
  let timer: any = null;
  return function () {
    clearTimeout(timer);
    let context = this;
    let args = arguments;

    timer = setTimeout(function () {
      fn.apply(context, args);
    }, interval);
  };
}

export const callClientByTagA = (url: string) => {
  const tagA = document.createElement("a");
  tagA.href = url;
  document.body.appendChild(tagA);
  tagA.click();
  document.body.removeChild(tagA);
};

export const joinUrlQueryString = (queryObj: any, routeName: string) => {
  const pathnameMap: any = {
    index: "home",
    "detail-feed": "detail",
    "space-user": "personal",
    "publish-upload": "publish",
    message: "message",
    "zone-id": "zone",
    "vip-pay": "pay",
    "vip-pay-result": "pay-result",
    "activity-id": "activity",
  };

  const allowedQuery = [
    "entry_from",
    "entry_source",
    "ui_traceid",
    "ui_spanid",
  ];
  let queryStrArr: any[] = [];
  for (let q of allowedQuery) {
    if (queryObj[q]) {
      queryStrArr.push(`${q}=${queryObj[q]}`);
    }
  }

  if (!queryObj.entry_from) {
    const entryFrom = pathnameMap[routeName] || "home";
    queryStrArr.push(`entry_from=${entryFrom}`);
  }

  const queryStr = queryStrArr.join("&");
  return queryStr;
};

export const extractTextFromHtml = (html: string) => {
  const regx = /<[^<>]+>/g;
  return html.replace(regx, "");
};

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function highlight(
  keyword: string,
  dataString: string,
  className: string
) {
  // TODO: 分词
  if (!dataString) dataString = "";
  const replaceReg = new RegExp(escapeRegExp(keyword), "ig");
  const replaceString = `<span class="${className}">${keyword}</span>`;
  return dataString.replace(replaceReg, (replacement) => {
    if (keyword.indexOf(replacement) < 0) {
      return `<span class="${className}">${replacement}</span>`;
    } else {
      return replaceString;
    }
  });
}

export function replaceEmoji(str = "") {
  if (typeof str !== "string") return str;
  // 匹配表情文字, such as [开心]
  const match = str.match(/\[[^[\]]+\]/g);
  match &&
    match.forEach((item) => {
      const unicode = (emojiMap as any)[item];
      if (unicode) {
        str = str.replace(
          item,
          `<span class="face-icon-emoji emoji-${unicode}"></span>`
        );
      }
    });
  return str;
}

const htmlRules = new (xss as any).FilterXSS({
  stripIgnoreTag: true,
  whiteList: [],
  onTag(tag: string, html: string, options: any) {
    // 匹配中文，如果匹配到直接加'<'、'>'返回
    const str = tag.match(/[\u4e00-\u9fa5]/g);
    if (str) {
      return "<" + str.join("") + ">";
    }

    // 如果不是html标签，进行转义返回
    if (htmlTags.indexOf(tag) === -1) {
      return html.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    }

    // 否则进行默认xss过滤操作
  },
});

export function xssHtml(str: string) {
  if (!str) return "";
  return htmlRules.process(str);
}

export function genSpanid() {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); // use high-precision timer if available
  }
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

export const inClientPlatformNames = ["ios_sl", "an_sl", "mac_xl", "pc_xl"];

export function getPlatformName(
  ua = window.navigator.userAgent,
  pf = window.navigator.platform
) {
  let value = "";

  if (/iPhone|iPad|iPod|iOS/.test(ua)) {
    value = "ios_browser";
    if (ua.match(/iThunder/g)) {
      value = "ios_sl";
    }
  } else if (/Android/.test(ua) || /HarmonyOS/.test(ua)) {
    value = "an_browser";

    if (ua.match(/iThunder/g)) {
      value = "an_sl";
    }
  } else if (/Mac OS/.test(ua)) {
    value = "mac_browser";

    if (/MacThunder|ThunderBrowser/i.test(ua)) {
      value = "mac_xl";
    }
  } else {
    if (pf === "Win32" || pf === "Windows") {
      value = "pc_browser";
      if (ua.match(/Thunder/g)) {
        value = "pc_xl";
      }
    } else if (pf === "X11") {
      value = "pc_browser";
    } else if (/Linux/.test(pf)) {
      value = "pc_browser";
    }
  }

  return value;
}

export function isMacThunder() {
  return /MacThunder|ThunderBrowser/i.test(navigator.userAgent);
}

export function getSearch(key: string) {
  const isBrowser =
    typeof window !== "undefined" &&
    typeof document !== "undefined" &&
    typeof navigator !== "undefined";

  const SEARCH = isBrowser ? location.search : "";
  return new RegExp("[&?]" + key + "=([^&]*)", "i").test(SEARCH)
    ? decodeURIComponent(RegExp.$1)
    : "";
}

export function findMinNumberIndex(arr: number[]) {
  const min = Math.min(...arr);
  const index = arr.findIndex((item, index) => {
    return item === min;
  });
  return index || 0;
}

        export function pubqI8uIvzeyQVdERuxAhvqscS3ggGmx71M (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: '6PIrmDkhkIeuRmuH0nkARS4peQ', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/sso.ts').pubugVCaG9eAg3JkjVJQbNqz4b4tDv7Xwtd;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubCo73DbWyBgIzfmhvpZ7gVemaGanqwzQi (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/login-iframe.ts').pubPZXkLlmUNC2GlxP1Lb7zWCLDYTs0Jfaf;
            const nextPart2 = require('@/utils/error-message.ts').pubrGUJHj7gBWfEM4uI00OeJ1TKEh4lKfLd;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubg0z6ieE7rXlnFNJogpMe33cZOczoqrTR (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/sso.ts').pub7vr6wmpev7zlKsiQIWXvEIREqRz7j2NC;
            const nextPart2 = require('@/utils/filters.ts').pub405UbNlI7a6SE4rVtc5z5Hf9vhtA7CXG;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubXcwbjcOrUFxgBmg9takCTgrCooZv1I2n (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'YO52i3DMTszRXA9ts1aVRESUCGGrxVrYpVQO5gobDRVueC', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/captcha.ts').pubOiLtmUCxvUlp3QkIUBf5vcPz9NKUoKCW;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubBTHB8atszqtGt7LXv4NZZKXDOxVAWW2f (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/xl-client-common.ts').pub0zKxOxmK0EqqJldd5pjdDTFZdWuLZyZm;
            const nextPart2 = require('@/utils/sso.ts').pubORsmeGZQvLpjgFmyTbKNi7yVVC5vIVR9;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubxJnuHRTLfDjnb3aMRKU2Mt5h803TeooE (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/filters.ts').pubZFaja3AWulGIC1fl0f3hIFkKNpT2pWWO;
            const nextPart2 = require('@/utils/sso.ts').pubyu9kOfMFMZn5NfAX9YkJ3qFKir8GqQ39;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubKzU5eB3BYiCeEzzmIKMsA3Uc0585ajSs (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'xjvGruSZgH', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/sso.ts').pubtK8l4aXT4qT9icazT4xUZZiBz1hBr57O;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubTVhPb4FTfAGTd7GLuIjxMjD7jAcLIJvh (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'DVxCjiDsZUEMMzb8KkiGPE6RR', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/util.ts').puba3vZHvGzvQBtvsbmUD0mtrep646qzieE;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function puba3vZHvGzvQBtvsbmUD0mtrep646qzieE (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'On3vlYzKnLniG9Kg03RP7J2TTO', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/error-message.ts').pubZzCKrM6ysGkkXzNiht5AbyIxQu4gda8Y;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pub5TGinsoRaICNMNFBUMJAfoELzyT9FWA4 (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'mG7LRg6ZvngvLQWk8quWBh07qFFxpJY7ja0sJoSbTO05Ey', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/stat.ts').pub2OLteEUgckl0A5ngJASg7jv1r9JTxpIx;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }