import { Plugin } from '@nuxt/types'

let route: any = {}

const routePlugin: Plugin = (context, inject) => {
  route = context.route
}

export function getRoute () {
  return route
}

export default routePlugin
