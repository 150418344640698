import "intersection-observer";
import Vue from "vue";

const VueSee: any = {};

VueSee.install = function (Vue: any) {
  Vue.directive("saw", {
    inserted: (el: any, { value, arg = 10, modifiers = {} }: any) => {
      let point: any = Number(arg) > 100 ? 100 : Number(arg);
      point = (point / 100).toFixed(1);
      point = Number(point);
      const io = new IntersectionObserver(
        (entries) => {
          if (entries[0].intersectionRatio > point) {
            if (modifiers.once) {
              io.disconnect();
            }

            value(true);
          } else {
            value(false);
          }
        },
        {
          threshold: [0, point],
        }
      );
      io.observe(el);
    },
  });
};

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(VueSee);
}

Vue.use(VueSee);
