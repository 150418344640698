import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Avatar: () => import('../../components/Avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c)),
  CategoryMenu: () => import('../../components/CategoryMenu.vue' /* webpackChunkName: "components/category-menu" */).then(c => wrapFunctional(c.default || c)),
  DailyStat: () => import('../../components/DailyStat.vue' /* webpackChunkName: "components/daily-stat" */).then(c => wrapFunctional(c.default || c)),
  DeleteDialog: () => import('../../components/DeleteDialog.vue' /* webpackChunkName: "components/delete-dialog" */).then(c => wrapFunctional(c.default || c)),
  DownloadDialog: () => import('../../components/DownloadDialog.vue' /* webpackChunkName: "components/download-dialog" */).then(c => wrapFunctional(c.default || c)),
  DownloadTipDialog: () => import('../../components/DownloadTipDialog.vue' /* webpackChunkName: "components/download-tip-dialog" */).then(c => wrapFunctional(c.default || c)),
  Emoji: () => import('../../components/Emoji.vue' /* webpackChunkName: "components/emoji" */).then(c => wrapFunctional(c.default || c)),
  Empty: () => import('../../components/Empty.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c)),
  FileListDialog: () => import('../../components/FileListDialog.vue' /* webpackChunkName: "components/file-list-dialog" */).then(c => wrapFunctional(c.default || c)),
  FileListItem: () => import('../../components/FileListItem.vue' /* webpackChunkName: "components/file-list-item" */).then(c => wrapFunctional(c.default || c)),
  FileUploadList: () => import('../../components/FileUploadList.vue' /* webpackChunkName: "components/file-upload-list" */).then(c => wrapFunctional(c.default || c)),
  Footer: () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  Img: () => import('../../components/LazyImg.vue' /* webpackChunkName: "components/lazy-img" */).then(c => wrapFunctional(c.default || c)),
  LeaveDialog: () => import('../../components/LeaveDialog.vue' /* webpackChunkName: "components/leave-dialog" */).then(c => wrapFunctional(c.default || c)),
  Loader: () => import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c)),
  Loading: () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c)),
  LoadingBar: () => import('../../components/LoadingBar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c)),
  LocalUpload: () => import('../../components/LocalUpload.vue' /* webpackChunkName: "components/local-upload" */).then(c => wrapFunctional(c.default || c)),
  MediaItem: () => import('../../components/MediaItem.vue' /* webpackChunkName: "components/media-item" */).then(c => wrapFunctional(c.default || c)),
  PhoneAuth: () => import('../../components/PhoneAuth.vue' /* webpackChunkName: "components/phone-auth" */).then(c => wrapFunctional(c.default || c)),
  ResourceItem: () => import('../../components/ResourceItem.vue' /* webpackChunkName: "components/resource-item" */).then(c => wrapFunctional(c.default || c)),
  SaveDialog: () => import('../../components/SaveDialog.vue' /* webpackChunkName: "components/save-dialog" */).then(c => wrapFunctional(c.default || c)),
  Search: () => import('../../components/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c)),
  Share: () => import('../../components/Share.vue' /* webpackChunkName: "components/share" */).then(c => wrapFunctional(c.default || c)),
  UploadButton: () => import('../../components/UploadButton.vue' /* webpackChunkName: "components/upload-button" */).then(c => wrapFunctional(c.default || c)),
  UserHeader: () => import('../../components/UserHeader.vue' /* webpackChunkName: "components/user-header" */).then(c => wrapFunctional(c.default || c)),
  Waterfall: () => import('../../components/Waterfall.vue' /* webpackChunkName: "components/waterfall" */).then(c => wrapFunctional(c.default || c)),
  WaterfallHeader: () => import('../../components/WaterfallHeader.vue' /* webpackChunkName: "components/waterfall-header" */).then(c => wrapFunctional(c.default || c)),
  WaterfallItem: () => import('../../components/WaterfallItem.vue' /* webpackChunkName: "components/waterfall-item" */).then(c => wrapFunctional(c.default || c)),
  XlPayDialog: () => import('../../components/XlPayDialog.vue' /* webpackChunkName: "components/xl-pay-dialog" */).then(c => wrapFunctional(c.default || c)),
  XlUpload: () => import('../../components/XlUpload.vue' /* webpackChunkName: "components/xl-upload" */).then(c => wrapFunctional(c.default || c)),
  ActivityCountdownDialog: () => import('../../components/activity/CountdownDialog.vue' /* webpackChunkName: "components/activity-countdown-dialog" */).then(c => wrapFunctional(c.default || c)),
  HomeBanner: () => import('../../components/home/Banner.vue' /* webpackChunkName: "components/home-banner" */).then(c => wrapFunctional(c.default || c)),
  HomeIndexList: () => import('../../components/home/IndexList.vue' /* webpackChunkName: "components/home-index-list" */).then(c => wrapFunctional(c.default || c)),
  HomeNav: () => import('../../components/home/Nav.vue' /* webpackChunkName: "components/home-nav" */).then(c => wrapFunctional(c.default || c)),
  HomeTopic: () => import('../../components/home/Topic.vue' /* webpackChunkName: "components/home-topic" */).then(c => wrapFunctional(c.default || c)),
  MessageLikeItem: () => import('../../components/message/LikeItem.vue' /* webpackChunkName: "components/message-like-item" */).then(c => wrapFunctional(c.default || c)),
  DetailComment: () => import('../../components/detail/Comment.vue' /* webpackChunkName: "components/detail-comment" */).then(c => wrapFunctional(c.default || c)),
  DetailCommentDeleteDialog: () => import('../../components/detail/CommentDeleteDialog.vue' /* webpackChunkName: "components/detail-comment-delete-dialog" */).then(c => wrapFunctional(c.default || c)),
  DetailCommentInput: () => import('../../components/detail/CommentInput.vue' /* webpackChunkName: "components/detail-comment-input" */).then(c => wrapFunctional(c.default || c)),
  DetailCommentItem: () => import('../../components/detail/CommentItem.vue' /* webpackChunkName: "components/detail-comment-item" */).then(c => wrapFunctional(c.default || c)),
  DetailFeedFileItem: () => import('../../components/detail/FeedFileItem.vue' /* webpackChunkName: "components/detail-feed-file-item" */).then(c => wrapFunctional(c.default || c)),
  DetailFeedInfo: () => import('../../components/detail/FeedInfo.vue' /* webpackChunkName: "components/detail-feed-info" */).then(c => wrapFunctional(c.default || c)),
  DetailPagination: () => import('../../components/detail/Pagination.vue' /* webpackChunkName: "components/detail-pagination" */).then(c => wrapFunctional(c.default || c)),
  DetailSubComment: () => import('../../components/detail/SubComment.vue' /* webpackChunkName: "components/detail-sub-comment" */).then(c => wrapFunctional(c.default || c)),
  DetailSubCommentItem: () => import('../../components/detail/SubCommentItem.vue' /* webpackChunkName: "components/detail-sub-comment-item" */).then(c => wrapFunctional(c.default || c)),
  DetailUploadSelectFileList: () => import('../../components/detail/UploadSelectFileList.vue' /* webpackChunkName: "components/detail-upload-select-file-list" */).then(c => wrapFunctional(c.default || c)),
  SkeletonCommentLoader: () => import('../../components/skeleton/CommentLoader.vue' /* webpackChunkName: "components/skeleton-comment-loader" */).then(c => wrapFunctional(c.default || c)),
  SkeletonFeedCardLoader: () => import('../../components/skeleton/FeedCardLoader.vue' /* webpackChunkName: "components/skeleton-feed-card-loader" */).then(c => wrapFunctional(c.default || c)),
  SkeletonMessageLoader: () => import('../../components/skeleton/MessageLoader.vue' /* webpackChunkName: "components/skeleton-message-loader" */).then(c => wrapFunctional(c.default || c)),
  SkeletonZoneResourceLoader: () => import('../../components/skeleton/ZoneResourceLoader.vue' /* webpackChunkName: "components/skeleton-zone-resource-loader" */).then(c => wrapFunctional(c.default || c)),
  SpaceCategoryDeleteDialog: () => import('../../components/space/CategoryDeleteDialog.vue' /* webpackChunkName: "components/space-category-delete-dialog" */).then(c => wrapFunctional(c.default || c)),
  SpaceCategoryDialog: () => import('../../components/space/CategoryDialog.vue' /* webpackChunkName: "components/space-category-dialog" */).then(c => wrapFunctional(c.default || c)),
  SpaceCategoryTab: () => import('../../components/space/CategoryTab.vue' /* webpackChunkName: "components/space-category-tab" */).then(c => wrapFunctional(c.default || c)),
  SpaceFilterTab: () => import('../../components/space/FilterTab.vue' /* webpackChunkName: "components/space-filter-tab" */).then(c => wrapFunctional(c.default || c)),
  SpaceMoveDialog: () => import('../../components/space/MoveDialog.vue' /* webpackChunkName: "components/space-move-dialog" */).then(c => wrapFunctional(c.default || c)),
  VipMobilePay: () => import('../../components/vip/MobilePay.vue' /* webpackChunkName: "components/vip-mobile-pay" */).then(c => wrapFunctional(c.default || c)),
  VipPay: () => import('../../components/vip/Pay.vue' /* webpackChunkName: "components/vip-pay" */).then(c => wrapFunctional(c.default || c)),
  VipPayDialog: () => import('../../components/vip/PayDialog.vue' /* webpackChunkName: "components/vip-pay-dialog" */).then(c => wrapFunctional(c.default || c)),
  VipPayHeader: () => import('../../components/vip/PayHeader.vue' /* webpackChunkName: "components/vip-pay-header" */).then(c => wrapFunctional(c.default || c)),
  VipPayResult: () => import('../../components/vip/PayResult.vue' /* webpackChunkName: "components/vip-pay-result" */).then(c => wrapFunctional(c.default || c)),
  VipPrivilege: () => import('../../components/vip/Privilege.vue' /* webpackChunkName: "components/vip-privilege" */).then(c => wrapFunctional(c.default || c)),
  PublishEditor: () => import('../../components/publish/Editor.vue' /* webpackChunkName: "components/publish-editor" */).then(c => wrapFunctional(c.default || c)),
  PublishHyperlinkDialog: () => import('../../components/publish/HyperlinkDialog.vue' /* webpackChunkName: "components/publish-hyperlink-dialog" */).then(c => wrapFunctional(c.default || c)),
  PublishUploadSelectFileList: () => import('../../components/publish/UploadSelectFileList.vue' /* webpackChunkName: "components/publish-upload-select-file-list" */).then(c => wrapFunctional(c.default || c)),
  ZoneResourceList: () => import('../../components/zone/ResourceList.vue' /* webpackChunkName: "components/zone-resource-list" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
