const genDeviceInfo = async () => {
  if (process.client) {
    const {
      checkCanUseNativeApi,
      createNativeApi,
    } = require("@xunlei/xluser-utilities/dist/native-api");

    const {
      DeviceSignUtil,
      deviceSign2DeviceId,
    } = require("@xunlei/xluser-utilities/dist/device-sign-util");

    let nativeApi: any = null;
    if (checkCanUseNativeApi()) {
      nativeApi = createNativeApi();
      await nativeApi.init();
    }

    const deviceSignUtil = new DeviceSignUtil();
    const apiOrigin = "https://xluser-ssl.xunlei.com";

    await deviceSignUtil.init(apiOrigin, nativeApi);
    const deviceSign = deviceSignUtil.getDeviceSign();
    const deviceID = deviceSign2DeviceId(deviceSign);

    return {
      deviceSign,
      deviceID,
    };
  }

  return {
    deviceSign: "",
    deviceID: "",
  };
};

export default genDeviceInfo;
