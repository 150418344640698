import qs from "qs";
import pkg from "../package.json";
import { getStore } from "@/plugins/vuex-store";
import getUserInfo from "@/utils/get-user-info";
import {
  isMacThunder,
  clientType,
  inClientPlatformNames,
  getPlatformName,
} from "@/utils/util";

function isEmbededBrowser() {
  if (process.client) {
    const name = getPlatformName();
    if (inClientPlatformNames.includes(name)) {
      return "in";
    }
  }
  return "out";
}

export async function stat(category: string, action: string, data: any) {
  if (process.env.NODE_ENV === "development") {
    console.log("📈", category, action, data);
  }
  const store = getStore();
  const userid = store.state.user.curUser.userId || "";
  const isVip = !!(
    store.state.user.curUser.vipData && store.state.user.curUser.vipData.isVip
  );

  const vipType =
    store.state.user.curUser.vipData &&
    store.state.user.curUser.vipData.vipType;

  const xlVipType =
    store.state.user.curUser.xlVipData &&
    store.state.user.curUser.xlVipData.vipType;

  let [peerId] = await getUserInfo();

  // TODO: peerId统一
  if (process.client) {
    if (window.native) {
      const xlpc = require("@/utils/xl-pc").default;
      peerId = await xlpc.getPeerID();
    } else if (isMacThunder()) {
      const { macApi } = require("@xunlei/universal-native-api");
      const infoRes = await macApi.call("getPlatformInfo", {
        keys: ["peerId"],
      });
      peerId = infoRes.peerId;
      console.log("macThunder peerid >>>", peerId);
    }
  }

  let firstPage: any = "";
  let currentPage = "";
  let entrySource: any = "";
  if (process.client) {
    const curPageMap: any = {
      space: "personal",
      detail: "detail",
      search: "search",
      publish: "publish",
      message: "message",
      zone: "zone",
      activity: "activity",
    };
    const searchParams = window.location.search.split("?");
    const pathnameArr = window.location.pathname.split("/");

    if (searchParams[1]) {
      const queryObj = qs.parse(searchParams[1]);

      if (Array.isArray(queryObj.entry_from)) {
        firstPage = queryObj.entry_from[0] || "";
      } else {
        firstPage = queryObj.entry_from || "";
      }

      if (Array.isArray(queryObj.entry_source)) {
        entrySource = queryObj.entry_source[0] || "";
      } else {
        entrySource = queryObj.entry_source || "";
      }
    }

    if (pathnameArr[1]) {
      currentPage = curPageMap[pathnameArr[1]];
    } else {
      currentPage = "home";
    }

    if (!firstPage) {
      firstPage = currentPage;
    }
  }

  xla2.push({
    type: "event",
    category: category || "page_event",
    action,
    extdata: {
      clientType: clientType(),
      businessType: "xl_xiaozhan",
      userid, // 用户userid (迅雷账号，未登录为0)
      peerid: peerId, // PC迅雷设备id，获取不到为null
      guid: "", // 手雷设备id，获取不到为null
      productVersion: pkg.version,
      firstPage,
      currentPage,
      entrySource,
      inClient: isEmbededBrowser(),
      is_vip: userid && userid !== "0" ? (!isVip ? 1 : 2) : 0,
      vip_type: vipType ? vipType : 0,
      xl_vip_type: xlVipType ? xlVipType : 0,
      zone_name: "",
      ...data,
    },
  });
}

export function addExtData(data: any): void {
  xla2.push({
    type: "globalExtData",
    data,
  });
}

        export function pub8zEF6dkkcM0o37k3k1UUlyi6ueNap8Vs (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/captcha.ts').pubxYWrjAJFTCzGc6kdhdR2Sz9pLferkntr;
            const nextPart2 = require('@/utils/get-user-info.ts').pubwfAdPQDvP3VIfuv9XMIkUN3zHiOe8bjU;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubmTyUHwx7NVrphxQldM2IIUF14nqT3Ylj (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/xl-client-common.ts').pubXdLVT2jPUSmZctO5iKxMZQ0fHpdqx96R;
            const nextPart2 = require('@/utils/static-icons.ts').puboEKvW3LWFCa3T9mA6p4SPkB5I88IJhnG;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pub71oOHGkOz147wFP5y0SrJLFHfS0UglTd (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'k0uWNUf8KcRNx827Ny3WcSkZl', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/sso.ts').pubbPoXLJFIP8qhnih0enW8d2I55WrCutPa;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pub7Lqr5qubsvmQRN3W2CgD2tkaLfk1Cq5Y (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/filters.ts').pubj4Z05f4yn57cDTjrwoUHlrBvRpBNfeVF;
            const nextPart2 = require('@/utils/stat.ts').pubYoclDQeOKkmRDdEew7xKgm4IDe5Cqbyj;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubEd2bF2Ur3oue2WhKTbjjor3aoBqbsf9K (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'X', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/code-res.ts').pubmSg3aiWzUHnlUakiYBf67R2wQ8LceZa0;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pub9ql5siKjyHMokeoHnl9nih1lRCORPrTM (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'VH2AoGv1bo', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/util.ts').pub5TGinsoRaICNMNFBUMJAfoELzyT9FWA4;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pub2OLteEUgckl0A5ngJASg7jv1r9JTxpIx (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/code-res.ts').pub3YUNxSEvN3V8QbMWdCLLnvZqTg1Mc9Tb;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }