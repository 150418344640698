import sget from "@xunlei/sget";

function toType(obj: any) {
  return obj === null || obj === undefined
    ? String(obj)
    : ({} as any).toString
        .call(obj)
        .match(/\[object (\w+)\]/)[1]
        .toLowerCase();
}

async function waitTillTimeout(
  promise: Promise<any>,
  timeout = 10 * 1000,
  message = `请求超时, 请稍后重试`
) {
  let finalReject: any = null;
  const timeoutPromise = new Promise(
    (resolve, reject) => (finalReject = reject)
  );

  let timeoutTimer: any = null;

  if (timeout) {
    timeoutTimer = setTimeout(() => finalReject(new Error(message)), timeout);
  }
  try {
    return await Promise.race([promise, timeoutPromise]);
  } finally {
    if (timeoutTimer) {
      clearTimeout(timeoutTimer);
    }
  }
}

const cacheObj: any = {};

const clearCachePromise = (key: string) => {
  cacheObj[key] = null;
};

function markCachePromise(
  fn: Function,
  options = { isCacheResolve: false, isCacheReject: false, cacheKey: "" }
) {
  const { isCacheResolve, cacheKey, isCacheReject } = options;
  return function (...args: any) {
    if (!cacheKey) {
      return fn.call(this, ...args);
    }

    let promise = cacheObj[cacheKey];

    if (!promise) {
      promise = fn.call(this, ...args).then(
        (resolveResult: any) => {
          if (!isCacheResolve) {
            cacheObj[cacheKey] = null;
          }
          return Promise.resolve(resolveResult);
        },
        (rejectResult: any) => {
          if (!isCacheReject) {
            cacheObj[cacheKey] = null;
          }
          return Promise.reject(rejectResult);
        }
      );
      cacheObj[cacheKey] = promise;
    }

    return promise;
  };
}

function changeUrl(url: string, arg: any, argVal: any) {
  var pattern = "[?&]" + arg + "=([^&]*)";
  var replaceText = arg + "=" + argVal;
  var hash = url.split("#")[1];
  url = url.split("#")[0];
  if (url.match(pattern)) {
    /* eslint-disable no-useless-escape, no-eval */
    var tmp = "/([&?]" + arg + "=)([^&]*)/gi";
    tmp = url.replace(eval(tmp), function (match, p1, p2) {
      if (argVal === "") {
        return "";
      }
      return p1 + argVal;
    });
    url = tmp;

    // argVal=== ''时 会清空参数, 假设参数为第1个, 需要把第二个参数的&改为?
    if (url.indexOf("&") !== -1 && url.indexOf("?") === -1) {
      url = url.replace(/&/, "?");
    }
    /* eslint-enable no-useless-escape, no-eval */
  } else {
    if (url.match("[?]")) {
      url = url + "&" + replaceText;
    } else {
      url = url + "?" + replaceText;
    }
  }
  return hash ? url + "#" + hash : url;
}

// TODO: cache

const loadJs = function (url: string, options: any) {
  return new Promise((resolve, reject) => {
    const { resolveResultFn, urlCacheTime } = options;
    // if (!isNaN(urlCacheTime)) {
    //   url = changeUrl(url, "_cacheTime", new Date().getTime() / urlCacheTime);
    // }

    const s: HTMLScriptElement = document.createElement("script");
    const finalResolve = () => {
      resolve(
        toType(resolveResultFn) === "function" ? resolveResultFn() : undefined
      );
    };

    s.onload = function () {
      finalResolve();
    };

    s.onerror = function (e: any) {
      reject(new Error(`资源加载异常, 请稍后重试。url: ${url}, e: ${e}`));
    };

    // s.crossOrigin = 'anonymous'
    s.src = url;
    s.async = true;
    document.getElementsByTagName("head")[0].appendChild(s);
  });
};

export default async function (url: string, options = { timeout: 10 * 1000 }) {
  const { timeout } = options;

  return waitTillTimeout(
    markCachePromise(loadJs, {
      isCacheResolve: true,
      cacheKey: sget(options, "cacheKey") || "",
    } as any)(url, options),
    timeout,
    "资源请求超时, 请稍后重试"
  );
}
