if (typeof xl9 === 'undefined') {
  (global as any).xl9 = {}
}

xl9.promiseApi = function () {
  var args = Array.prototype.slice.call(arguments) // 参数列表
  var name = args[0] // 方法名
  return new Promise(function (resolve, reject) {
    if (name in xl9.api) {
      xl9.api[name].apply(null, args.slice(1).concat(function (err: any, val: any) {
        if (err) {
          reject(err)
        } else {
          resolve(val)
        }
      }
      ))
    } else {
      xl9.api.call.apply(null, args.concat(function (err: any, val: any) {
        if (err) {
          reject(err)
        } else {
          resolve(val)
        }
      }))
    }
  })
}

// 迅雷9 API的Promise版本
export default xl9
