




















import Vue from "vue";
import eventEmitter from "@/utils/eventEmitter";
import VipPay from "@/components/vip/Pay.vue";
import MobileVipPay from "@/components/vip/MobilePay.vue";
import PayHeader from "@/components/vip/PayHeader.vue";
import { isPcPlatform } from "@/utils/util";
import eventMap from "@/utils/event-name";
import ssoMixin from "@/mixins/sso";

export default Vue.extend({
  name: "vip-pay-dialog",
  mixins: [ssoMixin],
  components: { VipPay, MobileVipPay, PayHeader },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    isVip() {
      return this.curUser.vipData && this.curUser.vipData.isVip;
    },
    isMobile() {
      return process.client && !isPcPlatform();
    },
  },
  watch: {
    visible(newVal, oldVal) {
      if (!newVal) {
        this.getUserInfo();
        // window.location.reload();
      }
    },
  },
  mounted() {},
  beforeDestroy() {
    eventEmitter.off(eventMap.SHOW_PAY_DIALOG, 0);
  },
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    close() {
      this.$emit("close");
    },
    iconClose() {
      this.close();
    },
  },
});
