const ua = navigator.userAgent;
const UA_IOS_REG = /\b(iPad|iPhone|iPod)\b.*? OS ([\d_]+)/;
const isIOS13Pad =
  navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
export const isIOS = UA_IOS_REG.test(ua) || isIOS13Pad;

const callbacks: any = (window.G_callbacks = {
  _id: 0,
  _getId: function () {
    return this._id++;
  },
});

export function sendMessage({
  jsBridge,
  method,
  target,
  params,
  isCallBack = false,
  callbackTimeout = 5000,
  isJSON = true,
}: any) {
  if (!window[jsBridge]) {
    return Promise.reject("不在客户端环境内");
  }
  const clientPromise = new Promise((resolve, reject) => {
    // 默认以全局callback的形式进行回调
    if (isCallBack) {
      (window as any)[jsBridge][method](target, params, (...args: any) => {
        resolve(args);
      });
    } else {
      let callbackName: any = null;
      const callbackKey = "_callback_" + callbacks._getId() + "_" + target;
      callbacks[callbackKey] = function (...args: any) {
        callbacks[callbackKey] = null;
        (window as any)[callbackName] = null;
        const data = args && isJSON ? JSON.parse(args) : args;
        resolve(data);
      };
      callbackName = "window.G_callbacks." + callbackKey;
      console.log(target, params);

      (window as any)[jsBridge][method](
        target,
        JSON.stringify(params),
        callbackName
      );
    }
  });
  return Promise.race([
    clientPromise,
    promiseTimeOut(callbackTimeout, `${target}-客户端通信超时`),
  ]);
}

function promiseTimeOut(time: number, reason = "") {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(reason);
    }, time);
  });
}

        export function pubr7TZWh6DsVWicnyfP3CZdoJUCaEACzBl (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/util.ts').pubcpsiETlzaaVeTi58EKlmlbXbG3gtCvTQ;
            const nextPart2 = require('@/utils/sso.ts').pubu0eSXzTdX5VNLxqN3gdPX6JrMMycjDDP;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }