import { IClientTaskParams } from "@/types/common";

// 获取应用peerid
const getPeerID = () => {
  return new Promise((resolve, reject) => {
    if (!window.native) {
      return resolve("");
    }

    window.native.CallNativeFunction(
      "GetPeerID",
      (err: number, peerid: string) => {
        // err 错误码，0 表示成功，1 表示接口不存在，2 参数错误，3 调用失败，4 非白名单域名不允许调用本地接口
        console.log("peerid", peerid);
        if (err === 0 && peerid) {
          resolve(peerid);
        } else {
          resolve("");
        }
      }
    );
  });
};

const checkIsLogined = () => {
  return new Promise((resolve, reject) => {
    if (!window.native) {
      return resolve(false);
    }

    window.native.CallNativeFunction(
      "IsLogined",
      (err: number, logined: boolean) => {
        if (err === 0 && logined) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    );
  });
};

// 拉起客户端登录框
const showLoginDialog = () => {
  return new Promise((resolve, reject) => {
    if (!window.native) {
      return resolve(false);
    }

    // from表示来源，用于统计，可不传
    window.native.CallNativeFunction(
      "ShowLoginDlg",
      "zhan.xunlei.com",
      (err: number) => {
        if (err === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    );
  });
};

// 监听客户端登录事件
const watchUserLogin = () => {
  return new Promise((resolve, reject) => {
    if (!window.native) {
      return resolve("");
    }

    window.native.AttachNativeEvent("OnUserLogin", (userid: string) => {
      console.log("OnUserLogin", userid);
      resolve(userid);
    });
  });
};

// 监听客户端登出事件
const watchUserLogout = () => {
  return new Promise((resolve, reject) => {
    if (!window.native) {
      return resolve("");
    }

    window.native.AttachNativeEvent("OnUserLogout", (userid: string) => {
      console.log("OnUserLogout", userid);
      resolve(userid);
    });
  });
};

const callDirectDownload = (params: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (!window.native) {
      return resolve({ result: "fail" });
    }

    window.native.CallNativeFunction(
      "CreatePanExternalFetchBackTask",
      params,
      (code: number) => {
        if (code === 0) {
          resolve({ result: "success", code });
        } else {
          resolve({ result: "fail", code });
        }
      }
    );
  });
};

const getThunderVersion = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!window.native) {
      return resolve("");
    }

    window.native.CallNativeFunction(
      "GetThunderVersion",
      (code: string, data: string) => {
        resolve(data);
      }
    );
  });
};

const xlpc = {
  getPeerID,
  checkIsLogined,
  showLoginDialog,
  watchUserLogin,
  watchUserLogout,
  callDirectDownload,
  getThunderVersion,
};

export default xlpc;
