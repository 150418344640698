import { IObjectKeys } from '@/types/common'

// result 的错误信息
const resultErrorMap: IObjectKeys = {
  'server error': '服务器异常',
  'params error': '参数错误',
  'login plz': '未登录',
  'shield review': '风险账号',
  'shield reject': '异常账号',
  'not allowed': '无权限做此操作',
  'not found': '未找到资源',
  'exists': '已存在',
  'too many': '超过操作上限',
  'has voted': '你已经投过票了'
}

// detail 的错误信息
const detailErrorMap: IObjectKeys = {
  'not found circle': '未找到该圈子',
  'content too long': '超出字数限制'
}

export function errorMap ({ result, detail, defaultError = '错误' }: IObjectKeys  = {}) {
  return detailErrorMap[detail] || resultErrorMap[result] || defaultError
}

        export function pubk1pmunSNtGFTHGkFjxcwlxOQPFSCJXLH (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/get-user-info.ts').pubmOs6D0qbOYVd970ROLanUPsV17wbRqV2;
            const nextPart2 = require('@/utils/code-res.ts').pubCK09smjFgXFLq3SDGIhyPj9L8moz56ef;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubFLKM8AcEVgcR07nP4lPBGGyY68xvfPti (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/xl-client-common.ts').pubLLG3rMz1mY2eaFYPjYyFdBff8OxOpLMd;
            const nextPart2 = require('@/utils/util.ts').pubrp9VblPBYkEWCQbFTYwkrzKwRXD6D5IB;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pub4yPoE2HBDD3NaxaPHz37M1gLIfs75a73 (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'FD6yJYwm674Lqpq2Uef8ddMVUkIu', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/filters.ts').pubz1HUQfQMYXjQ1EoScSv1TWyc9kbSnv1L;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubZzCKrM6ysGkkXzNiht5AbyIxQu4gda8Y (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: '5VPEEdl7kspPqlkLzLDztG0uqkQL', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/get-user-info.ts').pubVrSiFxyN11ikpGJuNgGt5qfrVzLLIR2J;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
          export function pubjrflzL41lLWkM1DcU2seka6c4S2mGD5z (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: 'K07Bnwd36qIlmD6CSLJbjOcok', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/drive-util.ts').pubndJ1xuSKXyaxeIQwtGfmhFSPLOytkz1k;
              const nextPart2 = require('@/utils/util.ts').pubTVhPb4FTfAGTd7GLuIjxMjD7jAcLIJvh;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }
          export function pubFIQZrMs6O47aEglEI61fgS0RrprzuNY7 (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: 'k7', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/sso.ts').pubGq0mW3rApZHr0vAJZbU1ZqyPDswAWPLt;
              const nextPart2 = require('@/utils/util.ts').pub5TGinsoRaICNMNFBUMJAfoELzyT9FWA4;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }
          export function pubYiAEFMCBBWkq4cuuKo9hH8ecFHvaAOn6 (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: 'kvyvt4COAt', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/error-message.ts').pubvyG8PYpDQOzLmJW89tFVHEoAkjHxIFqt;
              const nextPart2 = require('@/utils/static-icons.ts').pubc3HFOwfwiVkU3ObWVtDuFaokdziCZSIz;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }
          export function pubvyG8PYpDQOzLmJW89tFVHEoAkjHxIFqt (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: 'nrUJsI7cXAFLUyRbh1s6k9BACoI8+Ih3yhpjVGW20ABHkV', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/sso.ts').pubRLKRxhmaRUGSgaZulBQ6DQ9HurKFrkYa;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }