import { fetchZhanData } from "@/api/fetch-res";
import {
  IThinkSearchParams,
  ISpaceSearchParams,
  IZhanInfoParams,
  IFeedParams,
  IFeedInfoParams,
  IUserFeedParams,
  IFeedDeleteParams,
  IFeedCreateParams,
  IFeedChannelParams,
  IFeedChannelDeleteParams,
  IFeedChannelCreateParams,
  IFeedChannelUpdateParams,
  IFeedChannelModifyParams,
  IFeedLabelParams,
  IFavoriteParams,
  IProfileParams,
  IUserFavoriteParams,
  IReportDownloadParams,
  IDailyStatParams,
  IDailyStatBody,
  IUserMessageParams,
  ITextSensitiveParams,
  ICommentParams,
  ICommentCreateParams,
  ICommentActionParams,
  IZoneListParams,
  IZoneInfoParams,
  IZoneResourceParams,
  IZoneLabelResourceParams,
  IZoneActionParams,
  IThirdPartyUrlParams,
  IContentSearchParams,
  IHomeBannerParams,
  IHotwordParams,
  IHomeTopicParams,
} from "@/types/post";

function thinkSearch(params: IThinkSearchParams): Promise<any> {
  return fetchZhanData("/search/", params, {});
}

function userFeedSearch(params: ISpaceSearchParams): Promise<any> {
  return fetchZhanData("/square/v1/resources/search", params, {});
}

function fetchZhanInfo(params: IZhanInfoParams): Promise<any> {
  return fetchZhanData(`/square/v1/info/${params.userid}`, {}, {});
}

function fetchHomeFeedList(params: IFeedParams): Promise<any> {
  return fetchZhanData("/square/v1/resources/feed", params, {});
}

function fetchUserFeedList(params: IUserFeedParams): Promise<any> {
  return fetchZhanData("/square/v1/resources/list", params, {});
}

function fetchFeedInfo(params: IFeedInfoParams): Promise<any> {
  return fetchZhanData(`/square/v1/resources/${params.id}`, {}, {});
}

function createFeed(params: IFeedCreateParams): Promise<any> {
  return fetchZhanData("/square/v1/resources/create", params, {
    method: "POST",
  });
}

function modifyFeed(params: IFeedCreateParams): Promise<any> {
  return fetchZhanData(`/square/v1/resources/${params.id}`, params, {
    method: "PUT",
  });
}

function deleteFeed(params: IFeedDeleteParams): Promise<any> {
  return fetchZhanData(
    `/square/v1/resources/${params.id}`,
    {},
    {
      method: "DELETE",
    }
  );
}

function fetchFeedCategory(): Promise<any> {
  return fetchZhanData("/square/v1/category", {}, {});
}

function fetchFeedChannel(params: IFeedChannelParams): Promise<any> {
  return fetchZhanData("/square/v1/channel", params, {});
}

function createFeedChannel(params: IFeedChannelCreateParams): Promise<any> {
  return fetchZhanData("/square/v1/channel", params, {
    method: "POST",
  });
}

function modifyFeedChannel(params: IFeedChannelUpdateParams): Promise<any> {
  return fetchZhanData("​/square/v1/channel", params, {
    method: "PUT",
  });
}

function batchModifyFeedChannel(params: {
  data: IFeedChannelModifyParams[];
}): Promise<any> {
  return fetchZhanData("​/square/v1/channel/batch", params, {
    method: "PUT",
  });
}

function deleteFeedChannel(params: IFeedChannelDeleteParams): Promise<any> {
  return fetchZhanData(
    `/square/v1/channel/${params.id}`,
    {},
    {
      method: "DELETE",
    }
  );
}

function fetchFeedLabel(params: IFeedLabelParams): Promise<any> {
  return fetchZhanData("/square/v1/label", params, {});
}

function fetchUserFavoriteList(params: IUserFavoriteParams): Promise<any> {
  return fetchZhanData("/square/v1/favorite/resource", params, {});
}

function favoriteFeed(params: IFavoriteParams): Promise<any> {
  return fetchZhanData("​/square/v1/favorite", params, {
    method: "POST",
  });
}

function cancelFavoriteFeed(params: IFavoriteParams): Promise<any> {
  return fetchZhanData(
    `/square/v1/favorite/${params.rid}`,
    {},
    {
      method: "DELETE",
    }
  );
}

function fetchProfileFeed(): Promise<any> {
  return fetchZhanData("/square/v1/profile/feed", {}, {});
}

function modifyProfile(params: IProfileParams): Promise<any> {
  return fetchZhanData("​/square/v1/profile", params, {
    method: "PUT",
  });
}

function reportUserDownload(params: IReportDownloadParams): Promise<any> {
  return fetchZhanData("​/square/v1/download/record", params, {
    method: "POST",
  });
}

function fetchUserMessage(params: IUserMessageParams): Promise<any> {
  return fetchZhanData("/square/v1/message/list", params, {});
}

function fetchDailyStat(params: IDailyStatParams): Promise<any> {
  return fetchZhanData("/square/v1/message", params, {});
}

function readDailyStat(params: IDailyStatBody): Promise<any> {
  return fetchZhanData("​/square/v1/message", params, {
    method: "PUT",
  });
}

function checkTextSensitive(params: ITextSensitiveParams): Promise<any> {
  return fetchZhanData("​/square/v1/sensitive/check", params, {
    method: "POST",
  });
}

function fetchFeedComment(params: ICommentParams): Promise<any> {
  return fetchZhanData("/square/v1/comment/list", params, {});
}

function createFeedComment(params: ICommentCreateParams): Promise<any> {
  return fetchZhanData("​/square/v1/comment/create", params, {
    method: "POST",
  });
}

function deleteFeedComment(params: ICommentActionParams): Promise<any> {
  return fetchZhanData(
    `/square/v1/comment/${params.comment_id}?tid=${params.tid}`,
    {},
    {
      method: "DELETE",
    }
  );
}

function likeFeedComment(params: ICommentActionParams): Promise<any> {
  return fetchZhanData("​/square/v1/comment/like", params, {
    method: "POST",
  });
}

function unlikeFeedComment(params: ICommentActionParams): Promise<any> {
  return fetchZhanData("​/square/v1/comment/unlike", params, {
    method: "POST",
  });
}

function fetchZoneList(params: IZoneListParams): Promise<any> {
  return fetchZhanData("/square/v1/class/list", params, {});
}

function fetchZoneResource(params: IZoneResourceParams): Promise<any> {
  return fetchZhanData("/square/v1/class/resource/list", params, {});
}

function fetchZoneInfo(params: IZoneInfoParams): Promise<any> {
  return fetchZhanData(`/square/v1/class/id/${params.id}`, {}, {});
}

function fetchZoneLabelResource(
  params: IZoneLabelResourceParams
): Promise<any> {
  return fetchZhanData("/square/v2/resource/list", params, {});
}

function fetchThirdPartyJumpUrl(params: IThirdPartyUrlParams): Promise<any> {
  return fetchZhanData("/square/v1/jump_url", params, {});
}

function contentSearch(params: IContentSearchParams): Promise<any> {
  return fetchZhanData("/square/v2/resources/search", params, {});
}

function fetchHomeBanner(params: IHomeBannerParams): Promise<any> {
  return fetchZhanData("/square/v1/banner", params, {});
}

function fetchHotword(params: IHotwordParams): Promise<any> {
  return fetchZhanData("/square/v1/hot_words", params, {});
}

function fetchHomeTopic(params: IHomeTopicParams): Promise<any> {
  return fetchZhanData("/square/v1/topic", params, {});
}

export default {
  thinkSearch,
  userFeedSearch,
  fetchHomeFeedList,
  fetchUserFeedList,
  fetchZhanInfo,
  fetchFeedInfo,
  createFeed,
  modifyFeed,
  deleteFeed,
  fetchFeedCategory,
  fetchFeedChannel,
  createFeedChannel,
  modifyFeedChannel,
  batchModifyFeedChannel,
  deleteFeedChannel,
  fetchFeedLabel,
  fetchUserFavoriteList,
  favoriteFeed,
  cancelFavoriteFeed,
  fetchProfileFeed,
  modifyProfile,
  reportUserDownload,
  fetchUserMessage,
  fetchDailyStat,
  readDailyStat,
  checkTextSensitive,
  fetchFeedComment,
  createFeedComment,
  deleteFeedComment,
  likeFeedComment,
  unlikeFeedComment,
  fetchZoneList,
  fetchZoneInfo,
  fetchZoneResource,
  fetchZoneLabelResource,
  fetchThirdPartyJumpUrl,
  contentSearch,
  fetchHomeBanner,
  fetchHotword,
  fetchHomeTopic,
};
