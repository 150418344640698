import { GetterTree, ActionTree, MutationTree } from "vuex";
import { UserModuleState } from "@/store/user";
import {
  IActivityParams,
  IActivityResult,
  IActivityInfoParams,
  IActivityInfoResult,
  IActivitySelectionParams,
  IActivitySelectionResult,
  IActivityCollectionParams,
  IActivityCollectionResult,
} from "@/types/activity";
import api from "@/api/activity";

const LIMIT = 10;

export const state = () => ({
  name: "activity",
  list: [],
  info: {},
  selection: [],
  collection: [],
});

export type ActivityModuleState = ReturnType<typeof state>;

export const getters: GetterTree<ActivityModuleState, UserModuleState> = {
  name: (state) => state.name,
};

export const mutations: MutationTree<ActivityModuleState> = {
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_ACTIVITY: (state, list) => {
    state.list = list;
  },
  SET_ACTIVITY_INFO: (state, info) => {
    state.info = info;
  },
  SET_ACTIVITY_SELECTION: (state, info) => {
    state.selection = info;
  },
  SET_ACTIVITY_COLLECTION: (state, info) => {
    state.collection = info;
  },
};

export const actions: ActionTree<ActivityModuleState, UserModuleState> = {
  nuxtServerInit({ commit }, { req }) {
    console.log(req.auth);
  },
  fetchList({ commit, state }, params: IActivityParams) {
    return api
      .fetchActivity(params)
      .then((res: IActivityResult) => {
        commit("SET_ACTIVITY", res.data);
        return res;
      })
      .catch((e) => {
        return [];
      });
  },
  fetchInfo({ commit, state }, params: IActivityInfoParams) {
    return api
      .fetchInfo(params)
      .then((res: IActivityInfoResult) => {
        commit("SET_ACTIVITY_INFO", res.data);
        return res;
      })
      .catch((e) => {
        return {};
      });
  },
  fetchSelection({ commit, state }, params: IActivitySelectionParams) {
    const activity_id = params;
    const defaultData = {
      data: [
        {
          activity_id,
          name: "编辑推荐",
          order: 1,
          res_list: [
            {
              id: "VMtw165YcOMBZZHZ6D9jXaIL00",
              name: "虎年红包封面微信素材",
              order: 2,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/528c4ec7eddf9a0255bb82c9e0a46af5ce52383d_w_640?auth_key=1642998387-cfa0a3b26ec5443aa469595d24b3301d-0-a382f0a0eca8e04ee5cce1d22aca3eeb",
              download_count: "0",
              view_count: "2",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1AQrpoN3iwB2EyZTkaCmq-9uMV",
              payment: 2,
            },
            {
              id: "VMtuT33NQ4W0JxkGdK2RiuM500",
              name: "NBA总决赛视频吐血整理",
              order: 1,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/891f2dc445a1a545e133868c77748b9e299c1534_w_640?auth_key=1642996256-399a9f40268f467b8b87a16393846257-0-c552d37ecdc6a28e827909ecabbb0bbb",
              download_count: "0",
              view_count: "3",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1AeavmcTM-HrFJEn2JQXis8uMV",
              payment: 2,
            },
            {
              id: "VMtfAHRisobLnnEqCiwZGZwy00",
              name: "高清壁纸 2560*1920",
              order: 4,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/3394b25f8c21aac41f1b76473db38a2aa66b8fb2_w_640?auth_key=1642481028-b88a8dd34cf846b196bb556f4f3d395d-0-2e2339c746d96c40ca9a9f9b416e5270",
              download_count: "0",
              view_count: "7",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1AizdRHN9fmiu9lHpLNSHAftMV",
              payment: 2,
            },
            {
              id: "VMtb41c5Q4W0JxkGdK2Ri_6200",
              name: "全网最全表情包打包",
              order: 3,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/fd76886adebcae8de3c60705aa770adb1b757860?auth_key=1642413009-37e9e02b3406451797e640d7a35cf0ec-0-fc0b3692272a7419bc9ee3c2e6e9fa36",
              download_count: "0",
              view_count: "5",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1A7li8N_44qfJ5h4vtIMo6btMV",
              payment: 2,
            },
          ],
        },
        {
          activity_id,
          name: "颜值部落",
          order: 2,
          res_list: [
            {
              id: "VMtw-8U5pideyjI51tg7eJR800",
              name: "韩国女团舞蹈视频合集",
              order: 4,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/05163208c23886ef5a6201867ad37e35798dc104_w_640?auth_key=1642998676-0758a0cd992442879eb26d81e8e33803-0-22c76eed664ae91fca57cc3a66dfcb41",
              download_count: "0",
              view_count: "2",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1Abg3pN3iwB2EyZTkawLx09uMV",
              payment: 2,
            },
            {
              id: "VMtfE4M91f65pcEPQePV6mOV00",
              name: "三次元清纯妹子图集",
              order: 3,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/12690c8c920d58ee4444c23779b998bb2568b3c4_w_640?auth_key=1642482024-8b37edce00464bf8b33f21eda28b434a-0-caaf583fcb9e0f52fbacfff47f85f273",
              download_count: "0",
              view_count: "6",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1AwTVJdrYw7dOk74TA6N4EftMV",
              payment: 2,
            },
            {
              id: "VMtbHRHVQ4W0JxkGdK2Ri_7X00",
              name: "高清美女车模素材",
              order: 1,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/3178eb766fc8e6232cbc5a7bfe4adf1e6b8d6897_w_640?auth_key=1642999318-73cea702184a4d85815745bb834f5ffe-0-0e383366bf238856ed162f7ff1f32467",
              download_count: "0",
              view_count: "6",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1AIfQw9m967Uney01iF1P39uMV",
              payment: 2,
            },
            {
              id: "VMdv92v3z8tD0KwNzzURik0j00",
              name: "遇见动漫美女",
              order: 2,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/adc17cd02cd6466a4c652f7a9278dc84606699a1_w_640?auth_key=1640664861-cf8a32b461734cd38e0dea6b1aaa08d2-0-4aa7148ea9fb69256342db0af9c99601",
              download_count: "593",
              view_count: "2809",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1AYWHIh3gsI-7mlIZTcu29vdMV",
              payment: 2,
            },
          ],
        },
        {
          activity_id,
          name: "男生最爱",
          order: 3,
          res_list: [
            {
              id: "VMtuhPWgk08X_jtE10SLB1XB00",
              name: "欧冠精彩比赛全集",
              order: 2,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/1188e92d34e93da77ba55b7a9ef5e7ebda40dc28_w_640?auth_key=1642996807-27af7b85785644e6a5980f3b236097ce-0-96fa4f705ff80fd9b7ba7457944d6cbd",
              download_count: "0",
              view_count: "3",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1A-aMncTM-HrFJEn2JRApu8uMV",
              payment: 2,
            },
            {
              id: "VMtuT33NQ4W0JxkGdK2RiuM500",
              name: "NBA总决赛视频吐血整理",
              order: 1,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/891f2dc445a1a545e133868c77748b9e299c1534_w_640?auth_key=1642996256-399a9f40268f467b8b87a16393846257-0-c552d37ecdc6a28e827909ecabbb0bbb",
              download_count: "0",
              view_count: "3",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1AeavmcTM-HrFJEn2JQXis8uMV",
              payment: 2,
            },
            {
              id: "VMhYQYXKP0l5i6cxlaLwnz9m00",
              name: "超跑高清合集",
              order: 3,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/939ad32cb5c282e787ee24a965991a2798ec10ac_w_640?auth_key=1640664808-382aaece9d844ac581243bcdd361cf43-0-065b41a02c78b3a0758acc7b675d977d",
              download_count: "197",
              view_count: "622",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1Augmt8oOvjZ7lROVo77l80iMV",
              payment: 2,
            },
            {
              id: "VMdRroGqtI46zpEPNTKnjgmG00",
              name: "【4K超高清桌面壁纸】宅男福音第三波🌷",
              order: 4,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/6759C164A448041F75611F31AE4B1E9170D27D12_w_640?auth_key=1640661861-2be36ac5677245c7be91bc40f30581c8-0-dce46b241842e2d8f87ca252b4c33215",
              download_count: "3076",
              view_count: "21312",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1ASachIf91M9uaY_h8NGorRdMV",
              payment: 2,
            },
          ],
        },
        {
          activity_id,
          name: "办公学习",
          order: 4,
          res_list: [
            {
              id: "VMtvwPoCkLDGbuFx-iua1BjB00",
              name: "求职必备创意简历合集",
              order: 4,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/a7ef0f7409d2d505240ee620ff4d290de4ad1e01_w_640?auth_key=1642762343-fe3dd3acde82433c96f00d1411c45b5c-0-737d3b209afaa7a743d830e28330307e",
              download_count: "0",
              view_count: "1",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1AeeT2aosbMV5dJ-CznoPwvtMV",
              payment: 2,
            },
            {
              id: "VMtasiQWsobLnnEqCiwZGZjl00",
              name: "考研数学资料打包",
              order: 2,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/f65caffb84eb6071e840b113c19d8e752e5716b0_w_640?auth_key=1642409053-493e1703069e4e04a937c4e9bac1afc5-0-846dd34c768cfa33956ef518e939038d",
              download_count: "1",
              view_count: "5",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1ATvDy1PEsUVjpX9X6PRisatMV",
              payment: 2,
            },
            {
              id: "VMhYB4Lfpk00Mn_WupFQa_ch00",
              name: "如何高效工作？训练营视频",
              order: 3,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/2d8558812fe068009fb84e19555670e423e71e57_w_640?auth_key=1640664808-1d60cbddc8af48ee95910bd714164fda-0-64edcd0a926963544590a48ced6bc1b7",
              download_count: "117",
              view_count: "251",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1ASENxz5lfgKzP_3pXlP4BYhMV",
              payment: 2,
            },
            {
              id: "VMfad2YWfRui5zsC5Aq1WWPT00",
              name: "演讲必备！实用高端PPT",
              order: 1,
              res_cover:
                "https://img-xlppc-zhan.xunlei.com/f0912177d1e3511d85bd87f08fcc27c91fafd20b_w_640?auth_key=1640664844-a75f2772847642d3acdd47019d1aec19-0-04816a9e31480faddb9bdf4bc00626a4",
              download_count: "46",
              view_count: "145",
              tid: "",
              partner: "",
              width: "640",
              height: "360",
              share_id: "1AXPz1-nSFKRK6wCm5xUMe9gMV",
              payment: 2,
            },
          ],
        },
      ],
    };

    return api
      .fetchSelection(params)
      .then((res: IActivitySelectionResult) => {
        commit("SET_ACTIVITY_SELECTION", res.data);

        if (res.data.length === 0) {
          commit("SET_ACTIVITY_SELECTION", defaultData.data);
        }

        return res;
      })
      .catch((e) => {
        commit("SET_ACTIVITY_SELECTION", defaultData.data);
        return defaultData;
      });
  },
  fetchCollection({ commit, state }, params: IActivityCollectionParams) {
    const activity_id = params;
    const defaultData = {
      data: [
        {
          activity_id,
          name: "PPT精品大全",
          order: 1,
          img_url:
            "https://backstage-img-ssl.a.88cdn.com/f6091a1603e6fd04dbd18a57cbee3a1de56af34d",
          link_url: "https://zhan.xunlei.com/space/749193845",
        },
        {
          activity_id,
          name: "4K桌面壁纸",
          order: 2,
          img_url:
            " https://backstage-img-ssl.a.88cdn.com/2cfae3134d729c2d3c290aad1248af966af82fce",
          link_url: "https://zhan.xunlei.com/space/749193835",
        },
        {
          activity_id,
          name: "高清海报",
          order: 3,
          img_url:
            "https://backstage-img-ssl.a.88cdn.com/cd036b5c8cd188c47e1f6d8262e5f169dd68ba00",
          link_url: "https://zhan.xunlei.com/search/%E6%B5%B7%E6%8A%A5",
        },
        {
          activity_id,
          name: "1000张高质量简历",
          order: 4,
          img_url:
            "https://backstage-img-ssl.a.88cdn.com/f886088e32873fac9f5e7de5ec5a96d1e0ed679c",
          link_url: "https://zhan.xunlei.com/search/%E7%AE%80%E5%8E%86",
        },
      ],
    };
    return api
      .fetchCollection(params)
      .then((res: IActivityCollectionResult) => {
        commit("SET_ACTIVITY_COLLECTION", res.data);

        if (res.data.length === 0) {
          commit("SET_ACTIVITY_COLLECTION", defaultData.data);
        }
        return res;
      })
      .catch((e) => {
        commit("SET_ACTIVITY_COLLECTION", defaultData.data);
        return defaultData;
      });
  },
};
