














































import Vue from "vue";
import { joinUrlQueryString } from "@/utils/util";

export default Vue.extend({
  name: "daily-stat",
  filters: {
    formatTime(time: string) {
      const now = new Date(Number(time));
      const month = now.getMonth() + 1;
      const day = now.getDate();
      return `${month}月${day}日`;
    },
    formatCount(count: string) {
      const num = Number(count);
      if (num > 100000) {
        return "10W+";
      }
      return (num || 0).toLocaleString();
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    stats() {
      return this.$store.state.post.userDailyStat;
    },
    queryStr() {
      const queryObj = this.$route.query;
      const routeName = this.$route.name;
      const queryStr = joinUrlQueryString(queryObj, routeName);
      return queryStr;
    },
    isPcClient() {
      if (process.client) {
        return !!window.native;
      }
      return this.$store.state.isPCNative;
    },
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.stat("xiaozhan_day_report_pop_show", { showType: "hover" });
      }
    },
  },
  mounted() {
    this.timer = setTimeout(() => {
      if (this.hasLogin && !this.stats.id) {
        this.$store.dispatch("post/fetchUserDailyStat", { msg_type: 1 });
      }
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    handleCheck() {
      this.stat("xiaozhan_day_report_pop_click", {
        showType: "hover",
        clickid: "see",
      });

      window.open(`/message?${this.queryStr}`, "_blank", "noopener,noreferrer");
    },
    handleKnow() {
      this.stat("xiaozhan_day_report_pop_click", {
        showType: "hover",
        clickid: "known",
      });

      if (this.stats.id) {
        const id = this.stats.id;
        this.$store.dispatch("post/readDailyStat", { id, status: 1 });
      }
    },
  },
});
