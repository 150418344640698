

















import Vue from "vue";

export default Vue.extend({
  name: "lazy-img",
  props: {
    src: {
      type: String,
      required: true,
      default: "",
    },
    src2: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    // TODO: 小站CDN
    errorImg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgSrc: this.src,
    };
  },
  computed: {
    noscriptContent() {
      return `<img src="${this.imgSrc}" alt="${this.alt}" title="${this.title}">`;
    },
    srcSet() {
      if (this.imgSrc && this.src2) {
        return `${this.imgSrc} 1x, ${this.src2} 2x`;
      }

      if (this.imgSrc) {
        return this.imgSrc;
      }

      return "";
    },
  },
  watch: {
    src(newSrc) {
      this.imgSrc = newSrc;
    },
  },
  methods: {
    onError() {
      this.imgSrc = this.errorImg;
    },
  },
});
