import CryptoJS from "crypto-js";

export function getGCID(file: any) {
  return new Promise((resolve, reject) => {
    let setTimeoutId: any = 0;

    const reader = new window.FileReader(); // FileReader实例
    reader.addEventListener("load", (e: any) => {
      clearTimeout(setTimeoutId);
      resolve(calculateGCID(e.target.result)); // 文件的内容。该属性仅在读取操作完成后才有效，数据的格式取决于使用哪个方法来启动读取操作。
    });
    reader.readAsArrayBuffer(file); // result：ArrayBuffer数据对象

    setTimeoutId = setTimeout(() => {
      reject("加载图片失败");
    }, 2000);
  });
}

// 计算GCID（@詹鹏）
function calculateGCID(ab: any) {
  const size = ab.byteLength;
  const blockSize = calculateBlockSize(size);
  const blockNum = size / blockSize;

  const gcidSHA1 = CryptoJS.algo.SHA1.create();

  for (let i = 0; i < blockNum; i++) {
    const wa = CryptoJS.lib.WordArray.create(
      ab.slice(blockSize * i, blockSize * (i + 1))
    );
    const bcidSHA1 = CryptoJS.SHA1(wa);
    gcidSHA1.update(bcidSHA1);
  }
  if (blockSize * blockNum < size) {
    const wa = CryptoJS.lib.WordArray.create(
      ab.slice(blockSize * blockNum, size)
    );
    const bcidSHA1 = CryptoJS.SHA1(wa);
    gcidSHA1.update(bcidSHA1);
  }

  return gcidSHA1.finalize().toString();
}

function calculateBlockSize(size: number) {
  if (size >= 0 && size <= 128 << 20) {
    return 256 << 10;
  }
  if (size > 128 << 20 && size <= 256 << 20) {
    return 512 << 10;
  }
  if (size > 256 << 20 && size <= 512 << 20) {
    return 1024 << 10;
  }
  return 2048 << 10;
}
