











































import Vue from "vue";
import api from "@/api/user";
import { stat } from "@/utils/stat";

export default Vue.extend({
  name: "phone-auth",
  data() {
    return {
      visible: false,
      mobile: "",
      code: "",
      codeKey: "",
      warning: "",
      disabled: false,
      btnText: "获取验证码",
      duration: 60,
      timer: null,
      resolve: "",
      reject: "",
      promise: "",
      confirming: false,
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.stat("xiaozhan_phone_verify_show", {
          from: this.params.from || "",
        });
      }
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    stat(action: string, data: any) {
      stat("xiaozhan", action, data);
    },
    showDialog() {
      this.visible = true;
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });

      return this.promise;
    },
    cancel() {
      this.visible = false;
      this.reject("cancel");
      this.remove();
    },
    remove() {
      setTimeout(() => {
        this.destroy();
      }, 300);
    },
    destroy() {
      this.$destroy();
    },
    handleChange() {
      this.warning = "";
    },
    handleFocus() {
      this.warning = "";
    },
    handleCodeChange() {
      this.warning = "";
    },
    handleCodeFocus() {
      this.warning = "";
    },
    async getCode() {
      if (this.disabled) {
        return;
      }

      try {
        this.disabled = true;
        const validated = this.validateField(["mobile"]);
        if (validated) {
          const result = await api.sendAuthCode({
            phone: this.mobile.trim(),
            ...this.params,
          });

          if (result.key) {
            this.codeKey = result.key;

            this.timer = setInterval(() => {
              if (this.duration > 0) {
                this.duration = this.duration - 1;
                this.btnText = `${this.duration}s后重新发送`;
              } else {
                this.btnText = `获取验证码`;
                this.duration = 60;
                this.disabled = false;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          } else {
            this.disabled = false;
            this.$message({
              type: "warning",
              message: "验证码发送失败，请稍后重试",
            });
          }
        } else {
          this.disabled = false;
        }
      } catch (e) {
        this.disabled = false;
        this.$message({
          type: "warning",
          message: e.message || "验证码发送失败，请稍后重试",
        });
      }
    },
    validateField(fields: ["mobile", "code"]) {
      if (fields.includes("mobile")) {
        const mobileRegx = /^[1-9]\d{10}$/;
        if (!this.mobile.trim()) {
          this.warning = "手机号不能为空";
          return false;
        }

        if (!mobileRegx.test(this.mobile.trim())) {
          this.warning = "请输入正确的手机号";
          return false;
        }
      }

      if (fields.includes("code")) {
        if (!this.code.trim()) {
          this.warning = "请输入验证码";
          return false;
        }

        if (this.code.trim().length < 6) {
          this.warning = "验证码错误，请重新输入";
          return false;
        }

        if (!this.codeKey) {
          this.warning = "请先获取验证码";
          return false;
        }
      }

      return true;
    },
    async confirm() {
      if (this.confirming) {
        return;
      }

      const validated = this.validateField(["mobile", "code"]);
      if (validated) {
        try {
          this.confirming = true;
          const result = await api.bindAuthPhone({
            phone: this.mobile.trim(),
            code: this.code.trim(),
            key: this.codeKey,
            ...this.params,
          });

          if (result.result === "OK") {
            this.$message({
              type: "success",
              message: "绑定成功",
            });

            this.resolve("confirm");
            this.visible = false;
            this.remove();
            this.confirming = false;

            this.stat("xiaozhan_phone_verify_result", {
              from: this.params.from || "",
              result: "success",
            });
          } else {
            this.$message({
              type: "warning",
              message: result.data || "绑定失败，请稍后重试",
            });
            this.confirming = false;
            this.stat("xiaozhan_phone_verify_result", {
              from: this.params.from || "",
              result: "fail",
              errorcode: result.result,
            });
          }
        } catch (e) {
          this.$message({
            type: "warning",
            message: e.message || "绑定失败，请稍后重试",
          });
          this.confirming = false;
          this.stat("xiaozhan_phone_verify_result", {
            from: this.params.from || "",
            result: "fail",
            errorcode: e,
          });
        }
      }
    },
  },
});
