









import Vue from "vue";

export default Vue.extend({
  name: "vip-pay-result",
  data() {
    return {};
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    isVip() {
      return this.curUser.vipData && this.curUser.vipData.isVip;
    },
  },
  mounted() {},
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
  },
});
