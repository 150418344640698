
import { Plugin } from '@nuxt/types'

// declare module '@nuxt/types' {
//   interface Context {
//     $myInjectedFunction(message: string): void
//   }
// }

let store: any = {}

const storePlugin: Plugin = (context, inject) => {
  store = context.store
}

export default storePlugin

// export default function: Plugin (context, inject) {
//   store = context.store
// }

export function getStore () {
  return store
}
