













import Vue from "vue";
import VipPayDialog from "@/components/vip/PayDialog.vue";
import eventEmitter from "@/utils/eventEmitter";
import { getPlatformName, isMacThunder } from "@/utils/util";
import eventMap from "@/utils/event-name";

export default Vue.extend({
  components: {
    VipPayDialog,
  },
  data() {
    return {
      timer: null,
      vipPayVisible: false,
      skinChangeListenerId: null,
    };
  },
  computed: {
    showLoginIframe() {
      return this.$store.state.user.showLoginIframe;
    },
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
  },
  methods: {
    handleClose() {
      this.$store.commit("user/SET_LOGIN_IFRAME", 2);
    },
    calcTheme(skinId: string) {
      const DEFAULT_SKIN = "light";
      let theme = DEFAULT_SKIN;
      switch (skinId) {
        case "com.xunlei.plugin.skin.coolblack":
          theme = "dark";
          break;
        case "com.xunlei.plugin.skin.lightwhite":
          theme = "light";
          break;
        default:
          theme = DEFAULT_SKIN;
          break;
      }
      return theme;
    },
    async switchSkin() {
      try {
        if (isMacThunder()) {
          const { macApi } = require("@xunlei/universal-native-api");
          const infoRes = await macApi.call("getPlatformInfo", {});
          if (infoRes.skinId) {
            const theme = this.calcTheme(infoRes.skinId);
            if (theme === "light") {
              document.body.classList.remove("dark");
            } else {
              document.body.classList.add("dark");
            }
          }
        }

        if (window.native) {
          window.native.CallNativeFunction("GetSkinInfo", (...args) => {
            if (args && args[1]) {
              const info = JSON.parse(args[1]);
              if (info.colorID === 0) {
                document.body.classList.remove("dark");
              } else {
                document.body.classList.add("dark");
              }
            }
          });
        }
      } catch (e) {
        document.body.classList.remove("dark");
      }
    },
  },
  async mounted() {
    this.switchSkin();

    if (isMacThunder()) {
      this.skinChangeListenerId =
        window.WebViewJavascriptBridge.addEventListener(
          "skinChange",
          (event) => {
            this.switchSkin();
          }
        );
    }

    if (window.native) {
      window.native.AttachNativeEvent("OnChangeSkin", (...args) => {
        this.switchSkin();
      });
    }

    const platform = getPlatformName();
    this.$store.commit("vip/SET_PAY_PARAMS", {
      aidfrom: platform,
    });

    this.$store.commit("SET_PLATFORM_NAME", platform);

    eventEmitter.on(eventMap.SHOW_PAY_DIALOG, () => {
      this.vipPayVisible = true;
    });

    eventEmitter.on(eventMap.PAY_SUCCESS_KNOW, (result: any) => {
      this.vipPayVisible = false;
    });

    if (window.native) {
      window.native.AttachNativeEvent("OnUserLogout", (userid: string) => {
        console.log("OnUserLogout >>>", userid);
      });
    }

    // 获取活动
    const activities = await this.$store.dispatch("activity/fetchList", {});

    const needAuthPages = ["detail-feed", "publish-upload"];
    const routeName = this.$route.name;
    if (routeName && this.hasLogin && needAuthPages.includes(routeName)) {
      this.timer = setTimeout(async () => {
        const userid = this.curUser.userId;
        await this.$store.dispatch("user/checkAuthStatus", {
          "auth.userid": userid,
        });
      }, 800);
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.skinChangeListenerId) {
      window.WebViewJavascriptBridge.removeEventListener(
        "skinChange",
        this.skinChangeListenerId
      );
    }
  },
});
