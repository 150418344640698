import chunk from "lodash/chunk";
import Vue from "vue";
import { GetterTree, ActionTree, MutationTree } from "vuex";
import api from "@/api/drive";
import { updateListByPropId } from "@/utils/util";
import { RootState } from "@/store";

export const state = () => ({
  globalDisabled: false, // 美国ip或者url参数 switch = 1 禁用云添加/云笔记/侧边栏部分功能
  all: {} as any,
  home: {
    list: [] as any[],
  },
  uploadList: [] as any[],
  panFileSelected: [] as any[],
  tasks: {
    list: [] as any[],
    pageToken: "",
    restCount: 0,
    expiresIn: 0,
  },
  allTaskStatus: {} as any,
  spaceMsg: {
    kind: "",
    limit: "2199023255552",
    usage: 0,
  },
  pageToken: "",
  hasMore: false,
  parentId: "",
  parentPaths: [
    {
      id: "0",
      title: "我的云盘",
    },
  ], // 目录路径
  parentIndex: 0,
  folders: {
    "0": {
      id: "",
      name: "我的云盘",
    },
  } as any, // 目录树
  folderNodes: [] as any[],
  events: {
    list: [],
    pageToken: "",
  },
  uploadRetry: false,
  filterTypes: {
    all: { name: "全部文件", filter: { trashed: { eq: false } } },
    done: {
      name: "已完成",
      filter: { phase: { eq: "PHASE_TYPE_COMPLETE" }, trashed: { eq: false } },
    },
    starred: { name: "加星", filter: { starred: { eq: true } } },
    file: {
      name: "文件",
      filter: { kind: { eq: "drive#file" }, trashed: { eq: false } },
    },
    category: {
      name: "目录",
      filter: { kind: { eq: "drive#folder" }, trashed: { eq: false } },
    },
    picture: {
      name: "图片",
      filter: { mime_type: { prefix: "image/" }, trashed: { eq: false } },
    },
    // {name: '文档', filter: {}},
    video: {
      name: "视频",
      filter: { mime_type: { prefix: "video/" }, trashed: { eq: false } },
    },
    trash: { name: "回收站", filter: { trashed: { eq: true } } },
  } as any,
  curFilter: "done",
  userPrivilege: "",
  selFileIds: [] as any[], // 复制，移动时多选项的id
  selFileIdsIsAll: false, // 是否全选
});

export type DriveModuleState = ReturnType<typeof state>;

export const mutations: MutationTree<DriveModuleState> = {
  setGlobalDisabled(state, globalDisabled) {
    // state.globalDisabled = false
    state.globalDisabled = globalDisabled;
  },
  set(state, file) {
    Vue.set(state.all, file.id, Object.assign({}, state.all[file.id], file));
  },
  setFiles(state, { list, refresh = false }) {
    if (refresh) {
      state.home.list = list;
    } else {
      state.home.list = [...state.home.list, ...list];
    }
  },
  add(state, file) {
    state.home.list.unshift(file);
  },
  deleteFile(state, id) {
    // delete state.all[id]
    Vue.delete(state.all, id);
    state.home.list.forEach((item, index) => {
      if (item === id) {
        state.home.list.splice(index, 1);
      }
    });
  },
  deleteUploadFile(state, id) {
    state.uploadList.forEach((item, index) => {
      if (item.id === id) {
        state.uploadList.splice(index, 1);
      }
    });
  },
  setUploadTask(state, files) {
    if (Array.isArray(files)) {
      state.uploadList = files;
    } else {
      // 单文件部分更新
      const ids = state.uploadList.map((v) => v.id);
      const index = ids.indexOf(files.id);
      if (index === -1) return;
      const oldFile = state.uploadList.filter((v) => v.id === files.id)[0];
      const newFile = oldFile ? Object.assign({}, oldFile, files) : files;
      state.uploadList.splice(index, 1, newFile);
    }
  },
  updateUploadTaskID(state, { oldID, newID }) {
    const ids = state.uploadList.map((v) => v.id);
    const index = ids.indexOf(oldID);
    Vue.set(
      state.uploadList,
      index,
      Object.assign({}, state.uploadList[index], { id: newID })
    );
  },
  delUploadTask(state, file) {
    state.uploadList = state.uploadList.filter((v) => v.name !== file.name);
  },
  setPanFileSelected(state, files) {
    // TODO: id去重
    const selected = [...state.panFileSelected, ...files];
    state.panFileSelected = Array.from(new Set(selected));
  },
  delPanFileSelected(state, file) {
    state.panFileSelected = state.panFileSelected.filter(
      (v) => v.id !== file.id
    );
  },
  clearPanFileSelected(state, files) {
    state.panFileSelected = [];
  },
  setTasks(state, { refresh, list, pageToken, restCount, expiresIn }) {
    state.tasks = {
      list: refresh ? list : [...state.tasks.list, ...list],
      pageToken,
      restCount,
      expiresIn,
    };
  },
  setTaskStatus(state, { id, statuses }) {
    const list = state.tasks.list.map((v: any) => {
      if (v.id === id) {
        return {
          ...v,
          statuses,
        };
      } else return v;
    });
    state.tasks.list = list;
  },
  changeTaskInfo(state, { id, info }) {
    const list = state.tasks.list.map((v) => {
      if (v.id === id) {
        return {
          ...v,
          ...info,
        };
      } else return v;
    });
    state.tasks.list = list;
  },
  setSpageMsg(state, res) {
    state.spaceMsg = res;
  },
  delTasks(state, ids) {
    state.tasks.list = state.tasks.list.filter((v) => !ids.includes(v.id));
  },
  setDiskInfo(state, res) {
    state.pageToken = res.pageToken;
    state.hasMore = res.hasMore;
    state.parentId = res.parentId;
  },
  setFolders(state, folder) {
    Vue.set(state.folders, folder.id, folder);
  },
  setFolderNodes(state, { parentId, list }) {
    const oldList = [...state.folderNodes];
    if (parentId === "0") {
      state.folderNodes = [
        {
          id: "0",
          name: "我的云盘",
        },
        ...list,
      ];
    } else {
      const newList = updateListByPropId(parentId, oldList, list);
      state.folderNodes = newList;
    }
  },
  setFolder(state, folder) {
    state.parentPaths = [...state.parentPaths, folder];
  },
  setFolderAll(state, folders) {
    state.parentPaths = folders;
  },
  backFolder(state, index) {
    state.parentPaths = state.parentPaths.slice(0, index);
  },
  setAllTaskStatus(state, status) {
    state.allTaskStatus[status.id] = status;
  },
  setEvents(state, { refresh, list, pageToken }) {
    state.events = {
      list: refresh ? list : [...state.events.list, ...list],
      pageToken,
    };
  },
  setUploadRetry(state, bol) {
    state.uploadRetry = bol;
  },
  setFilter(state, key) {
    state.curFilter = key;
  },
  setUserPrivilege(state, msg) {
    state.userPrivilege = msg;
  },
  setSelFiles(state, ids) {
    if (Array.isArray(ids)) {
      state.selFileIds = ids;
    } else {
      const index = state.selFileIds.indexOf(ids);
      if (index > -1) {
        state.selFileIds = state.selFileIds.filter((v) => v !== ids);
      } else {
        state.selFileIds = [...state.selFileIds, ids];
      }
    }
  },
  setSelFilesIsAll(state, bol) {
    state.selFileIdsIsAll = bol;
  },
};

export const actions: ActionTree<DriveModuleState, RootState> = {
  nuxtServerInit({ commit }, { req }) {
    console.log(req.auth);
  },
  getFiles({ commit, state }, params = {}) {
    const filter = state.filterTypes[state.curFilter].filter;
    params.filters = filter;
    params.with_audit = true;
    if (!params.limit) {
      params.limit = 100;
    }
    return api.findFiles(params).then((res: any) => {
      if (!res.error) {
        commit("setDiskInfo", {
          pageToken: res.next_page_token,
          hasMore: !!res.next_page_token,
          parentId: state.curFilter === "trash" ? "" : params.parent_id, // 不去更新*符号
        });
        let list = res.files
          .filter((item: any) => item.kind)
          .map((item: any) => {
            commit("set", { ...item, picked: false });
            return item.id;
          });
        commit("setFiles", {
          list,
          refresh: !params.page_token,
        });

        return "success";
      } else {
        // alert('inter folder success')
      }
    });
  },
  getFileInfo({ commit }, id) {
    return api.getFileInfo(id).then((res: any) => {
      commit("set", res);
      return "success";
    });
  },
  deleteFile({ commit }, id) {
    return api.deleteFile(id).then((res: any) => {
      if (Object.keys(res).length === 0) {
        commit("deleteFile", id);
        return "success";
      } else {
        return res;
      }
      // if (Object.keys(res).length === 0) {
      //   alert('delete success')
      // }
    });
  },
  batchDeleteFile({ commit }, ids) {
    const allPromise: any[] = [];
    for (let id of ids) {
      allPromise.push(api.deleteFile(id));
    }

    return Promise.all(allPromise).then((res: any) => {
      for (let id of ids) {
        commit("deleteFile", id);
      }
      return "success";
    });
  },
  updateFile({ commit }, { id, params }) {
    return api.updateFile({ id, params }).then((res: any) => {
      // if (!res.error) {
      //   alert('update success')
      // }
      commit("set", res);
      return "success";
    });
  },
  trashFile({ commit }, id) {
    return api.trashFile(id).then((res: any) => {
      commit("deleteFile", id);
      return res;
    });
  },
  batchTrashFile({ commit }, ids) {
    const allPromise: any[] = [];
    for (let id of ids) {
      allPromise.push(api.trashFile(id));
    }

    return Promise.all(allPromise).then((res: any) => {
      for (let id of ids) {
        commit("deleteFile", id);
      }
      return res;
    });
  },
  untrashFile({ commit }, id) {
    return api.untrashFile(id).then((res: any) => {
      commit("deleteFile", id);
      return res;
    });
  },
  batchUntrashFile({ commit }, ids) {
    const allPromise: any[] = [];
    for (let id of ids) {
      allPromise.push(api.untrashFile(id));
    }

    return Promise.all(allPromise).then((res: any) => {
      for (let id of ids) {
        commit("deleteFile", id);
      }
      return res;
    });
  },
  clearTrash({ commit }) {
    return api.clearTrash().then((res: any) => {
      console.log(res);
      commit("setFiles", {
        list: [],
        refresh: true,
      });
      return res;
    });
  },
  copyFile({ commit }, { id, parent_id, name }) {
    return api.copyFile({ id, parent_id, name }).then((res: any) => {
      if (Object.keys(res).length === 0) {
        console.log("copy success");
      }
    });
  },
  batchCopyFile({ commit }, files) {
    const allPromise: any[] = [];
    for (let file of files) {
      const { id, parentId, name } = file;
      allPromise.push(api.copyFile({ id, parent_id: parentId, name }));
    }

    return Promise.all(allPromise).then((res: any) => {
      return res;
    });
  },
  moveFile({ commit, state }, { id, parent_id, name }) {
    return api.moveFile({ id, parent_id, name }).then(() => {
      const list = state.home.list.filter((v) => v !== id);
      commit("setFiles", {
        list,
        refresh: true,
      });
    });
  },
  batchMoveFile({ commit, state }, files) {
    const allPromise: any[] = [];
    for (let file of files) {
      const { id, parentId, name } = file;
      allPromise.push(api.moveFile({ id, parent_id: parentId, name }));
    }
    return Promise.all(allPromise).then((res: any) => {
      const ids: any[] = [];
      for (let file of files) {
        ids.push(file.id);
      }
      const list = state.home.list.filter((v: any) => !ids.includes(v));
      commit("setFiles", {
        list,
        refresh: true,
      });
      return res;
    });
  },
  getTasks(
    { commit, state },
    params = { refresh: true, limit: 100, phaseCheck: false }
  ) {
    const refresh = params.refresh;
    const phaseCheck = params.phaseCheck || false;
    const newParams = Object.assign({}, params, {
      page_token: refresh ? "" : state.tasks.pageToken,
    });
    delete newParams.refresh;
    return api.getTasks(newParams).then((res: any) => {
      const list = res.tasks.map((task: any) => {
        if (phaseCheck) {
          commit("changeTaskInfo", { id: task.id, info: task });
        }

        if (task.statuses) {
          task.statuses.forEach((v: any) => commit("setAllTaskStatus", v));
        }
        return task;
      });
      const restList = list.filter((v: any) =>
        ["PHASE_TYPE_PENDING", "PHASE_TYPE_RUNNING"].includes(v.phase)
      );

      if (phaseCheck) {
        return restList;
      }
      commit("setTasks", {
        list,
        refresh,
        pageToken: res.next_page_token || "",
        restCount: restList.length,
        expiresIn: res.expires_in,
      });
      // 返回未完成的任务
      return restList;
    });
  },
  delTasks({ commit }, ids) {
    return api.delTasks(ids).then((res: any) => {
      if (res) {
        commit("delTasks", ids);
      }
    });
  },
  getTask({ commit }, id) {
    return api.getTask(id).then((res: any) => {
      if (res.statuses && res.statuses.length) {
        commit("setTaskStatus", {
          id,
          statuses: res.statuses.map((v: any) => v.id),
        });
        res.statuses.forEach((v: any) => commit("setAllTaskStatus", v));
      }
    });
  },
  getTaskStatus({ commit }, { ids }) {
    const promiseIds = chunk(ids, 20).map((_ids) => {
      return api.getTaskStatus({ status_ids: _ids }).then((res: any) => {
        if (res.statuses) {
          res.statuses.forEach((v: any) => {
            if (v) commit("setAllTaskStatus", v);
          });
          return _ids;
        }
      });
    });
    return Promise.all([promiseIds]).then((vals) => vals.join());
  },
  getEvent({ commit }, params = {}) {
    return api.getEvent(params).then((res: any) => {
      if (res) {
        commit("setEvents", {
          list: res.events,
          refresh: !params.page_token,
          pageToken: res.next_page_token,
        });
      }
    });
  },
  clearEvent({ commit }) {
    return api.clearEvent().then((res: any) => {
      console.log("clearEvent", res);
      commit("setEvents", {
        list: [],
        refresh: true,
        pageToken: "",
      });
    });
  },
  delEvent({ commit, state }, ids) {
    return api.delEvent(ids).then((res: any) => {
      commit("setEvents", {
        ...state.events,
        list: state.events.list.filter((v: any) => ids.indexOf(v.id) < 0),
        refresh: true,
      });
    });
  },
  patchEvent({ commit }, { id, params }) {
    return api.patchEvent(id, params).then((res: any) => {
      console.log("patchEvent", res);
    });
  },
  getAbout({ commit }, params) {
    return api.getAbout(params).then((res: any) => {
      commit("setSpageMsg", {
        kind: res.quota.kind,
        usage: Number(res.quota.usage || 0),
        limit: Number(res.quota.limit),
      });
    });
  },
  addFolder({ commit, state }, parentId) {
    return api
      .createFile({
        parent_id: state.parentId,
        name: parentId,
        kind: "drive#folder",
      })
      .then((res: any) => {
        commit("set", res.file);
        const list = [res.file.id];
        commit("setFiles", {
          list,
        });
      });
  },
  addNewFolder({ commit, state }, parentId) {
    return api
      .createFile({
        parent_id: state.parentId,
        name: parentId,
        kind: "drive#folder",
      })
      .then((res: any) => {
        commit("set", res.file);
        return res;
      });
  },
  addFileTreeFolder({ commit, state }, { parentId, name }) {
    return api
      .createFile({
        parent_id: parentId,
        name: name,
        kind: "drive#folder",
      })
      .then((res) => {
        // commit('set', res.file)
        return res;
      });
  },
  getFolders({ commit, state }, params) {
    return api.findFiles(params).then((res: any) => {
      if (!res.error) {
        const parentId = params.parent_id || "0";
        if (parentId !== "0") {
          commit("setFolders", {
            ...state.folders[parentId],
            isClick: true,
          });
        }
        if (res.files.length === 0) {
          return;
        }
        const list = res.files.map((folder: any) => {
          commit("setFolders", folder);
          return {
            id: folder.id,
            name: folder.name,
            thumbnail_link: folder.thumbnail_link,
            icon_link: folder.icon_link,
          };
        });
        commit("setFolderNodes", { parentId, list });
        return res;
      } else {
        // alert('inter folder success')
      }
    });
  },
  loadFolders({ commit, state }, params) {
    return api.findFiles(params).then((res: any) => {
      if (!res.error) {
        const parentId = params.parent_id || "0";
        if (res.files.length === 0) {
          return;
        }
        const list = res.files.map((folder: any) => {
          // commit('setFolders', folder)
          return folder;
          return {
            id: folder.id,
            name: folder.name,
            thumbnail_link: folder.thumbnail_link,
            icon_link: folder.icon_link,
          };
        });
        return list;
      } else {
        // alert('inter folder success')
      }
    });
  },
  checkPrivilege({ commit }, params) {
    console.log("params, option :>> ", params);
    return api
      .checkPrivilege("CLOUD_NOTE", params)
      .then((privilegeResp: any) => {
        if (privilegeResp && privilegeResp.result === "ACCEPTED") {
          commit("setGlobalDisabled", false);
        } else {
          commit("setGlobalDisabled", true);
        }
        return privilegeResp;
      })
      .catch((err) => {
        console.log("checkPrivilege err :>> ", err);
        commit("setGlobalDisabled", true);
        return err;
      });
  },
  applyPrivilege({ commit }, params) {
    return api.applyPrivilege(params);
  },
  delUploadTask({ commit, state }, params) {
    const task: any = state.uploadList.filter(
      (v: any) => v.name === params.name
    )[0];
    if (task && task.id) {
      const hasFile: any = state.uploadList.filter((v) => v.id === task.id)[0];
      if (hasFile && hasFile.status === 2) {
        // 已上传完成的文件删除，移入回收站
        return api.trashFile(task.id).then(() => {
          commit("deleteFile", task.id);
          commit("delUploadTask", params);
          return task.id;
        });
      } else {
        commit("delUploadTask", params);
        return task.id;
      }
    } else return null;
  },
  setUploadTaskStatus({ state }, { id }) {
    const params = { phase: "PHASE_TYPE_RUNNING" };
    return api
      .updateFile({ id, params })
      .then((res) => res)
      .catch((err) => err);
  },
};
