import { GetterTree, ActionTree, MutationTree } from "vuex";
import aliyun from "@/api/aliyun";

export const state = () => ({
  name: "zhan.xunlei.com",
  isPCNative: false, // PC/Mac客户端
  platform: "pc_browser",
  errorType: "",
  isMobile: false,
});

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  name: (state) => state.name,
};

export const mutations: MutationTree<RootState> = {
  CHANGE_NAME: (state, newName: string) => (state.name = newName),
  SET_IS_NATIVE(state, isNative) {
    state.isPCNative = isNative;
  },
  SET_PLATFORM_NAME(state, platform) {
    state.platform = platform;
  },
  SET_ERROR_TYPE(state, type) {
    state.errorType = type;
  },
  SET_IS_MOBILE(state, isMobile) {
    state.isMobile = isMobile;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  nuxtServerInit({ commit }, { req }) {
    console.log(req.auth);
  },
  aliUpload(
    context,
    { file, progressCallback = () => {}, serviceType = "xiaozhan" }
  ) {
    return aliyun.uploadPicture(file, progressCallback, serviceType); // 返回 '图片url'
  },
  // 阿里云 转存网络图片（接受字符串，返回字符串）
  transferPicsAliyun(context, { url, serviceType = "xiaozhan" }) {
    return aliyun.transferPics(url, serviceType).then((res) => res.data.url); // 接受 'url'，返回 'url'
  },
};
