import Vue from "vue";
import { Plugin } from "@nuxt/types";
import VueRavenPlugin from "@xunlei/vue-raven-plugin";

Vue.use(VueRavenPlugin, {
  dsn: "https://e081a747f1724599b58ffe4ed129033b@sentry.xunlei.com/30",
  isProduction: process.env.NODE_ENV === "production",
  appName: "迅雷小站",
  version: process.env.APP_VERSION,
  ignoreErrors: [/not login/, /not in xl9/, /没有数据/],
});

const ravenPlugin: Plugin = (context, inject) => {
  inject("raven", (VueRavenPlugin as any).raven);
};

export default ravenPlugin;

export const raven = (VueRavenPlugin as any).raven;
