// node环境中运行，commonjs

const axios = require("axios");
const md5 = require("blueimp-md5");
const pkg = require("../package");
const xbaseConfig = require("../xbase-sdk.config").default;
const { riskApiOrigin } = require("../xbase-sdk.config");

console.log("📝 riskApiOrigin >>>", riskApiOrigin);
const { clientId, packageName, signKey, algVersion } = xbaseConfig;
const getPubKeyTimeStamp = parseInt(Date.now() / 1000);

// 获取最初pub_key
async function getPubKey() {
  const sign = md5(
    `${clientId}${pkg.version}${packageName}${getPubKeyTimeStamp}${algVersion}${signKey}`
  );
  return axios
    .get(
      `${riskApiOrigin}/amaze/v1.0/algorithms?client_id=${clientId}&client_version=${pkg.version}&package_name=${packageName}&timestamp=${getPubKeyTimeStamp}&alg_version=${algVersion}&sign=${sign}`
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));
}

export default getPubKey;
