import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _79cf6f24 = () => interopDefault(import('../pages/blank/index.vue' /* webpackChunkName: "pages/blank/index" */))
const _02157e52 = () => interopDefault(import('../pages/message/index.vue' /* webpackChunkName: "pages/message/index" */))
const _f1e50d8e = () => interopDefault(import('../pages/blank/third.vue' /* webpackChunkName: "pages/blank/third" */))
const _44ab97e8 = () => interopDefault(import('../pages/term/privacy.vue' /* webpackChunkName: "pages/term/privacy" */))
const _87c99c74 = () => interopDefault(import('../pages/vip/pay/index.vue' /* webpackChunkName: "pages/vip/pay/index" */))
const _7b4d0059 = () => interopDefault(import('../pages/vip/pay/result.vue' /* webpackChunkName: "pages/vip/pay/result" */))
const _2cf86186 = () => interopDefault(import('../pages/activity/_id.vue' /* webpackChunkName: "pages/activity/_id" */))
const _6c96c57c = () => interopDefault(import('../pages/detail/_feed.vue' /* webpackChunkName: "pages/detail/_feed" */))
const _d6e69426 = () => interopDefault(import('../pages/publish/_upload.vue' /* webpackChunkName: "pages/publish/_upload" */))
const _98440704 = () => interopDefault(import('../pages/search/_keyword.vue' /* webpackChunkName: "pages/search/_keyword" */))
const _4c7f282e = () => interopDefault(import('../pages/space/_user.vue' /* webpackChunkName: "pages/space/_user" */))
const _5fbf405a = () => interopDefault(import('../pages/zone/_id.vue' /* webpackChunkName: "pages/zone/_id" */))
const _27b1dddf = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'site-exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/blank",
    component: _79cf6f24,
    name: "blank"
  }, {
    path: "/message",
    component: _02157e52,
    name: "message"
  }, {
    path: "/blank/third",
    component: _f1e50d8e,
    name: "blank-third"
  }, {
    path: "/term/privacy",
    component: _44ab97e8,
    name: "term-privacy"
  }, {
    path: "/vip/pay",
    component: _87c99c74,
    name: "vip-pay"
  }, {
    path: "/vip/pay/result",
    component: _7b4d0059,
    name: "vip-pay-result"
  }, {
    path: "/activity/:id?",
    component: _2cf86186,
    name: "activity-id"
  }, {
    path: "/detail/:feed?",
    component: _6c96c57c,
    name: "detail-feed"
  }, {
    path: "/publish/:upload?",
    component: _d6e69426,
    name: "publish-upload"
  }, {
    path: "/search/:keyword?",
    component: _98440704,
    name: "search-keyword"
  }, {
    path: "/space/:user?",
    component: _4c7f282e,
    name: "space-user"
  }, {
    path: "/zone/:id?",
    component: _5fbf405a,
    name: "zone-id"
  }, {
    path: "/",
    component: _27b1dddf,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
