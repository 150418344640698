import { GetterTree, ActionTree, MutationTree } from 'vuex'
import Vue from 'vue'
import api from '@/api/share'
import { shareStatusCode } from '@/utils/code-res'
import { addExtData } from '@/utils/stat'
import { RootState } from '@/store'


export const state = () => ({
  files: {} as any, // 分享的文件资源
  list: [] as any[], // 当前展示的文件id列表
  pageToken: '',
  folders: [{
    id: '0',
    title: '全部文件'
  }],
  user: {
    user_id: '',
    portrait_url: '',
    nickname: ''
  }, // 分享者信息
  shareInfo: {
    shareStatus: -1,
    fileNum: '0',
    expirationLeft: '0',
    restoreCountLeft: '0',
    passCodeToken: ''
  },
  wechatConfig: {},
  saveFolderId: '',
  isOpenSaveFolderId: false,
  isRestoreKeep: false,
  sharedList: [],
  getSharedListLoading: false
})

export type ShareModuleState = ReturnType<typeof state>

export const mutations: MutationTree<ShareModuleState> = {
  set (state, file) {
    Vue.set(state.files, file.id, Object.assign({}, state.files[file.id], file))
  },
  setFiles (state, { list, refresh = false }) {
    if (refresh) {
      state.list = list
    } else {
      state.list = [...state.list, ...list]
    }
  },
  setPageToken (state, token) {
    state.pageToken = token
  },
  setPassCodeToken (state, token) {
    state.shareInfo.passCodeToken = token
  },
  setUser (state, user) {
    state.user = user
  },
  setShareInfo (state, info) {
    state.shareInfo = info
  },
  setShareStatus (state, status) {
    state.shareInfo.shareStatus = status
  },
  setFolder (state, folder) {
    state.folders = [...state.folders, folder]
  },
  setFolders (state, folder) {
    state.folders = folder
  },
  backFolder (state, index) {
    state.folders = state.folders.slice(0, index)
  },
  setWechatConfig (state, config) {
    state.wechatConfig = Object.freeze(config)
  },
  setSaveFolderId (state, id) {
    state.saveFolderId = id
  },
  setIsOpenSaveFolderId (state, bol) {
    state.isOpenSaveFolderId = bol
  },
  setSharedList (state, newSharedList) {
    state.sharedList = newSharedList
  },
  setSharedListStatus (state, newSharedListLoading) {
    console.log('state, newSharedListLoading :>> ', state, newSharedListLoading);
    state.getSharedListLoading = newSharedListLoading
  },
}

export const actions: ActionTree<ShareModuleState, RootState> = {
  nuxtServerInit ({ commit }, { req }) {
    console.log(req.auth)
  },
  getShare ({ commit, state }, { params, withCaptcha = true, withCredentials = false, show = true }) {
    return api.getShare(params, withCaptcha, withCredentials)
      .then((res: any) => {
        if (res.user_info) {
          addExtData({
            share_user_id: res.user_info.user_id
          })
          commit('setUser', res.user_info)
        }

        commit('setPageToken', res.next_page_token)

        if (res.files && show) {
          const list = res.files.filter((v: any) => v.phase === 'PHASE_TYPE_COMPLETE').map((file: any) => {
            commit('set', file)
            return file.id
          })
          commit('setFiles', { list, refresh: !params.page_token })
        }

        commit('setShareInfo', {
          shareStatus: shareStatusCode[res.share_status.toUpperCase()],
          fileNum: res.file_num,
          expirationLeft: res.expiration_left,
          restoreCountLeft: res.restore_count_left,
          passCodeToken: res.pass_code_token || state.shareInfo.passCodeToken
        })

        localStorage.setItem('xlpan_pass_token', res.pass_code_token || state.shareInfo.passCodeToken)
        if(params.pass_code){
          localStorage.setItem('xlpan_pass_code',  params.pass_code)
        }
        
        return res
      })
  },
  getDetail ({ commit, state }, { params, withCredentials = false }) {
    return api.getDetail(params, withCredentials)
      .then((res: any) => {
        commit('setPageToken', res.next_page_token)
        if (res.files) {
          const list = res.files.filter((v: any) => v.phase === 'PHASE_TYPE_COMPLETE').map((file: any) => {
            commit('set', file)
            return file.id
          })
          commit('setFiles', { list, refresh: !params.page_token })
        }
        if (res.share_status) {
          commit('setShareInfo', {
            ...state.shareInfo,
            shareStatus: shareStatusCode[res.share_status.toUpperCase()]
          })
        }
        return res
      })
  },
  postRestore ({ commit, state }, params) {
    return api.postRestore(params)
      .then((res: any) => {
        if (res.share_status !== 'OK') {
          commit('setShareInfo', {
            ...state.shareInfo,
            shareStatus: shareStatusCode[res.share_status.toUpperCase()]
          })
        }
        
        if (res.file_id && state.shareInfo.restoreCountLeft !== '-1') {
          commit('setShareInfo', {
            ...state.shareInfo,
            restoreCountLeft: Number(state.shareInfo.restoreCountLeft) - 1
          })
        }
        return res
      })
  },
  getWechatConfig ({ commit }, { url }) {
    return api.getWechatConfig({ url })
      .then((res) => {
        if (res.data.code === 200) {
          commit('setWechatConfig', res.data.data)
          return 'success'
        }
      })
  },
  getFileInfo ({}, params) {
    return api.getFileInfo(params)
  },
  checkRestore ({}, params) {
    return api.checkRestore(params)
  },
  checkRestoreStatus ({}, id) {
    return api.checkRestoreStatus(id)
  },
  postShare ({}, params) {
    return api.postShare(params)
  },
  // 我的分享列表
  getSharedList({ commit }, params){
    commit('setSharedListStatus', true)
    return api.getSharedList(params, false, true)
  },
  // 删除分享链接
  deleteShare ({}, params) {
    return api.deleteShare(params)
  },
}
