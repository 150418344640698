import {
  isAndroidNative as isAndroid,
  isIOSNative as isIOS,
  sendMessage,
} from "@xunlei/universal-native-api";
import xbaseConfig from "@/xbase-sdk.config";
import qs from "qs";
import { IObjectKeys } from "~/types/common";

interface IiOSRequestParams {
  method: string;
  host: string;
  path: string;
  params?: IObjectKeys;
  jsonRequest?: any;
  headers?: IObjectKeys;
}

export async function request(
  url: string,
  params: IObjectKeys,
  options: IObjectKeys
) {
  console.log("fetch native", url, params, options);
  const method = options.method || "GET";

  // IOS 使用ios的httprequest
  if (isIOS) {
    // 按照fetch规范传递请求参数是url 但是ios接口需要区分host path
    const parseURL = new URL(url);
    const iosRequestParams: IiOSRequestParams = {
      method,
      host: parseURL.origin,
      path: parseURL.pathname,
    };

    if (params && Object.keys(params).length > 0) {
      if (params.filters) {
        params.filters = JSON.stringify(params.filters);
      }
      iosRequestParams.params = params;
    }

    if (method === "POST") {
      // json 参数请求必须传 1
      iosRequestParams.jsonRequest = 1;
    }

    if (options.headers) {
      iosRequestParams.headers = options.headers;
    }

    // 使用iosrequest 直接在headers添加登录access_token 和 captcha_token
    return sendMessage({
      platform: "ios",
      name: "xlAjax",
      params: {
        ...iosRequestParams,
      },
    }).then((res) => {
      const [err, result] = res;
      if (err === null && result && result.result === 0) {
        return Promise.resolve(JSON.parse(result.data));
      } else {
        return Promise.reject({
          error: "unknown",
        });
      }
    });
  }

  if (isAndroid) {
    if (method && ["GET", "DELETE"].indexOf(method.toUpperCase()) > -1) {
      if (params && Object.keys(params).length > 0) {
        if (params.filters) {
          params.filters = JSON.stringify(params.filters);
        }
        const str = qs.stringify(params);
        url = url + (str ? "?" + str : "");
      }
    }
    let headers = { ...(options.headers || {}) };

    if (options.isAuth === true) {
      const loginRelatedHeaders = {
        Authorization: "Bearer " + "authorize",
        "x-device-id": "deviceId",
        "x-client-id": xbaseConfig.clientId,
      };
      headers = Object.assign(headers, loginRelatedHeaders);
    }

    return sendMessage({
      platform: "android",
      name: "xlAjax",
      params: {
        url,
        method,
        header: headers,
        body: method === "GET" ? undefined : params,
      },
    })
      .then((res) => {
        if (res) {
          if (res.ret === 0) {
            return Promise.resolve(JSON.parse(res.body));
          }
        } else {
          return Promise.reject({
            error: "unknown",
          });
        }
      })
      .catch((err) => console.log("错误信息", err));
  }
}
