import * as storePlugin from "store";
import sso from "@/utils/sso";
import { fetchSlRes, fetchAccountData } from "@/api/fetch-res";
import xbaseSDKConfig from "../xbase-sdk.config";
import { IAuthCodeParams, IAuthBindPhoneParams } from "@/types/user";

const store = storePlugin as any;

export const getSsoUser = async () => {
  return (await sso).auth
    .getUserProfile()
    .then((res: any) => res)
    .catch((res: any) => {
      console.log("getSsoUser error >>>", res);
      return Promise.reject(res);
    });
};

export const getSsoStore = () => {
  return store.get("credentials_" + xbaseSDKConfig.clientId);
};

export const getUserInfo = (uid: string) => {
  return fetchSlRes("/user_info/pc_info", { uid });
};

export const getUserAllInfo = () => {
  const store = getSsoStore();
  if (store && store.user_id) {
    return getUserInfo(store.user_id);
  } else {
    return store;
  }
};

const getAccessToken = async () => {
  return (await sso).oAuth2Client
    .getAccessToken()
    .then((res: any) => res)
    .catch((res: any) => {
      console.log("getAccessToken error >>>", res);
      return "";
    });
};

const checkAuthStatus = (params: any) => {
  return fetchAccountData("/certification/v1.4/isauth", params, {});
};

const sendAuthCode = (params: IAuthCodeParams) => {
  return fetchAccountData("/certification/v1.4/sendcode", params, {
    method: "post",
  });
};

const bindAuthPhone = (params: IAuthBindPhoneParams) => {
  return fetchAccountData("/certification/v1.4/setphone", params, {
    method: "post",
  });
};

export const getIOSUserInfo = (id: string, options: any) => {
  const { sendMessage, isIOSNative } = require("@xunlei/universal-native-api");

  // iOS - webview 环境 使用ios的httprequest
  if (isIOSNative) {
    // 使用iosrequest 直接在headers添加登录access_token 和 captcha_token
    const url = xbaseSDKConfig.apiOrigins[0] + "/v1/getuserinfo";
    const parseURL = new URL(url);
    let iosRequestParams = {
      method: "POST",
      host: parseURL.origin,
      path: parseURL.pathname,
      params: {
        account: String(id),
        account_type: "userid",
        scene: "web-pan",
      },
      jsonRequest: 1,
    };

    console.log("查询用户信息接口 >>>", iosRequestParams);

    return sendMessage({
      platform: "ios",
      name: "xlAjax",
      params: {
        ...iosRequestParams,
      },
    }).then((res) => {
      const [err, result] = res;
      console.log("err, result :>> ", err, result);
      if (err === null && result && result.result === 0) {
        return Promise.resolve(JSON.parse(result.data));
      } else {
        if (err && err.message) {
          return Promise.reject({
            error: err.message,
          });
        }
        return Promise.reject({
          error: "unknown",
        });
      }
    });
  } else {
    const url = xbaseSDKConfig.apiOrigins[0] + "/v1/getuserinfo";
    const payload = {
      method: "POST",
      ...options,
      body: JSON.stringify({
        account: String(id),
        account_type: "userid",
        scene: "web-pan",
      }),
    };

    return fetch(url, payload).then((res) => {
      return res.json();
    });
  }
};

export default {
  getAccessToken,
  checkAuthStatus,
  sendAuthCode,
  bindAuthPhone,
};
