import * as storePlugin from 'store'
import storeExpire from 'store/plugins/expire'
import OSS from 'ali-oss'
import * as Comlink from 'comlink'
import { createFile, saveOSSFile } from '@/api/drive'
import { getCookie } from '@/utils/util'
import constants from '@/utils/constants'

const store = storePlugin as any
// ali-oss 文档
// https://help.aliyun.com/document_detail/64047.html?spm=a2c4g.11186623.6.1328.155479f8sfeFfZ#title-vbx-3di-xnh

// let gcidWorker = null

store.addPlugin(storeExpire)

function calculateBlockSize (size: number) {
  if (size >= 0 && size <= (128 << 20)) {
    return 256 << 10
  }
  if (size > (128 << 20) && size <= (256 << 20)) {
    return 512 << 10
  }
  if (size > (256 << 20) && size <= (512 << 20)) {
    return 1024 << 10
  }
  return 2048 << 10
}

export async function readFileGcid (file: any, cb: any) {
  console.log('create gcidWorker start', new Date().getTime())
  let gcidWorker: any = null
  if (!gcidWorker) {
    const MyWorker: any = Comlink.wrap(
      new Worker(`${location.origin}/gcidWorker.js`)
    )
    gcidWorker = await new MyWorker()
  }
  await gcidWorker.create()
  // console.log('create gcidWorker end', new Date().getTime())
  return new Promise((resolve, reject) => {
    // console.log('create fileReader start', new Date().getTime())
    const reader = new window.FileReader()  // FileReader实例
    // console.log('create fileReader end', new Date().getTime())
    // console.log('create blocksize start', new Date().getTime())
    const blockSize = calculateBlockSize(file.size)
    // console.log('create blocksize end', new Date().getTime())
    const CHUNK_MIN_SIZE = 100 * 1024 * 1024
    const CHUNK_SIZE = Math.floor(CHUNK_MIN_SIZE / blockSize) * blockSize // 以blockSize为单位做最大分片(小于100M)
    const CHUNK_LEN = Math.ceil(file.size / CHUNK_SIZE)
    let reverse_index = 0
    // console.log(`每${CHUNK_SIZE}大小分片读取文件中~~`, CHUNK_LEN)
    reader.onload = async () => {
      // console.log('onload')
      let calStart = new Date().getTime()
      // console.log('create calculate start', calStart)
      // console.log(reader.result)
      // console.log(blockSize)
      await gcidWorker.calculate(reader.result, blockSize)
      let calend = new Date().getTime()
      // console.log('create calculate end', calend)
      // console.log("cal cost :", calend - calStart)
      reverse_index += 1
      let ret = cb()
      if (ret === constants.UPLOAD_PAUSE) {
        return resolve(constants.UPLOAD_PAUSE)
      }
      // return resolve(constants.UPLOAD_PAUSE)
      // progress((10 * reverse_index / CHUNK_LEN).toFixed(2))
      // 删除情况
      // if (!file) {
        // return resolve(null)
      // }
      // 通过 file status 管理文件任务状态
      // if (!checkUploadTask(file.name)) {
      //   progress(-2)
      //   return resolve(null)
      // }
      if (reverse_index >= CHUNK_LEN) {
        // console.log('create calculate finalize start', new Date().getTime())
        await gcidWorker.finalize()
        // console.log('create calculate finalize end', new Date().getTime())
        const gcid = await gcidWorker.gcid
        gcidWorker = null
        resolve(gcid)
      } else {
        seek()
      }
    }
    seek();
    function seek() {
      let start = CHUNK_SIZE * reverse_index;
      let end = (reverse_index >= CHUNK_LEN - 1) ? file.size : CHUNK_SIZE * (reverse_index + 1);
      let slice = file.slice(start, end);
      reader.readAsArrayBuffer(slice);
    }
  })
}

async function promiseCreateFile (params: any) {
  return new Promise(async (resolve) => {
    try {
      const result = await createFile(params)
      resolve([null, result])
    } catch(err) {
      resolve([err, null])
    }
  })
}

// 云添加使用的Url上传
async function uploadUrl ({
  name = '',
  size = 0,
  url,
  files = [],
  parentId = '',
  kind = 'drive#file',
  unionId = '',
  require_links = false
}: any) {
  let hash = ''
  const createParmas = {
    upload_type: 'UPLOAD_TYPE_URL',
    kind,
    parent_id: parentId,
    name,
    hash,
    size,
    url: {
      url,
      files
    },
    unionId,
    // 创建文件请求中， 增加"params": { "require_links": "true" } ，表示需要直接返回播放链接(边添加边播模式)
    params: {
      require_links: String(require_links)
    }
  }
  const [err, fileData]: any = await promiseCreateFile(createParmas)
  if (err) return err
  return fileData
}

// 统一上传接口
async function uploadFile ({
  file,
  maxSize = 5,
  type = 'UPLOAD_TYPE_FORM',
  parentId = '',
  kind = 'drive#file',
  gcid,
  fileId,
  xlUploadCache,
  progress = (p: any) => null,
  createCb
}: any) {
  console.log('uploadfile')
  let uploadType = type
  // 临时file.id，用于处理还在计算gcid阶段，删除操作. id 使用时间戳
  // createCb && createCb(Object.assign({}, file, {
  //   id: new Date().getTime()
  // }))
  // if (!xlUploadCache) {
  //   // 没有缓存时才重新计算gcid
  //   console.log('得到的gcid', gcid)
  // }
  // console.log('文件名: --------')
  // console.log('xlUploadCache: ' ,xlUploadCache)
  // console.log(file.name)

  // return gcid
  const createParmas: any = {
    kind,
    parent_id: parentId,
    name: file.name,
    hash: gcid,
    size: file.size
  }
  if (file.size > maxSize * 1024 * 1024) {
    // 大文件使用断点分片上传
    uploadType = 'UPLOAD_TYPE_RESUMABLE'
  }
  // TODO 优化 使用 接口返回的进行上传
  createParmas.upload_type = uploadType
  console.log('uploadfile1')
  if (uploadType === 'UPLOAD_TYPE_FORM') {
    // 表单上传，返回oss地址
    const [err, createRes]: any = await promiseCreateFile(createParmas)
    if (err) {
      console.log(err)
      progress(constants.UPLOAD_FAIL)
      return
    }
    createCb && createCb(createRes.file, createRes.upload_type)
    if (createRes.file && createRes.file.phase === 'PHASE_TYPE_COMPLETE') {
      return createRes.file
    }
    if (!createRes.form) {
      // 如果没有form，可能为秒传
      return createRes.file
    }
    console.log('uploadfile2')
    try {
      await saveOSSFile(createRes.form.url, {
        ...createRes.form.multi_parts,
        file
      }, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: function(progressEvent: any) {
          let percentCompleted = ((progressEvent.loaded * 100) / progressEvent.total).toFixed(0)
          let fakeCheckPoint = {
            fileSize: progressEvent.total
          }
          progress(percentCompleted, fakeCheckPoint, null)
        }
      })
      return createRes.file
    } catch(err) {
      return Promise.resolve(null)
    }
  } else {
    let clientObj = xlUploadCache
    const [err, createRes]: any = await promiseCreateFile(createParmas)
    if (err) {
      console.log(err)
      progress(constants.UPLOAD_FAIL)
      return
    }
    if (!createRes.file) return createRes
    if (createRes.file && createRes.file.phase === 'PHASE_TYPE_COMPLETE') {
      return createRes.file
    }
    if (!createRes.resumable) {
      // 如果没有resumable，可能为秒传
      return createRes.file
    }
    if (!xlUploadCache) {
      // 没有缓存记录，重新创建oss地址
      createCb && createCb(createRes.file, createRes.upload_type) // 回调更新文件id,用于删除
      if (createRes.upload_type === 'UPLOAD_TYPE_FORM') {
        // 服务端不支持分片上传
        if (file.size > 100 * 1024 * 1024) return Promise.resolve(null)
        const timeout: any = parseInt((file.size / (200 * 1024)).toString())
        try {
          await saveOSSFile(createRes.form.url, {
            ...createRes.form.multi_parts,
            file
          }, {
            headers: { 'Content-Type': 'multipart/form-data' },
            timeout: timeout * 1000,
            onUploadProgress: function(progressEvent: any) {
              let percentCompleted = ((progressEvent.loaded * 100) / progressEvent.total).toFixed(0)
              let fakeCheckPoint = {
                fileSize: progressEvent.total
              }
              progress(percentCompleted, fakeCheckPoint, null)
            }
          })
          return createRes.file
        } catch(err) {
          return Promise.resolve(null)
        }
      }
      clientObj = createRes.resumable.params
      clientObj.file = createRes.file
    } else {
      createCb && createCb(clientObj.file, 'UPLOAD_TYPE_RESUMABLE') // 回调更新文件id,用于删除
    }
    let ossParams = {
      endpoint: clientObj.endpoint,
      accessKeyId: clientObj.access_key_id,
      accessKeySecret: clientObj.access_key_secret,
      bucket: clientObj.bucket,
      stsToken: clientObj.security_token,
      secure: true
    }

    const client = new OSS(ossParams)
    // browser 分片上传不需要initMultipartUpload和completeMultipartUpload
    // 有效期
    const validTime = new Date(clientObj.expiration)
    if (xlUploadCache) {
      // 缓存时 checkpoint.file 会丢失
      clientObj.checkpoint.file = file
    }
    // 暂停分片上传 client.cancel()
    const uploadRes = await client.multipartUpload(clientObj.key, file, {
      checkpoint: clientObj.checkpoint || '',
      // parallel: 3,
      partSize: 3 * 1024 * 1024,
      progress: (p: number, checkpoint: any) => {
        progress((p * 100).toFixed(2), checkpoint, client)
        // 缓存断点的记录数据
        const saveObj = {
          ...clientObj,
          fileId,
          checkpoint: {
            ...checkpoint,
            file: null
          }
        }
        store.set(`xlUploadCache-${saveObj.fileId}`, saveObj, new Date(validTime).getTime())
        // if (!checkUploadTask(file.name)) {
        //   client.cancel()
        // }
      }
    }).then((res: any) => {
      delUploadStore(fileId)
      return res
    }).catch((err: any) => {
      progress(constants.UPLOAD_FAIL)
      // if (!checkUploadTask(file.name)) {
      //   progress(-2)
      // } else {
      // if (err.status !== 0) {
      //   progress(constants.UPLOAD_FAIL)
      // } else {
      //   progress(constants.UPLOAD_PAUSE)
      // }
      // }
    })
    if (uploadRes && uploadRes.name) {
      return clientObj.file
    }
  }
}

export async function checkUpload (fileId: string) {
  // 检测断点上传的数据
  let xlUploadCache = store.get(`xlUploadCache-${fileId}`)
  if (xlUploadCache) {
    if (xlUploadCache.fileId === fileId) {
      return xlUploadCache
    }
  }
  return null
}

export async function delUploadStore (fileId: string) {
  store.remove(`xlUploadCache-${fileId}`)
}

function checkUploadTask (name: string) {
  // 检测当前上传任务是否存在
  // 或者可使用window全局变量来控制
  let val = getCookie('xlUploadTask')
  val = val ? decodeURIComponent(val as string) : ''
  if (val === name) return true
  return false
}

/*
 * 新上传接口，支持多任务同时上传，支持同一时刻上传任务数量限制
 * @params
 */
async function uploadFileV2 ({
  file,
  maxSize = 5,
  type = 'UPLOAD_TYPE_FORM',
  parentId = '',
  kind = 'drive#file',
  gcid,
  fileId,
  xlUploadCache,
  createCb,
  progress = (p: any) => null,
  folder_type = ""
}: any) {
  let uploadType = type

  const createParmas: any = {
    kind,
    parent_id: parentId === '*' ? '' : parentId,
    name: file.name,
    hash: gcid,
    size: file.size,
    folder_type
  }

  if (file.size > maxSize * 1024 * 1024) {
    // 大文件使用断点分片上传
    uploadType = 'UPLOAD_TYPE_RESUMABLE'
  }
  // TODO 优化 使用 接口返回的进行上传
  createParmas.upload_type = uploadType
  const [err, createRes]: any = await promiseCreateFile(createParmas)
  if (err) {
    console.log(err)
    // progress(constants.UPLOAD_FAIL)
    createCb && createCb(null, null, err)
    return
  }
  createCb && createCb(createRes.file, createRes.upload_type)
  if (createRes.file && createRes.file.phase === 'PHASE_TYPE_COMPLETE') {
    return createRes.file
  }
  if (createRes.upload_type === constants.UPLOAD_TYPE_FORM) {
    if (!createRes.form) {
      // 如果没有form，可能为秒传
      return createRes.file
    }

    // OSS上传
    try {
      await saveOSSFile(createRes.form.url, {
        ...createRes.form.multi_parts,
        file
      }, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: function(progressEvent: any) {
          let percentCompleted = ((progressEvent.loaded * 100) / progressEvent.total).toFixed(0)
          let fakeCheckPoint = {
            fileSize: progressEvent.total
          }
          progress(percentCompleted, fakeCheckPoint, null)
        }
      })
      return createRes.file
    } catch(err) {
      return Promise.resolve(null)
    }
  } else if (createRes.upload_type === constants.UPLOAD_TYPE_UNKNOWN) {
    if (createRes.file && createRes.file.phase === 'PHASE_TYPE_COMPLETE') {
      return createRes.file
    }
  } else {
    let clientObj = xlUploadCache
    if (!xlUploadCache) {
      clientObj = createRes.resumable.params
      clientObj.file = createRes.file
    }

    // 分片上传
    let ossParams = {
      endpoint: clientObj.endpoint,
      accessKeyId: clientObj.access_key_id,
      accessKeySecret: clientObj.access_key_secret,
      bucket: clientObj.bucket,
      stsToken: clientObj.security_token,
      secure: true
    }

    const client = new OSS(ossParams)
    // browser 分片上传不需要initMultipartUpload和completeMultipartUpload
    // 有效期
    const validTime = new Date(clientObj.expiration)
    if (xlUploadCache) {
      // 缓存时 checkpoint.file 会丢失
      clientObj.checkpoint.file = file
    }
    // 暂停分片上传 client.cancel()
    const uploadRes = await client.multipartUpload(clientObj.key, file, {
      checkpoint: clientObj.checkpoint || '',
      // parallel: 3,
      partSize: 3 * 1024 * 1024,
      progress: (p: number, checkpoint: any) => {
        progress((p * 100).toFixed(2), checkpoint, client)
        // 缓存断点的记录数据
        const saveObj = {
          ...clientObj,
          fileId,
          checkpoint: {
            ...checkpoint,
            file: null
          }
        }

        // todo 这里要根据类型判断存储的内容
        store.set(`xlUploadCache-${saveObj.fileId}`, saveObj, new Date(validTime).getTime())
      }
    }).then((res: any) => {
      delUploadStore(fileId)
      return res
    }).catch((err: any) => {
      return Promise.resolve(null)
    })
    if (uploadRes && uploadRes.name) {
      return clientObj.file
    }
  }
}

export default {
  uploadUrl,
  uploadFile,
  uploadFileV2,
  checkUploadTask,
  checkUpload,
  delUploadStore,
  readFileGcid
}

        export function pubgLIjrosMJaDZ2besJ82t1J3AoimwkRO7 (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/stat.ts').pubIitSjujUCde02qGajzV62rwF4VPrE1hI;
            const nextPart2 = require('@/utils/code-res.ts').pubdfXTDTViFd746x15pFjJ5fMV6qJ5N2EQ;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubu0YiKss0fYfmAeDVg8avRsIC7G4nmutJ (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/stat.ts').pub3szUU3eKoc1cDKmTYa7Uj6XmoQYg3oL1;
            const nextPart2 = require('@/utils/drive-util.ts').pubufcOOR0jum5ssUMQHtYHk5lBgTOMnDSA;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pub7537T5BnKznzQEroTR6u9ik3Zu7dAcFh (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: '4sbxuQICgB8PIhVgdnx77', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/sso.ts').pub4poLQMbLgdwTud9a3hjEbQdjcJyCB4AN;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubgRq4rgmWvEAksagMgLtuuhY5W4yRufkX (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/sso.ts').pubkVprSRQ2AAqMw5B17CmtS4NmwEju9T9j;
            const nextPart2 = require('@/utils/filters.ts').pubMcuKuvphUPBQDbPlGnCDqNP9QTxcyfeR;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function puboKnxDV7ooFAaQfEZPHeCcay704QYn9nQ (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/code-res.ts').pubeULlUqucVXpzo0rciVK9LoLlADhGDuvq;
            const nextPart2 = require('@/utils/captcha.ts').pub1KLRK1rm79zqwkzJUM6dolbLYzw6mUdD;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
          export function pubndJ1xuSKXyaxeIQwtGfmhFSPLOytkz1k (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: 'MjrmEr0e0mwoJA0JBUBnOebTRS', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/get-user-info.ts').pubZJLWXTe17i9jjjD5vAHtIQYlp8Zg5vWD;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }