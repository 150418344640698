import { Plugin } from "@nuxt/types";

const userAgentPlugin: Plugin = (context: any) => {
  context.userAgent = process.server
    ? context.req.headers["user-agent"]
    : navigator.userAgent;
  let isFromPCClient = false;
  let isMobile = false;

  if (context.userAgent && context.userAgent.match(/Thunder/g)) {
    isFromPCClient = true;
  } else if (
    process.client &&
    (window.WebViewJavascriptBridge || window.native)
  ) {
    isFromPCClient = true;
  }

  if (
    context.userAgent &&
    context.userAgent.toLowerCase().indexOf("mobi") > -1
  ) {
    isMobile = true;
  }
  console.log("isFromPCClient >>>", isFromPCClient, context.userAgent);
  context.store.commit("SET_IS_NATIVE", isFromPCClient);
  context.store.commit("SET_IS_MOBILE", isMobile);
};

export default userAgentPlugin;
