// 当前任务状态,0 获取文件信息（如计算gcid）,1开始上传，2完成,-1失败,-2暂停,-3排队中
export default {
  // 本地上传文件状态
  UPLOAD_GET_INFO: 0,
  UPLOAD_START: 1,
  UPLOAD_DONE: 2,
  UPLOAD_FAIL: -1,
  UPLOAD_PAUSE: -2,
  UPLOAD_WAIT: -3,

  // VIP弹窗提示状态
  NO_VIP_DIALOG_QUANTITY_LIMIT: 1, // 【非会员】文件数量超限
  NO_VIP_DIALOG_FILE_SIZE_LIMIT: 2, // 【非会员】文件大小超限
  VIP_DIALOG_QUANTITY_LIMIT: 3, // 【会员（包括白金会员和超级会员）】状态下文件数量超限
  VIP_DIALOG_FILE_SIZE_LIMIT: 4, // 【白金会员】文件大小超限
  SUPER_VIP_DIALOG_FILE_SIZE_LIMIT: 5, //【超级会员】文件大小超限

  // 上传类型
  UPLOAD_TYPE_FORM: "UPLOAD_TYPE_FORM",
  UPLOAD_TYPE_RESUMABLE: "UPLOAD_TYPE_RESUMABLE",
  UPLOAD_TYPE_UNKNOWN: "UPLOAD_TYPE_UNKNOWN",

  // 跳转地址
  YINGYONGBAK_SHARE_DOWNLOAD_URL:
    "http://a.app.qq.com/o/simple.jsp?pkgname=com.xunlei.downloadprovider&ckey=CK1496245874776",
  YINGYONGBAK_HOMEPAGE_DOWNLOAD_URL:
    "http://a.app.qq.com/o/simple.jsp?pkgname=com.xunlei.downloadprovider&ckey=CK1496245874776",

  DEFAULT_AVATAR_URL: "https://xfile2.a.88cdn.com/file/k/avatar/default",
  ZONE_RES_TAB: [
    {
      id: "1",
      title: "推荐资源",
      en: "recommend",
    },
    {
      id: "2",
      title: "最新资源",
      en: "new",
    },
    {
      id: "3",
      title: "最热资源",
      en: "popular",
    },
  ],
  XL_VIP_IDENTITY: {
    3: "白金会员",
    5: "超级会员",
  },
};
