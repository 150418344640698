






























































































































import Vue from "vue";
import ssoMixin from "@/mixins/sso";
import { joinUrlQueryString, debounce, isMacThunder } from "@/utils/util";
import DailyStat from "~/components/DailyStat.vue";
import Privilege from "~/components/vip/Privilege.vue";
import Avatar from "@/components/Avatar.vue";
import Search from "@/components/Search.vue";
import eventEmitter from "@/utils/eventEmitter";
import eventMap from "@/utils/event-name";
import { parseTime } from "@/utils/filters";
import logo from "@/assets/img/logo-text.svg";

export default Vue.extend({
  name: "site-header",
  mixins: [ssoMixin],
  props: {
    page: {
      type: String,
      default: "home",
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DailyStat,
    Privilege,
    Avatar,
    Search,
  },
  data() {
    return {
      isClient: false,
      hovering: false,
      reporting: false,
      logo,
      searchDisabledPages: ["message", "privacy", "publish", "vip"],
      baiduSpecialFlag:
        (this.$route.query.entry_source === "bdwenku" ||
          this.$route.query.entry_source === "slhometab") &&
        (this.page === "detail" || this.page === "personal"),
      isMobileNative: false,
    };
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    isVip() {
      return this.curUser.vipData && this.curUser.vipData.isVip;
    },
    expiredAt() {
      return this.curUser.vipData && this.curUser.vipData.expiredAt;
    },
    queryStr() {
      const queryObj = this.$route.query;
      const routeName = this.$route.name;
      const queryStr = joinUrlQueryString(queryObj, routeName);
      return queryStr;
    },
    isPcClient() {
      if (process.client) {
        return !!window.native;
      }
      return this.$store.state.isPCNative;
    },
    payParams() {
      return this.$store.state.vip.payParams;
    },
    xlVipInfo() {
      return this.curUser.xlVipData;
    },
    isXlVip() {
      return this.xlVipInfo && this.xlVipInfo.isVip;
    },
    xlVipType() {
      if (this.xlVipInfo) {
        return this.xlVipInfo.vipType;
      }
      return 0;
    },
    activityList() {
      return this.$store.state.activity.list;
    },
    activityInfo() {
      const item = this.activityList[0];
      if (item) {
        return item;
      }
      return {};
    },
    activityValid() {
      const item = this.activityInfo;
      let valid = false;
      if (item && item.start_time && item.end_time) {
        const currentTime = new Date().getTime();
        const startTime = +item.start_time;
        const endTime = +item.end_time;
        valid = currentTime >= startTime && currentTime <= endTime;
      }
      return valid && !!item.remark;
    },
  },
  mounted() {
    if (window.native || isMacThunder()) {
      this.isClient = true;
    }

    const {
      isAndroidNative,
      isIOSNative,
    } = require("@xunlei/universal-native-api");
    this.isMobileNative = isAndroidNative || isIOSNative;

    setTimeout(() => {
      this.stat("xiaozhan_top_bar_show", {
        pageType: this.page,
      });

      this.stat("xiaozhan_vip_topbutton_pay_show", {
        referfrom: "v_wap_xiaozhan_ggong_top_button",
        aidfrom: this.payParams.aidfrom,
      });
    }, 800);
  },
  beforeDestroy() {},
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    parseTime(time: string) {
      return parseTime(time, "{y}-{m}-{d}");
    },
    handleLogin() {
      this.stat("xiaozhan_top_bar_click", {
        pageType: this.page,
        clickid: "head",
      });
      this.popLogin("topbar");
    },
    handleLogout() {
      this.stat("xiaozhan_top_bar_click", {
        pageType: this.page,
        clickid: "head",
      });
      this.logout();
    },
    handleCheckSpace() {
      this.stat("xiaozhan_top_bar_click", {
        pageType: this.page,
        clickid: "my_station",
      });
    },
    handleClickSave() {
      this.stat("xiaozhan_top_bar_click", {
        pageType: this.page,
        clickid: "collect",
      });
    },
    handleCheckSite() {
      this.stat("xiaozhan_top_bar_click", {
        pageType: this.page,
        clickid: "xiaozhan",
      });
    },
    handleCheckMessage() {
      this.stat("xiaozhan_top_bar_click", {
        pageType: this.page,
        clickid: "message",
      });
    },
    onVipPay() {
      if (!this.hasLogin) {
        this.popLogin("vip");
        return;
      }

      eventEmitter.emit(eventMap.SHOW_PAY_DIALOG, 0, {});
    },
    headerPayStat() {
      this.stat("xiaozhan_vip_topbutton_pay_click", {
        referfrom: "v_wap_xiaozhan_ggong_top_button",
        aidfrom: this.payParams.aidfrom,
      });

      this.$store.commit("vip/SET_PAY_PARAMS", {
        referfrom: "v_wap_xiaozhan_ggong_top_button",
      });
    },
    panelPayStat() {
      this.stat("xiaozhan_vip_head_hover_click", {
        referfrom: "v_wap_xiaozhan_ggong_head_hover",
        aidfrom: this.payParams.aidfrom,
      });

      this.$store.commit("vip/SET_PAY_PARAMS", {
        referfrom: "v_wap_xiaozhan_ggong_head_hover",
      });
    },
    panelHoverStat() {
      if (!this.hovering) {
        this.hovering = true;
        this.stat("xiaozhan_vip_head_hover_show", {
          referfrom: "v_wap_xiaozhan_ggong_head_hover",
          aidfrom: this.payParams.aidfrom,
        });
        setTimeout(() => {
          this.hovering = false;
        }, 800);
      }
    },
    privilegeShowStat() {
      if (!this.reporting) {
        this.reporting = true;
        this.stat("xiaozhan_vip_topbutton_hover_pay_show", {
          referfrom: "v_wap_xiaozhan_ggong_top_button_panel",
          aidfrom: this.payParams.aidfrom,
        });

        setTimeout(() => {
          this.reporting = false;
        }, 800);
      }
    },
  },
});
