






import Vue from "vue";

export default Vue.extend({
  name: "loading-bar",
  data: () => ({
    loading: false,
  }),
  methods: {
    start() {
      this.loading = true;
    },
    finish() {
      this.loading = false;
    },
  },
});
