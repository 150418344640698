import { fetchDataForDrive } from "@/api/fetch-res";
import axios from "axios";
import { IShareBatchGetParams } from "@/types/drive";
const CancelToken = axios.CancelToken;
export const source = CancelToken.source();

export async function findFiles(params: any): Promise<any> {
  // 默认拉取任务完成的文件
  // params.filters = {...params.filters, "phase":{"eq":"PHASE_TYPE_COMPLETE"}}
  return fetchDataForDrive("files", params);
}

export async function createFile(params: any): Promise<any> {
  return fetchDataForDrive("files", params, {
    withCaptcha: true,
    method: "POST",
    headers: {
      "x-request-from": params.unionId,
    },
  });
}

export async function getFileInfo(id: string): Promise<any> {
  return fetchDataForDrive(`files/${id}`);
}

export async function deleteFile(id: string): Promise<any> {
  return fetchDataForDrive(
    `files/${id}`,
    {},
    {
      withCaptcha: false,
      method: "DELETE",
    }
  );
}

async function updateFile({ id, params }: any): Promise<any> {
  return fetchDataForDrive(`files/${id}`, params, {
    withCaptcha: true,
    method: "PATCH",
  });
}

async function trashFile(id: string): Promise<any> {
  return fetchDataForDrive(
    `files/${id}/trash`,
    {},
    {
      withCaptcha: true,
      method: "PATCH",
    }
  );
}

async function untrashFile(id: string): Promise<any> {
  return fetchDataForDrive(
    `files/${id}/untrash`,
    {},
    {
      withCaptcha: true,
      method: "PATCH",
    }
  );
}

export async function clearTrash(): Promise<any> {
  return fetchDataForDrive(
    "files/trash:empty",
    {},
    {
      withCaptcha: true,
      method: "PATCH",
    }
  );
}

async function copyFile({ id, parent_id, name }: any): Promise<any> {
  return fetchDataForDrive(
    `files/${id}/copy`,
    {
      to: {
        parent_id,
        name,
      },
    },
    {
      withCaptcha: true,
      method: "POST",
    }
  );
}

async function moveFile({ id, parent_id, name }: any): Promise<any> {
  return fetchDataForDrive(
    `files/${id}/move`,
    {
      to: {
        parent_id,
        name,
      },
    },
    {
      withCaptcha: true,
      method: "POST",
    }
  );
}

// 文件直传oss地址
export async function saveOSSFile(
  url: string,
  params: any,
  config: any
): Promise<any> {
  console.log("oss upload axios");
  const formData = new FormData();
  if (params) {
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });
  }
  // 默认10秒为超时
  return axios
    .post(
      url,
      formData,
      Object.assign({}, config, {
        cancelToken: source.token,
      })
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));
}

export async function getTasks(params: any): Promise<any> {
  // params.filters = {...params.filters, "phase":{"ne":"PHASE_TYPE_COMPLETE"}}
  params.type = "offline";
  return fetchDataForDrive("tasks", params);
}

export async function getTask(id: string): Promise<any> {
  return fetchDataForDrive(`task/${id}/statuses`);
}

export async function getTaskStatus(params: any): Promise<any> {
  let param = params.status_ids.join("&status_ids=");
  param = "?status_ids=" + param;
  return fetchDataForDrive(`statuses${param}`);
}

export async function delTasks(taskIds: any): Promise<any> {
  let taskIdQs = "";
  if (Array.isArray(taskIds)) {
    taskIdQs = `task_ids=${taskIds.join("&task_ids=")}`;
  } else {
    taskIdQs = `task_ids=${taskIds}`;
  }
  return fetchDataForDrive(
    `tasks?${taskIdQs}`,
    {},
    {
      withCaptcha: true,
      method: "DELETE",
    }
  );
}

export async function getEvent(params: any): Promise<any> {
  return fetchDataForDrive("events", params);
}

export async function clearEvent(): Promise<any> {
  return fetchDataForDrive(
    "events:clear",
    {},
    {
      method: "POST",
    }
  );
}

export async function delEvent(ids: any): Promise<any> {
  return fetchDataForDrive(
    "events:delete",
    {
      ids,
    },
    {
      method: "POST",
    }
  );
}

export async function patchEvent(id: string, params: any): Promise<any> {
  return fetchDataForDrive(`events/${id}`, params, {
    method: "PATCH",
  });
}

export async function getAbout(params: any): Promise<any> {
  return fetchDataForDrive("about", params);
}

export async function checkPrivilege(
  privilege: any,
  params = {}
): Promise<any> {
  return fetchDataForDrive(`privilege/${privilege}`, params);
}

export async function applyPrivilege(params: any): Promise<any> {
  return fetchDataForDrive("privilege", params, {
    withCaptcha: true,
    method: "POST",
  });
}

export async function batchGetFileInfo(ids: string[]) {
  return fetchDataForDrive("files:batchGet", ids);
}

export async function batchGetShareFileInfo(params: IShareBatchGetParams): Promise<any> {
  return fetchDataForDrive("share:batch_file_info", params, {
    method: "POST",
  });
}

export default {
  findFiles,
  createFile,
  getFileInfo,
  deleteFile,
  updateFile,
  trashFile,
  untrashFile,
  clearTrash,
  copyFile,
  moveFile,
  saveOSSFile,
  getTasks,
  getTask,
  getTaskStatus,
  delTasks,
  getEvent,
  clearEvent,
  delEvent,
  patchEvent,
  getAbout,
  checkPrivilege,
  applyPrivilege,
  batchGetFileInfo,
  batchGetShareFileInfo,
};
