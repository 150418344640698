import { sendMessage } from "./xl-client-common";

export default {
  // android
  isNative() {
    return !!window.XLAccountJsBridge;
  },
  callFunction(target: string, params = {}, args: any) {
    return sendMessage({
      jsBridge: "XLJSWebViewBridge",
      method: "sendMessage",
      target,
      params,
      ...args,
    });
  },
  // 用于云盘手雷内账号authorize获取，在webview中注入了账号的方法，可直接调用
  ssoCallFunction(target: string, params = {}) {
    return sendMessage({
      jsBridge: "XLAccountJsBridge",
      method: "callFunction",
      target,
      params,
      isJSON: false, // 目前 getAccessToken 这个方法只返回字符串
    });
  },
  // ios
  isIOS() {
    return !!window.WebViewJavascriptBridge;
  },
  callNativeHandler(target: string, params = {}, args: any) {
    return sendMessage({
      jsBridge: "WebViewJavascriptBridge",
      method: "callHandler",
      target,
      params,
      isCallBack: true,
      ...args,
    });
  },
};
