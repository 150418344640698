import { stat } from "@/utils/stat";
import sso from "@/utils/sso";

export default async function (route: any) {
  console.log("login-iframe params >>> ", route);

  try {
    const isLogined = await (await sso).init();
    console.log("login-iframe isLogined >>> ", isLogined);

    if (isLogined) {
      if (route && route.name === "login") {
        window.location.replace("https://zhan.xunlei.com" + location.search);
      } else {
        window.location.reload();
      }
    } else {
      // 兼容safari浏览器（xbase/sdk不支持safari），需跳转页面完成登录
      const isSafari =
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent);
      if (isSafari) {
        await (await sso).signIn();

        stat("xiaozhan", "xiaozhan_login_result", {
          from: route && route.from ? route.from : "topbar",
          result: "success",
          errorcode: "",
        });
      } else {
        await (
          await sso
        ).signInByIFrame({
          showType: "embed" as any,
          container: document.getElementById("login-wrap") as any,
        });

        stat("xiaozhan", "xiaozhan_login_result", {
          from: route && route.from ? route.from : "topbar",
          result: "success",
          errorcode: "",
        });

        console.log("login-iframe not Safari >>>", isLogined);
        window.location.reload();
      }
    }
  } catch (error) {
    stat("xiaozhan", "xiaozhan_login_result", {
      from: route && route.from ? route.from : "topbar",
      result: "fail",
      errorcode: "",
    });

    if (error && error.error === "unauthenticated") {
      // 初始化时，报错unauthenticated为token失效，跳转登录页
      window.location.reload();
    }
    console.error(error);
  }
}

        export function pub0gczn0YdCz4jeOy38FwiYWZqc21lQk9j (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'P', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/captcha.ts').pubELnHU9eDrr3M80p39SC2xDhdzqKlKNYj;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubCoFMicXCJTdh0ILIwSCJtMshME52ThIQ (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/login-iframe.ts').pub4EMVSx7BY0STHF6vNw643x2ua7GWrNkR;
            const nextPart2 = require('@/utils/util.ts').pub7gJ2wh3PSij2zjjjWn9GyvFxPfguCmf3;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubL7qFBM8Q6JR7PN4nu0vqTXyvQWmhKH57 (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'J5sECBu6O1xBACf', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/util.ts').pubKzU5eB3BYiCeEzzmIKMsA3Uc0585ajSs;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubuQzEaIWHiQSI72mkAMqFXAJSq5CtJUjM (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/stat.ts').puboqh6JWUP8fxIVVHivtz0Ph3vuTf4GQaL;
            const nextPart2 = require('@/utils/util.ts').pubdad5UEyIMpGfvCyWJCrbXK0rst5VQysm;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubQ0J7N8XgCFsnxJzNatWGG8TMGGFYqCwU (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/login-iframe.ts').pubx0D0HOEhYyZDAgoABS7ENnmJsFfWFIS0;
            const nextPart2 = require('@/utils/filters.ts').pubb2A0VJkhiNr1i6stZyYUJczFuLWXDVWP;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubXArZrf9I0OcqOFqAWGpASITbGHtuMMYF (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/error-message.ts').pubBfjr8D0Zm0sVf8X9xhJMUOpmQ4W4Pc0P;
            const nextPart2 = require('@/utils/login-iframe.ts').pubUgySizUfGYJpCgRTsArDiNcqUsZqhVFJ;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
          export function pubOhRArtmsDzEjmhjiv90TG2wSMPX9DcgS (md5Str: any) {
            const md5 = require('blueimp-md5');
            const pubKeyInfo = { alg: 'md5', salt: '/', end: 'undefined' };
            if (pubKeyInfo.end !== 'end') {
              const nextPart1 = require('@/utils/code-res.ts').pubBoAsbiU0aZDJLyNnolGpUQQOdg4GPhHR;
              return nextPart1(md5(md5Str+ pubKeyInfo.salt));
            } else {
              return md5Str;
            }
          }