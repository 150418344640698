import axios from "axios";
import { GetterTree, ActionTree, MutationTree } from "vuex";
import { UserModuleState } from "@/store/user";
import {
  IThinkSearchParams,
  ISpaceSearchParams,
  IZhanInfoParams,
  IZhanInfo,
  IZhanInfoResult,
  IFeedInfoParams,
  ISearchFeedResult,
  IFeedParams,
  IFeedResult,
  IFeedItem,
  IUserFeedParams,
  IFeedCategory,
  IFeedDeleteParams,
  IFeedCreateParams,
  IFeedChannel,
  IFeedChannelItem,
  IFeedChannelCreateParams,
  IFeedChannelDeleteParams,
  IFeedChannelModifyParams,
  IFeedLabel,
  IFeedLabelParams,
  IFeedLabelResult,
  IFavoriteParams,
  IProfileFeedResult,
  IProfileParams,
  IFavoriteItem,
  IUserFavoriteParams,
  IReportDownloadParams,
  IDailyStatParams,
  IDailyStatBody,
  IMessageItem,
  IUserMessageParams,
  ITextSensitiveParams,
  ICommentParams,
  ICommentItem,
  ICommentActionParams,
  ICommentCreateParams,
  IZoneListParams,
  IZoneListResponse,
  IZoneInfoParams,
  IZoneInfoResult,
  IZoneResourceParams,
  IZoneLabelResourceParams,
  IZoneResourceItem,
  IZoneResourceResult,
  IZoneResourceResponse,
  IZoneListItem,
  IZoneActionParams,
  IThirdPartyUrlParams,
  IThirdPartyUrlResult,
  IContentSearchParams,
  IContentSearchResult,
  IHomeBannerParams,
  IHomeBannerResult,
  IHotwordParams,
  IHotwordResult,
  IHomeTopicParams,
  IHomeTopicResult,
} from "@/types/post";
import api from "@/api/post";
import constants from "@/utils/constants";

const FEED_LIMIT = 24;
const MESSAGE_LIMIT = 30;
const FEED_COMMENT_LIMIT = 10;
const ZONE_RESOURCE_LIMIT = 18;

export const state = () => ({
  name: "post",
  searchThinkResult: [],
  homeFeed: {
    list: [] as IFeedItem[],
    pageToken: "",
    hasMore: false,
  },
  userFeed: {
    list: [] as IFeedItem[],
    pageToken: "",
    hasMore: false,
    total: 0,
  },
  userFeedChannel: "",
  searchFeed: {
    list: [] as IFeedItem[],
    pageToken: "",
    hasMore: false,
    total: 0,
  },
  zhanInfo: {
    profile: {} as IZhanInfo,
    channels: [] as IFeedChannelItem[],
  },
  feedLabels: [] as IFeedLabel[],
  feedInfo: {
    id: "",
    favor: {},
    user_info: {},
    files: [],
    describe: "",
  },
  feedCategory: [],
  feedChannel: [] as IFeedChannelItem[],
  profileFeed: [],
  userFavorite: {
    list: [] as IFavoriteItem[],
    pageToken: "",
    hasMore: false,
    total: 0,
  },
  userMessage: {
    list: [] as IMessageItem[],
    pageToken: "",
    hasMore: false,
  },
  userDailyStat: {},
  feedComment: {
    list: [] as ICommentItem[],
    pageToken: "",
    hasMore: false,
    total: 0,
    users: {},
    is_praises: {} as { [id: string]: boolean },
  },
  zoneList: [] as IZoneListItem[],
  zoneInfo: {
    name: "",
    remark: "",
  },
  zoneHomeResource: {
    list: {
      data: [] as IZoneResourceResult[],
      resources: {},
      labels: {},
    },
    pageToken: "",
    hasMore: false,
    total: 0,
  },
  zoneLabelResource: {
    list: [] as IZoneResourceItem[],
    pageToken: "",
    hasMore: false,
    total: 0,
  },
  contentSearchFeed: {
    list: [] as IFeedItem[],
    pageToken: "",
    hasMore: false,
    total: 0,
  },
  homeBanner: [],
  hotwords: [],
  homeTopic: [],
  wenkuContentInfo: {
    status: {},
    data: {
      total: 0,
      docList: [],
    },
  },
});

export type PostModuleState = ReturnType<typeof state>;

export const getters: GetterTree<PostModuleState, UserModuleState> = {
  name: (state) => state.name,
};

export const mutations: MutationTree<PostModuleState> = {
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_SEARCH_THINK_RESULT: (state, result) => {
    state.searchThinkResult = result;
  },
  SET_ZHAN_INFO: (state, info) => {
    state.zhanInfo = info;
  },
  SET_FEED_LABEL: (state, labels) => {
    state.feedLabels = labels;
  },
  SET_FEED_INFO: (state, info) => {
    state.feedInfo = info;
  },
  SET_HOME_FEED_LIST: (state, { refresh, list, pageToken, hasMore }) => {
    state.homeFeed = {
      list: refresh ? list : [...state.homeFeed.list, ...list],
      pageToken,
      hasMore,
    };
  },
  SET_SEARCH_FEED_LIST: (
    state,
    { refresh, list, pageToken, hasMore, total }
  ) => {
    state.searchFeed = {
      list: refresh ? list : [...state.searchFeed.list, ...list],
      pageToken,
      hasMore,
      total,
    };
  },
  SET_USER_FEED_LIST: (state, { refresh, list, pageToken, hasMore, total }) => {
    state.userFeed = {
      list: refresh ? list : [...state.userFeed.list, ...list],
      pageToken,
      hasMore,
      total,
    };
  },
  SET_USER_FEED_ACTIVE_CHANNEL: (state, { channel }) => {
    state.userFeedChannel = channel;
  },
  CHANGE_USER_FEED_STATUS: (state, info) => {
    state.userFeed.list = state.userFeed.list.map((v) => {
      if (info.id === v.id) {
        return Object.assign(v, { ...info });
      }
      return v;
    });
  },
  ADD_USER_FEED: (state, feed) => {
    // state.userFeed.list = [feed, ...state.userFeed.list]
  },
  DELETE_USER_FEED: (state, { id }) => {
    state.userFeed.list = state.userFeed.list.filter((v) => id !== v.id);
  },
  SET_FEED_CATEGORY: (state, category) => {
    state.feedCategory = category;
  },
  ADD_FEED_CHANNEL: (state, channel: any) => {
    state.feedChannel = [channel, ...state.feedChannel];
  },
  DELETE_FEED_CHANNEL: (state, { id }) => {
    state.feedChannel = state.feedChannel.filter((v) => id !== v.id);
  },
  SET_FEED_CHANNEL: (state, channels) => {
    state.feedChannel = channels;
  },
  SET_PROFILE_FEED: (state, feeds) => {
    state.profileFeed = feeds;
  },
  SET_USER_FAVORITE_LIST: (
    state,
    { refresh, list, pageToken, hasMore, total }
  ) => {
    state.userFavorite = {
      list: refresh ? list : [...state.userFavorite.list, ...list],
      pageToken,
      hasMore,
      total,
    };
  },
  DELETE_USER_FAVORITE: (state, { id }) => {
    state.userFavorite.list = state.userFavorite.list.filter(
      (v) => id !== v.id
    );
  },
  CHANGE_USER_FAVORITE: (state, { id, favor }) => {
    state.userFavorite.list = state.userFavorite.list.map((v) => {
      if (id === v.id) {
        const newFavor = Object.assign(v.favor, { ...favor });
        return {
          ...v,
          favor: newFavor,
        };
      }
      return v;
    });
  },
  SET_USER_MESSAGE: (state, { refresh, list, pageToken, hasMore }) => {
    state.userMessage = {
      list: refresh ? list : [...state.userMessage.list, ...list],
      pageToken,
      hasMore,
    };
  },
  SET_USER_DAILY_STAT: (state, stats) => {
    state.userDailyStat = stats;
  },
  SET_FEED_COMMENTS: (
    state,
    { refresh, list, pageToken, hasMore, total, users, praises }
  ) => {
    state.feedComment = {
      list: refresh ? list : [...state.feedComment.list, ...list],
      pageToken,
      hasMore,
      total,
      users: refresh
        ? users
        : Object.assign({}, state.feedComment.users, users),
      is_praises: refresh
        ? praises
        : Object.assign({}, state.feedComment.is_praises, praises),
    };
  },
  ADD_FEED_COMMENTS: (state, { level, cid, item, users }) => {
    console.log("ADD_FEED_COMMENTS >>>", item, level, cid);
    if (level === "1") {
      state.feedComment.list = [item, ...state.feedComment.list];
      state.feedComment.total = Number(state.feedComment.total) + 1;
      state.feedComment.users = Object.assign(
        {},
        users,
        state.feedComment.users
      );
    } else {
      console.log("users >>>>>", users);
      state.feedComment.list = state.feedComment.list.map((v) => {
        if (v.comment_id === cid) {
          v.replies = {
            ...v.replies,
            data: [item, ...v.replies.data],
            users: Object.assign({}, users, v.replies.users),
            total: `${Number(v.replies.total) + 1}`,
          };
        }
        return v;
      });
    }
  },
  DELETE_FEED_COMMENT: (state, { id, level, cid }) => {
    if (level === "1") {
      state.feedComment.list = state.feedComment.list.filter(
        (v) => id !== v.comment_id
      );
      state.feedComment.total = Number(state.feedComment.total) - 1;
    } else {
      state.feedComment.list = state.feedComment.list.map((v) => {
        if (v.comment_id === cid) {
          v.replies = {
            ...v.replies,
            data: v.replies.data.filter((d) => {
              return d.comment_id !== id;
            }),
            total: `${Number(v.replies.total) - 1}`,
          };
        }
        return v;
      });
    }
  },
  LIKE_FEED_COMMENT: (state, { id, count, level, cid }) => {
    console.log("id, count, level, cid >>>", id, count, level, cid);
    if (level === "1") {
      state.feedComment.list = state.feedComment.list.map((v) => {
        if (id === v.comment_id) {
          const newLikedCount = `${Number(v.gcount) + Number(count)}`;
          return {
            ...v,
            gcount: newLikedCount,
          };
        }
        return v;
      });

      state.feedComment.is_praises[id] = !!(Number(count) + 1);
    } else {
      state.feedComment.list = state.feedComment.list.map((v) => {
        if (v.comment_id === cid) {
          v.replies = {
            ...v.replies,
            data: v.replies.data.map((d) => {
              if (d.comment_id === id) {
                d.gcount = `${Number(d.gcount) + Number(count)}`;
              }
              return d;
            }),
            is_praises: Object.assign({}, v.replies.is_praises, {
              [id]: !!(Number(count) + 1),
            }),
          };
        }
        return v;
      });
    }
  },
  SET_FEED_SUB_COMMENTS: (state, { list, cid }) => {
    state.feedComment.list = state.feedComment.list.map((item) => {
      if (item.comment_id === cid) {
        item.replies = list;
      }
      return item;
    });
  },
  SET_ZONE_LIST: (state, zones) => {
    state.zoneList = zones;
  },
  SET_ZONE_INFO: (state, info) => {
    state.zoneInfo = info;
  },
  SET_ZONE_HOME_RESOURCE: (state, { list, pageToken, hasMore, total }) => {
    state.zoneHomeResource = {
      list,
      pageToken,
      hasMore,
      total,
    };
  },
  SET_ZONE_LABEL_RESOURCE: (
    state,
    { refresh, list, pageToken, hasMore, total }
  ) => {
    state.zoneLabelResource = {
      list: refresh ? list : [...state.zoneLabelResource.list, ...list],
      pageToken,
      hasMore,
      total,
    };
  },
  CHANGE_ZONE_RESOURCE_FAVORITE: (state, { id, favor }) => {
    state.zoneLabelResource.list = state.zoneLabelResource.list.map((v) => {
      if (id === v.id) {
        return {
          ...v,
          is_favor: favor,
        };
      }
      return v;
    });
  },
  SET_CONTENT_SEARCH_LIST: (
    state,
    { refresh, list, pageToken, hasMore, total }
  ) => {
    state.contentSearchFeed = {
      list: refresh ? list : [...state.contentSearchFeed.list, ...list],
      pageToken,
      hasMore,
      total,
    };
  },
  CHANGE_SEARCH_FEED_FAVORITE: (state, { id, favor }) => {
    state.contentSearchFeed.list = state.contentSearchFeed.list.map((v) => {
      if (id === v.id) {
        return {
          ...v,
          is_favor: favor,
        };
      }
      return v;
    });
  },
  SET_HOME_BANNER: (state, banners) => {
    state.homeBanner = banners;
  },
  SET_HOTWORD: (state, hotwords) => {
    state.hotwords = hotwords;
  },
  SET_HOME_TOPIC: (state, topic) => {
    state.homeTopic = topic;
  },

  SET_HOME_WENKU_CONTENT_INFO: (state, info) => {
    state.wenkuContentInfo = info;
  },
};

export const actions: ActionTree<PostModuleState, UserModuleState> = {
  nuxtServerInit({ commit }, { req }) {
    console.log(req.auth);
  },
  fetchZhanInfo({ commit, state }, params: IZhanInfoParams = { userid: "" }) {
    console.log("fetchZhanInfo store >>>", params);

    return api
      .fetchZhanInfo(params)
      .then((res: IZhanInfoResult): any => {
        commit("SET_ZHAN_INFO", res);
        return res;
      })
      .catch((e) => {
        console.log("fetchZhanInfo-error >>>", e);
        return {};
      });
  },
  fetchFeedInfo({ commit, state }, params: IFeedInfoParams) {
    return api.fetchFeedInfo(params).then((res: IFeedItem): any => {
      if (res) {
        commit("SET_FEED_INFO", res);
      }
      return res;
    });
  },
  fetchHomeFeed(
    { commit, state, rootState },
    params: IFeedParams = { refresh: false, limit: FEED_LIMIT, res_type: "3" }
  ) {
    const { refresh, res_type, limit } = params;
    const newParams: IFeedParams = Object.assign({}, params, {
      res_type: res_type || "3",
      limit: limit || FEED_LIMIT,
      page_token: refresh ? "" : state.homeFeed.pageToken,
    });

    delete newParams.refresh;
    console.log("fetchHomeFeed store >>>", newParams);

    return api
      .fetchHomeFeedList(newParams)
      .then((res: IFeedResult): any => {
        commit("SET_HOME_FEED_LIST", {
          refresh,
          list: res.data,
          pageToken: res.next_page_token,
          hasMore: res.has_more,
        });
        return res;
      })
      .catch((e) => {
        console.log("---error----", e);
      });
  },
  fetchUserFeed(
    { commit, state },
    params: IUserFeedParams = {
      refresh: false,
      limit: FEED_LIMIT,
      res_type: "3",
    }
  ) {
    const { refresh, res_type, limit, user_id, channel_id, ordering } = params;
    const newParams: IUserFeedParams = Object.assign({}, params, {
      res_type: res_type || "3",
      limit: limit || FEED_LIMIT,
      page_token: refresh ? "" : state.userFeed.pageToken,
      user_id: user_id,
      channel_id: channel_id || "",
      ordering: ordering,
    });

    delete newParams.refresh;
    console.log("fetchUserFeed store >>>", newParams);

    commit("SET_USER_FEED_ACTIVE_CHANNEL", { channel: channel_id });

    return api
      .fetchUserFeedList(newParams)
      .then((res: IFeedResult): any => {
        // 防止快速切换channel导致接口返回速度不一致导致当前channel列表被覆盖
        if (channel_id === state.userFeedChannel) {
          commit("SET_USER_FEED_LIST", {
            refresh,
            list: res.data,
            pageToken: res.next_page_token,
            hasMore: res.has_more,
            total: res.total,
          });
        }

        return res;
      })
      .catch((e) => {
        console.log("ACTION fetchUserFeed error >>>", e);
      });
  },
  thinkSearch({ commit, state }, params: IThinkSearchParams = { keyword: "" }) {
    commit("SET_SEARCH_THINK_RESULT", [
      "shy",
      "evershy",
      "名字要长长长长长长长长很长长长长长长",
    ]);
    return [{ code: 0 }];

    return api.thinkSearch(params).then((res) => {
      commit("SET_SEARCH_THINK_RESULT", res);
      return res;
    });
  },
  contentSearch(
    { commit, state },
    params: IContentSearchParams = {
      refresh: false,
      limit: FEED_LIMIT,
      s: "",
      user_id: "",
      partner: "",
    }
  ) {
    const { refresh, limit, s, user_id, partner = "" } = params;
    const newParams: IContentSearchParams = Object.assign({}, params, {
      limit: limit || FEED_LIMIT,
      page_token: refresh ? "" : state.contentSearchFeed.pageToken,
      s: s || "",
      user_id,
      partner,
    });

    delete newParams.refresh;

    return api.contentSearch(newParams).then((res: IContentSearchResult) => {
      commit("SET_CONTENT_SEARCH_LIST", {
        refresh,
        list: res.data,
        pageToken: res.next_page_token,
        hasMore: res.has_more,
        total: res.total,
      });
      return res;
    });
  },
  spaceFeedSearch(
    { commit, state },
    params: ISpaceSearchParams = {
      refresh: false,
      limit: FEED_LIMIT,
      res_type: "3",
      s: "",
      user_id: "",
      scope: "1",
    }
  ) {
    const { refresh, res_type, limit, s, user_id, scope } = params;
    const newParams: ISpaceSearchParams = Object.assign({}, params, {
      res_type: res_type || "3",
      limit: limit || FEED_LIMIT,
      page_token: refresh ? "" : state.searchFeed.pageToken,
      s: s || "",
      user_id,
      scope,
    });

    delete newParams.refresh;
    console.log("spaceFeedSearch store >>>", newParams);

    return api
      .userFeedSearch(newParams)
      .then((res: ISearchFeedResult) => {
        commit("SET_SEARCH_FEED_LIST", {
          refresh,
          list: res.data,
          pageToken: res.next_page_token,
          hasMore: res.has_more,
          total: res.total,
        });
        return res;
      })
      .catch((e) => {
        console.log("ACTION spaceFeedSearch error >>>", e);
      });
  },
  createFeed({ commit, state }, params: IFeedCreateParams) {
    return api
      .createFeed(params)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  },
  modifyFeed({ commit, state }, params: IFeedCreateParams) {
    return api
      .modifyFeed(params)
      .then((res) => {
        return {
          ...res,
          id: params.id,
        };
      })
      .catch((e) => {
        return e;
      });
  },
  deleteFeed({ commit, state }, params: IFeedDeleteParams) {
    return api
      .deleteFeed(params)
      .then(() => {
        commit("DELETE_USER_FEED", { id: params.id });
        return "ok";
      })
      .catch((e) => {});
  },
  fetchFeedCategory({ commit, state }) {
    return api
      .fetchFeedCategory()
      .then((res: IFeedCategory) => {
        commit("SET_FEED_CATEGORY", res.data);
        return res;
      })
      .catch((e) => {});
  },
  fetchFeedLabel({ commit, state }, params: IFeedLabelParams) {
    return api
      .fetchFeedLabel(params)
      .then((res: IFeedLabelResult) => {
        commit("SET_FEED_LABEL", res.data);
        return res;
      })
      .catch((e) => {});
  },
  fetchFeedChannel({ commit, state }, params) {
    return api
      .fetchFeedChannel(params)
      .then((res: IFeedChannel) => {
        commit("SET_FEED_CHANNEL", res.data);
        return res;
      })
      .catch((e) => {});
  },
  createFeedChannel({ commit, state }, params: IFeedChannelCreateParams) {
    return api.createFeedChannel(params).then((res: any) => {
      commit("ADD_FEED_CHANNEL", {
        id: res.id,
        ...params,
      });
      return res;
    });
  },
  deleteFeedChannel({ commit, state }, params: IFeedChannelDeleteParams) {
    return api
      .deleteFeedChannel(params)
      .then((res: any) => {
        commit("DELETE_FEED_CHANNEL", params.id);
        return "ok";
      })
      .catch((e) => {});
  },
  batchModifyFeedChannel(
    { commit, state },
    params: { data: IFeedChannelModifyParams[] }
  ) {
    return api.batchModifyFeedChannel(params).then((res: any) => {
      return res;
    });
  },
  fetchUserFavorite(
    { commit, state },
    params: IUserFavoriteParams = { refresh: false, limit: FEED_LIMIT }
  ) {
    const { refresh, limit } = params;
    const newParams: IUserFavoriteParams = Object.assign({}, params, {
      limit: limit || FEED_LIMIT,
      page_token: refresh ? "" : state.userFavorite.pageToken,
    });

    delete newParams.refresh;
    console.log("fetchUserFavorite store >>>", newParams);

    return api
      .fetchUserFavoriteList(newParams)
      .then((res: any): any => {
        commit("SET_USER_FAVORITE_LIST", {
          refresh,
          list: res.data,
          pageToken: res.next_page_token,
          hasMore: res.has_more,
          total: res.total,
        });
        return res;
      })
      .catch((e) => {
        // console.log('---error----', e)
      });
  },
  favoriteFeed({ commit, state }, params: IFavoriteParams) {
    return api
      .favoriteFeed(params)
      .then((res: any) => {
        if (state.userFeed.list.length) {
          commit("CHANGE_USER_FEED_STATUS", {
            id: params.resource_id,
            favor: { is_favor: true },
          });
        }

        if (state.userFavorite.list.length) {
          commit("CHANGE_USER_FAVORITE", {
            id: params.resource_id,
            favor: { is_favor: true },
          });
        }

        if (state.feedInfo.id) {
          commit("SET_FEED_INFO", {
            ...state.feedInfo,
            favor: {
              ...state.feedInfo.favor,
              is_favor: true,
            },
          });
        }

        if (state.contentSearchFeed.list.length) {
          commit("CHANGE_SEARCH_FEED_FAVORITE", {
            id: params.resource_id,
            favor: true,
          });
        }

        if (state.zoneLabelResource.list.length) {
          commit("CHANGE_ZONE_RESOURCE_FAVORITE", {
            id: params.resource_id,
            favor: true,
          });
        }

        return res;
      })
      .catch((e) => {
        if (e.error && e.error === "already_exists") {
          if (state.contentSearchFeed.list.length) {
            commit("CHANGE_SEARCH_FEED_FAVORITE", {
              id: params.resource_id,
              favor: true,
            });
          }

          if (state.zoneLabelResource.list.length) {
            commit("CHANGE_ZONE_RESOURCE_FAVORITE", {
              id: params.resource_id,
              favor: true,
            });
          }
        }
        throw e;
      });
  },
  cancelFavoriteFeed({ commit, state }, params: IFavoriteParams) {
    return api
      .cancelFavoriteFeed(params)
      .then((res: any) => {
        // 产品策略暂时不及时删除卡片，主动刷新触发
        // commit("DELETE_USER_FAVORITE", { id: params.rid });
        if (state.userFeed.list.length) {
          commit("CHANGE_USER_FEED_STATUS", {
            id: params.rid,
            favor: { is_favor: false },
          });
        }

        if (state.userFavorite.list.length) {
          commit("CHANGE_USER_FAVORITE", {
            id: params.rid,
            favor: { is_favor: false },
          });
        }

        if (state.feedInfo.id) {
          commit("SET_FEED_INFO", {
            ...state.feedInfo,
            favor: {
              ...state.feedInfo.favor,
              is_favor: false,
            },
          });
        }

        if (state.contentSearchFeed.list.length) {
          commit("CHANGE_SEARCH_FEED_FAVORITE", {
            id: params.rid,
            favor: false,
          });
        }

        if (state.zoneLabelResource.list.length) {
          commit("CHANGE_ZONE_RESOURCE_FAVORITE", {
            id: params.rid,
            favor: false,
          });
        }

        return res;
      })
      .catch((e) => {
        return e;
      });
  },
  fetchProfileFeed({ commit, state }) {
    return api
      .fetchProfileFeed()
      .then((res: IProfileFeedResult) => {
        commit("SET_PROFILE_FEED", res.data);
        return res;
      })
      .catch((e) => {});
  },
  modifyProfile({ commit, state }, params: IProfileParams) {
    return api.modifyProfile(params).then((res: any) => {
      return res;
    });
  },
  reportUserDownload({ commit, state }, params: IReportDownloadParams) {
    return api
      .reportUserDownload(params)
      .then((res: any) => {
        return res;
      })
      .catch((e: any) => {});
  },
  fetchUserMessage(
    { commit, state },
    params: IUserMessageParams = { refresh: false, limit: MESSAGE_LIMIT }
  ) {
    const { refresh, limit } = params;
    const newParams: IUserMessageParams = Object.assign({}, params, {
      limit: limit || MESSAGE_LIMIT,
      page_token: refresh ? "" : state.userMessage.pageToken,
    });

    delete newParams.refresh;
    console.log("fetchUserMessage store >>>", newParams);

    return api
      .fetchUserMessage(newParams)
      .then((res: any): any => {
        commit("SET_USER_MESSAGE", {
          refresh,
          list: res.data,
          pageToken: res.next_page_token,
          hasMore: res.has_more,
        });
        return res;
      })
      .catch((e) => {
        // console.log('---error----', e)
      });
  },
  fetchUserDailyStat({ commit, state }, params: IDailyStatParams) {
    return api
      .fetchDailyStat(params)
      .then((res: any) => {
        commit("SET_USER_DAILY_STAT", res);
        return res;
      })
      .catch((e: any) => {});
  },
  readDailyStat({ commit, state }, params: IDailyStatBody) {
    return api
      .readDailyStat(params)
      .then((res: any) => {
        return res;
      })
      .catch((e: any) => {});
  },
  checkTextSensitive({ commit, state }, params: ITextSensitiveParams) {
    return api.checkTextSensitive(params).then((res: any) => {
      return res;
    });
  },
  fetchFeedComment(
    { commit, state },
    params: ICommentParams = {
      refresh: false,
      limit: FEED_COMMENT_LIMIT,
      reply_cid: "",
    }
  ) {
    const { tid, refresh, limit, reply_cid } = params;
    const newParams: ICommentParams = Object.assign({}, params, {
      tid,
      reply_cid,
      limit: limit || FEED_COMMENT_LIMIT,
      page_token: refresh ? "" : state.feedComment.pageToken,
    });

    delete newParams.refresh;
    console.log("fetchFeedComment store >>>", newParams);

    return api
      .fetchFeedComment(newParams)
      .then((res: any): any => {
        commit("SET_FEED_COMMENTS", {
          refresh,
          list: res.data,
          pageToken: res.next_page_token,
          hasMore: res.has_more,
          total: res.total,
          users: res.users,
          praises: res.is_praises,
        });
        return res;
      })
      .catch((e) => {});
  },
  createFeedComment({ commit, state }, params: ICommentCreateParams) {
    return api.createFeedComment(params).then((res) => {
      return res;
    });
  },
  deleteFeedComment({ commit, state }, params: ICommentActionParams) {
    return api.deleteFeedComment(params).then((res: any) => {
      commit("DELETE_FEED_COMMENT", {
        id: params.comment_id,
        level: params.level,
        cid: params.cid,
      });
      return "ok";
    });
  },
  likeFeedComment({ commit, state }, params: ICommentActionParams) {
    return api.likeFeedComment(params).then((res: any) => {
      commit("LIKE_FEED_COMMENT", {
        id: params.comment_id,
        count: 1,
        cid: params.cid,
        level: params.level,
      });
      return res;
    });
  },
  unlikeFeedComment({ commit, state }, params: ICommentActionParams) {
    return api.unlikeFeedComment(params).then((res: any) => {
      commit("LIKE_FEED_COMMENT", {
        id: params.comment_id,
        count: -1,
        cid: params.cid,
        level: params.level,
      });
      return res;
    });
  },
  fetchFeedSubComment(
    { commit, state },
    params: ICommentParams = {
      refresh: false,
      limit: FEED_COMMENT_LIMIT,
      reply_cid: "",
      page_token: "1",
    }
  ) {
    const { tid, limit, reply_cid, page_token } = params;
    const newParams: ICommentParams = Object.assign({}, params, {
      tid,
      reply_cid,
      limit: limit || FEED_COMMENT_LIMIT,
      page_token,
    });

    return api
      .fetchFeedComment(newParams)
      .then((res: any): any => {
        commit("SET_FEED_SUB_COMMENTS", {
          cid: reply_cid,
          list: res,
        });

        return res;
      })
      .catch((e) => {});
  },
  fetchZoneList({ commit, state }, params: IZoneListParams) {
    const { type = 2, limit = 20, page_token = "" } = params;
    const newParams: IZoneListParams = Object.assign({}, params, {
      type,
      limit,
      page_token,
    });

    return api
      .fetchZoneList(newParams)
      .then((res: IZoneListResponse) => {
        commit("SET_ZONE_LIST", res.data);
        return res;
      })
      .catch((e) => {
        console.log("fetchZoneList error >>>", e);
      });
  },
  fetchZoneResource({ commit, state }, params: IZoneResourceParams) {
    const {
      refresh = false,
      limit = ZONE_RESOURCE_LIMIT,
      res_limit = 12,
    } = params;
    const newParams: IZoneResourceParams = Object.assign({}, params, {
      res_limit,
      limit,
      page_token: refresh ? "" : state.zoneHomeResource.pageToken,
    });

    delete newParams.refresh;

    return api
      .fetchZoneResource(newParams)
      .then((res: IZoneResourceResponse) => {
        commit("SET_ZONE_HOME_RESOURCE", {
          list: {
            data: res.data,
            resources: res.resources,
            labels: res.labels,
          },
          pageToken: res.next_page_token,
          hasMore: res.has_more,
          total: res.total,
        });

        return res;
      })
      .catch((e) => {
        commit("SET_ZONE_HOME_RESOURCE", {
          list: {
            data: [],
            resources: {},
            labels: {},
          },
          pageToken: "",
          hasMore: false,
          total: 0,
        });
      });
  },
  fetchZoneInfo({ commit, state }, params: IZoneInfoParams) {
    const newParams = {
      id: params.id,
    };
    return api
      .fetchZoneInfo(newParams)
      .then((res: IZoneInfoResult) => {
        commit("SET_ZONE_INFO", res);
        return res;
      })
      .catch((e) => {});
  },
  fetchZoneLabelResource({ commit, state }, params: IZoneLabelResourceParams) {
    const {
      refresh = false,
      limit = ZONE_RESOURCE_LIMIT,
      class_id = "",
    } = params;

    const newParams: IZoneLabelResourceParams = Object.assign({}, params, {
      limit,
      page_token: refresh ? "" : state.zoneLabelResource.pageToken,
      class_id,
    });

    delete newParams.refresh;

    return api
      .fetchZoneLabelResource(newParams)
      .then((res: IZoneResourceResponse) => {
        commit("SET_ZONE_LABEL_RESOURCE", {
          refresh,
          list: res.data,
          pageToken: res.next_page_token,
          hasMore: res.has_more,
          total: res.total,
        });

        return res;
      })
      .catch((e) => {
        commit("SET_ZONE_LABEL_RESOURCE", {
          refresh,
          list: [],
          pageToken: "",
          hasMore: false,
          total: 0,
        });
      });
  },
  fetchThirdPartyJumpUrl({ commit, state }, params: IThirdPartyUrlParams) {
    return api
      .fetchThirdPartyJumpUrl(params)
      .then((res: IThirdPartyUrlResult) => {
        return res;
      })
      .catch((e) => {
        return {
          url: "",
        };
      });
  },
  fetchHomeBanner({ commit, state }, params: IHomeBannerParams) {
    return api
      .fetchHomeBanner(params)
      .then((res: IHomeBannerResult) => {
        commit("SET_HOME_BANNER", res.data);
        return res;
      })
      .catch((e) => {
        return [];
      });
  },
  fetchHotword({ commit, state }, params: IHotwordParams) {
    return api
      .fetchHotword(params)
      .then((res: IHotwordResult) => {
        commit("SET_HOTWORD", res.data);
        return res;
      })
      .catch((e) => {
        return [];
      });
  },
  fetchHomeTopic({ commit, state }, params: IHomeTopicParams) {
    return api
      .fetchHomeTopic(params)
      .then((res: IHomeTopicResult) => {
        commit("SET_HOME_TOPIC", res.data);
        return res;
      })
      .catch((e) => {
        return [];
      });
  },
};
