import sget from '@xunlei/sget'
import xl9 from '@/utils/xl9Promise'
import xlmPromise from '@/utils/xlmPromise'
import { getCookie } from '@/utils/util'
import { raven } from '@xunlei/vue-raven-plugin'

const config = {
  pcWeb: {
    async showLoginDlg () {
      try {
        return xlQuickLogin.popup()
      } catch (e) {
        this.$raven.error(e)
      }
    }
  },
  mac: {
    showLoginDlg () {
      return xlmPromise.call('showLoginWindow')
    }
  },
  xl9: {
    showLoginDlg (from) {
      return xl9.promiseApi('ShowLoginDlg', from)
    }
  },
  mobileWeb: {
    router: '',
    async init ({ router }) {
      this.router = router
      try {
        // http://xluser.gitlab.xunlei.cn/login/web_sdk/
        xlQuickLogin.init({
          LOGIN_ID: '165',
          REGISTER_ID: '165',
          loginSuccessFunc () {},
          logoutFunc () {},
          LOGIN_SUCCESS_URL: window.location.origin + '/mobileLogin',
          UI_THEME: 'default',
          DEFUALT_UI: 'login',
          LOGIN_TYPES: ['account', 'mobile'],
          SET_ROOT_DOMAIN: true,
          THIRD_LOGIN_TARGET_PARENT: true // 当点击三方登录图标的时候是在当前页面打开
        })
      } catch (e) {
        raven.error(e)
      }
    },
    async showLoginDlg () {
      const redirect = this.router.currentRoute.fullPath
      this.router.push('/mobileLogin')
      window.localStorage.setItem('loginRedirect', redirect)
    }
  }
}

const getPlatform = () => {
  if (process.server) {
    return 'server'
  } else if (sget(window, 'native', 'CallNativeFunction')) {
    return 'xl9'
  } else if ('WebViewJavascriptBridge' in window) {
    return 'mac'
  } else if (/Mobi|Android/i.test(navigator.userAgent)) {
    return 'mobileWeb'
  } else {
    return 'pcWeb'
  }
}

const platform = getPlatform()
const platformApi = config[platform]

const defaultApi = {
  async init () {
    try {
      // http:// xluser.gitlab.xunlei.cn/login/web_sdk/
      xlQuickLogin.init({
        LOGIN_ID: '165',
        REGISTER_ID: '165',
        loginSuccessFunc () {}, // 登录时不重定向
        logoutFunc () { }, // 登出时不重定向
        UI_THEME: 'default',
        UI_TYPE: 'popup',
        SET_ROOT_DOMAIN: true,
        IS_SYNC_MAC: true,
        DEFUALT_BACKGROUND: '' // 空背景图
        // POPUP_CLOSE_FUNC () { window.close() }
      })
    } catch (e) {}
  },
  onUserLogin (callback) {
    try {
      return xlQuickLogin.setLoginExt(callback)
    } catch (e) {
      raven.error(e)
    }
  },
  onUserLogout (callback) {
    try {
      return xlQuickLogin.setLogoutExt(callback)
    } catch (e) {
      raven.error(e)
    }
  },
  offUserLogin (eventId) {
    try {
      return xlQuickLogin.setLoginExt(function () { }, eventId)
    } catch (e) {
      raven.error(e)
    }
  },
  offUserLogout (eventId) {
    try {
      return xlQuickLogin.setLogoutExt(function () { }, eventId)
    } catch (e) {
      raven.error(e)
    }
  }
}

let lastLoginEventId

const commonApi = {
  platform,
  isLogined () {
    const userid = getCookie('userid')
    const sessionId = getCookie('sessionid')

    return userid && userid !== '0' && sessionId
  },
  async login (from = '') {
    this.offUserLogin(lastLoginEventId) // 去除：之前记录的回调方法

    await this.showLoginDlg(from)

    return new Promise((resolve) => {
      lastLoginEventId = this.onceUserLogin(() => {  // 等待登录成功，如果多次打开登录弹窗且关闭，则最后登录成功时候会一起返回登录成功
        resolve(getCookie('userid'))
      })
    })
  },
  onceUserLogin (callback) {
    if (typeof callback !== 'function') return
    const eventId = this.onUserLogin(() => {
      callback()
      this.offUserLogin(eventId)
    })

    return eventId
  },
  onceUserLogout (callback) {
    if (typeof callback !== 'function') return
    const eventId = this.onUserLogout(() => {
      callback()
      this.offUserLogout(eventId)
    })
  }
}
const account = Object.assign(defaultApi, platformApi, commonApi)
export default account
