import store from "store";
import sso from "@/utils/sso";
import loginIframeFunc from "@/utils/login-iframe";
import { deleteCookie, isMacThunder } from "@/utils/util";
import { getSsoUser } from "@/api/user";
import { VipRawInfo } from "@/types/user";

export default {
  data() {
    return {
      tryLogin: 0,
    };
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    showLoginIframe() {
      return this.$store.state.user.showLoginIframe;
    },
  },
  mounted() {
    if (process.client && isMacThunder()) {
      window.WebViewJavascriptBridge.addEventListener(
        "userChange",
        ({ state }: { [key: string]: number }) => {
          // 0: logout-success 1: login-success
          if (state === 0 || state === 2) {
            window.location.reload();
          }
        }
      );
    }
  },
  methods: {
    stat(action: string, data: any): void {
      this.$stat("xiaozhan", action, data);
    },
    async jumpLogin(): Promise<void> {
      try {
        await (await sso).signIn();
        this.$store.commit("SET_ERROR_TYPE", "");
      } catch (error) {
        console.log("handleLogin error >>>", error);
        if (typeof error === "object" && error) {
          // 第三方站点嵌入小站，无痕窗口模式，若三方cookie被用户阻止，则sso流程无法走完
          const name = (error as any).name;
          if (name === "SecurityError") {
            this.$store.commit("SET_ERROR_TYPE", name);
          }
        }
      }
    },
    async popLogin(from = "collect_btn"): Promise<boolean> {
      if (process.client) {
        const {
          isAndroidNative,
          isIOSNative,
          sendMessage,
        } = require("@xunlei/universal-native-api");

        if (isAndroidNative) {
          const ret = await sendMessage(
            {
              platform: "android",
              name: "xlGetUserInfo",
              params: { forceLogin: 1 },
            },
            { callbackTimeout: 600000 }
          );

          if (ret.isLogin) {
            const user = ret.userInfo;

            const newUserData = {
              userId: user.userID,
              sessionId: user.sessionID,
              portrait_url: user.avatarURL,
              nick_name: user.nickName,
              vipData: {}, // TODO: 如何获取VIP信息
              xlVipData: {},
            };

            this.$store.commit("user/SET_CURRENT_USER", newUserData);
          }

          return false;
        }

        if (isIOSNative) {
          // TODO: iOS默认登录，暂时不无需兼容
          return false;
        }
      }

      if (process.client && window.native) {
        console.log("login button clicked popLogin client");
        const xlpc = require("@/utils/xl-pc").default;

        try {
          const isNativeLogined = await xlpc.checkIsLogined();
          console.log("popLogin isNativeLogined >>>", isNativeLogined);
          if (isNativeLogined) {
            window.location.reload();
            return false;
          }
        } catch (e) {
          console.log("sync client login status error: ", e);
        }

        const dialog = await xlpc.showLoginDialog();
        window.native.AttachNativeEvent(
          "OnUserLogin",
          async (userid: string) => {
            console.log("OnUserLogin", userid);
            try {
              await (await sso).signIn();
            } catch (e) {
              console.log("call-pc-client-login-error: ", e);
            }
          }
        );

        return false;
      }

      if (process.client && isMacThunder()) {
        try {
          const { macApi } = require("@xunlei/universal-native-api");
          const { sessionId } = await macApi.call("getUserInfo");
          if (sessionId) {
            window.location.reload();
            return false;
          }

          await macApi.call("showLoginWindow");
          return false;
        } catch (error) {
          console.error("call-mac-client-login-error: ", error);
        }
      }

      this.stat("xiaozhan_login_pop_show", {
        from,
      });

      if (this.showLoginIframe === 0) {
        this.$store.commit("user/SET_LOGIN_IFRAME", 1);
        loginIframeFunc({
          name: "header",
          from,
        });
      } else {
        this.$store.commit("user/SET_LOGIN_IFRAME", 1);
      }

      return true;
    },
    async logout(): Promise<void> {
      await (await sso).signOut(window.location.href);
      store.remove("save_success_dialog_visible");
      deleteCookie("cur_userid_xz");
    },
    async getUserInfo() {
      try {
        const user = await getSsoUser();
        console.log("sso user info >>>", user);
        const vipData = {
          vipType: 0,
          isVip: false,
          vipLevel: 0,
          expiredAt: "",
        };

        if (user.vips && user.vips.length) {
          const xzVipInfo = user.vips.filter((item: VipRawInfo) => {
            return item.id && item.id.indexOf("vip201") > -1;
          });

          if (xzVipInfo.length) {
            const vids = xzVipInfo[0].id.split("_");
            vipData.expiredAt = xzVipInfo[0].expires_at;
            vipData.isVip = Number(vids[1]) === 1;
            vipData.vipLevel = vids[2];
            vipData.vipType = vids[3];
          }
        }

        const newUserData = {
          userId: user.id,
          portrait_url: user.picture,
          nick_name: user.name,
          vipData,
        };

        this.$store.commit("user/SET_CURRENT_USER", newUserData);
      } catch (e) {
        console.log("getUserInfo failed >>>", e);
      }
    },
  },
};
