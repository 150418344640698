import config, { serverClientConfig } from "@/xbase-sdk.config";
import sso from "@/utils/sso";
import axios from "axios";
import { getPubKeySign } from "@/api/get-pub-key-sign";
import getUserInfo from "@/utils/get-user-info";
import pkg from "../package.json";
import { Captcha, CaptchaRequestOptions } from "@xbase/captcha";
import { CaptchaOptions } from "@xbase/captcha/dist/types/captcha-interface";
import genDeviceInfo from "@/utils/gen-device-info";
import { isMacThunder } from "@/utils/util";

let deviceId = "";

export const captchaInstance = process.client
  ? (async () => {
      const apiOrigins = config.apiOrigins;
      const ssoInstance = await sso;
      const baseRequest: any =
        process.client && !window.XLAccountJsBridge
          ? ssoInstance.request.bind(ssoInstance)
          : null;

      const captchaOptions: CaptchaOptions = {
        apiOrigins,
        clientId: config.clientId,
        baseRequest,
        originDeviceId: "",
      };

      if (isMacThunder()) {
        const { macApi } = require("@xunlei/universal-native-api");
        const { peerId } = await macApi.call("getPlatformInfo", {
          keys: ["peerId"],
        });
        deviceId = peerId;
      } else {
        const deviceInfo = await genDeviceInfo();
        deviceId = deviceInfo.deviceID;
      }

      console.log("captcha deviceId >>>", deviceId);

      captchaOptions.originDeviceId = deviceId;

      console.log("🔥 new captcha config >>>", captchaOptions);

      return new Captcha(captchaOptions);
    })()
  : sso;

const Request = async (url: string, option: any) => {
  const method = option.method || "GET";

  if (process.env.VUE_ENV === "server") {
    // TODO: 传递更多参数，辅助服务端排查异常
    const headers = {
      "X-Client-Id": serverClientConfig.clientId,
      ...option.headers,
    };

    const optionConfig: any = {
      method,
      url,
      headers,
    };

    if (option.body) {
      optionConfig.data = option.body;
    }

    console.log("ssr request options >>>", optionConfig);

    const reqBeginTime = process.uptime();
    return axios(optionConfig)
      .then((res: any) => {
        // console.log("ssr request result >>>", JSON.stringify(res.data));
        const reqEndTime = process.uptime();
        console.log(
          `ssr request tasks ${(reqEndTime - reqBeginTime).toFixed(2)}S`
        );
        return res.data;
      })
      .catch((err: any) => {
        // console.log("ssr request error >>>", err.response);
        if (err.response) {
          // TODO: 上报
          throw err.response.data;
        } else {
          console.log("ssr request Error >>>", err.message);
          throw "ssr request error";
        }
      });
  }

  const timestamp = String(Date.now());
  const sign = await getPubKeySign(timestamp);
  const [peerId, userId = 0] = await getUserInfo();

  const withCaptchaMeta = {
    package_name: config.packageName,
    client_version: pkg.version,
    captcha_sign: sign,
    timestamp,
    user_id: userId,
  };

  let withCaptcha = true;
  if (option.withCaptcha !== undefined) {
    withCaptcha = option.withCaptcha;
  }

  const opt: CaptchaRequestOptions = {
    ...option,
    withCaptchaMeta,
    withCaptcha,
  };

  opt.headers["x-device-id"] = deviceId;
  opt.headers["x-client-id"] = config.clientId;

  return new Promise(async (resolve, reject) => {
    (await (captchaInstance as any))
      .request(url, opt)
      .then((res: any) => {
        resolve(res);
      })
      .catch(async (err: any) => {
        if (isMacThunder() && err.error_code === 16) {
          (await sso).signIn();
        }

        if (err.error === "unauthenticated" && opt.withCredentials) {
          // return location.reload()
        }
        reject(err);
      });
  });
};

export default Request;

        export function pubzk4eBq3j2NaCYlQRtsGzygKhyv9MlNhJ (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/error-message.ts').pubzlhp3Oyg4jThLc7aiCaibANYFZH9IXoU;
            const nextPart2 = require('@/utils/filters.ts').pub5ZMlwMeT5yoOrFLMx7GBwam01puFZYnS;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubELnHU9eDrr3M80p39SC2xDhdzqKlKNYj (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'VMOwOzHIN4bKLP6KILEgM', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/captcha.ts').pubTEJElOepGQYFf4L8wu1X6clzqYMuWEre;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubTEJElOepGQYFf4L8wu1X6clzqYMuWEre (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: 'Wpzd1I6L2wG1WcRtOeoAKlCRc6uZft5f3w4NOygLLBN', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/sso.ts').pubuZTmb82z7TKX1nvz9PJtqq0xSkkQl0wR;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubESB2K4NhoJyni8adUYsndLSnGa9OW42Y (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'md5', salt: '731653X2Ec', end: 'undefined' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/util.ts').pubXcwbjcOrUFxgBmg9takCTgrCooZv1I2n;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubOiLtmUCxvUlp3QkIUBf5vcPz9NKUoKCW (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart = require('@/utils/stat.ts').pubKCh8RupHynl6lTfEJdg9gtLTD0r8GmGw;
            return nextPart(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return '';
          }
        }
        export function pubIg55eCoJhxzsdcv72pWCJ8nGs7CJhjTU (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/login-iframe.ts').pubULFnWUHNa4ZaubJhTysv0z0yzu0xP9zN;
            const nextPart2 = require('@/utils/xl-client-common.ts').pub8FVbpAoSbOsJCpuRTLenyvfvkf2h69rK;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubEECZtx9JdiNb2U42JzpJ3CsnAhRRMhuB (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/sso.ts').pubiOJIRTCFVxhjwI2CUp5Yn6XmBhuNJUTB;
            const nextPart2 = require('@/utils/stat.ts').pub84YSMMivayZQFKdcYzz6UwcahTlqSl9B;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubp8bRv0vhBl0mmjVy7IPesDxBz5mlKoBg (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/login-iframe.ts').pubMOz4MVEFwAU6BL2HBxzkYuf8phKI09A5;
            const nextPart2 = require('@/utils/stat.ts').pubhNKvaXq9MQfct9D9jwLfg9Ve01aBhhhs;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }
        export function pubMGODAGFRmZb35MCA3thyR0ty2iB15Tbd (md5Str: any) {
          const md5 = require('blueimp-md5');
          const pubKeyInfo = { alg: 'undefined', salt: 'undefined', end: 'end' };
          if (pubKeyInfo.end !== 'end') {
            const nextPart1 = require('@/utils/login-iframe.ts').pubCEbq3VIKuKXySOLYNG1xOGZt8RdsbO1B;
            const nextPart2 = require('@/utils/drive-util.ts').pubRiS9ShbU2LGLESJy6567ggat3kFk0uNf;
            return nextPart1(md5(md5Str+ pubKeyInfo.salt)) || nextPart2(md5(md5Str+ pubKeyInfo.salt));
          } else {
            return md5Str;
          }
        }