import md5 from "blueimp-md5";
import xbaseConfig from "@/xbase-sdk.config";
import getPubKey from "@/api/get-pub-key.esm";
import pkg from "@/package";
import genDeviceInfo from "@/utils/gen-device-info";
import { isMacThunder } from "@/utils/util";

let algorithms = [];

export async function getPubKeySign(timestamp) {
  try {
    const deviceInfo = await genDeviceInfo();
    let deviceid = await deviceInfo.deviceID;
    if (isMacThunder()) {
      const { macApi } = require("@xunlei/universal-native-api");
      const { peerId } = await macApi.call("getPlatformInfo", {
        keys: ["peerId"],
      });
      deviceid = peerId;
    }

    // init: captchaSign = client_id + client_version + package_name + device_id + timestamp (timestamp精确到毫秒)
    let captchaSign = `${xbaseConfig.clientId}${pkg.version}${location.host}${deviceid}${timestamp}`;
    if (
      !["BEGIN_FUN_FILE_PAN", "BEGIN_FUN_FILE_XLPAN"].includes(
        xbaseConfig.funFile
      )
    ) {
      // TODO: 确认
      const getPubKeySignUtil = (
        await import("@/utils/" + xbaseConfig.funFile)
      )[xbaseConfig.funName];
      captchaSign = getPubKeySignUtil(captchaSign);
    } else {
      if (!algorithms.length) {
        const pubKeyInfo = await getPubKey();
        algorithms = pubKeyInfo.algorithms;
      }
      algorithms.forEach((item) => {
        captchaSign = md5(`${captchaSign}${item.salt}`);
      });
    }
    captchaSign = `${xbaseConfig.algVersion}.${captchaSign}`;
    return captchaSign;
  } catch (error) {
    console.log(error);
  }
}
