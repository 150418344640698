













































































































import Vue from "vue";
import qs from "qs";
import eventEmitter from "@/utils/eventEmitter";
import Loading from "@/components/Loading.vue";
import { IScanStatusResult } from "@/types/vip";
import api from "@/api/vip";
import { genSpanid, isPcPlatform, getSearch } from "@/utils/util";
import { getClientMetaInfo } from "@/utils/xl-client";
import eventMap from "@/utils/event-name";
import { parseTime } from "@/utils/filters";
import config from "@/config";
import user from "@/api/user";
import ssoMixin from "@/mixins/sso";
import PayResult from "@/components/vip/PayResult.vue";
import { IVipPrice } from "@/types/vip";
import { IUserGoodsItem } from "@/types/activity";

export default Vue.extend({
  name: "vip-pay",
  filters: {
    parseTime(time: string) {
      return parseTime(time, "{y}-{m}-{d}");
    },
    displayUnit(val: string) {
      if (val === "12") {
        return "年";
      }

      return "月";
    },
  },
  mixins: [ssoMixin],
  components: { Loading, PayResult },
  props: {
    showAction: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      vipTypes: [] as { price: IVipPrice }[],
      paySuccess: false,
      activeVipType: "",
      activeVipInfo: {
        price: {} as IVipPrice,
      },
      loading: false,
      qrcodeStatus: "active",
      qrcodeStatusText: "",
      qrcodeActionText: "刷新",
      scanned: false,
      payWay: [
        {
          icon: "z-icon-wechat-pay",
          title: "微信支付",
        },
        {
          icon: "z-icon-alipay",
          title: "支付宝支付",
        },
      ],
      configLoadError: false,
      configLoading: true,
      qrcodeDuration: 600 * 1000,
      startTime: 0,
      endTime: 0,
    };
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    isVip() {
      return this.curUser.vipData && this.curUser.vipData.isVip;
    },
    payParams() {
      return this.$store.state.vip.payParams;
    },
    qrcodeRes() {
      return this.$store.state.vip.qrcodeRes;
    },
    qrcodeUrl() {
      return this.qrcodeRes.qrcode || "";
    },
    xlVipInfo() {
      return this.curUser.xlVipData;
    },
    isXlVip() {
      return this.xlVipInfo && this.xlVipInfo.isVip;
    },
    xlVipType() {
      if (this.xlVipInfo) {
        return this.xlVipInfo.vipType;
      }
      return 0;
    },
    activityList() {
      return this.$store.state.activity.list;
    },
    goods() {
      let newVipTypes = this.vipTypes.filter(
        (vt: { price: IVipPrice; pid: string }) => {
          return vt.pid === config.vip.pid;
        }
      );

      try {
        const item = this.activityList[0];
        const userGoodsMap = {};
        if (item) {
          const goodsMapArr = JSON.parse(item.good_map);

          goodsMapArr.map((good: IUserGoodsItem) => {
            if (!userGoodsMap[good.vip_type]) {
              userGoodsMap[good.vip_type] = [];
              userGoodsMap[good.vip_type].push(good.goods_key);
            } else {
              userGoodsMap[good.vip_type].push(good.goods_key);
            }
          });
        }

        // TODO: 有效期内针对迅雷VIP用户
        if (this.isXlVip) {
          const xlVipType = this.xlVipType;
          const goodsIds = userGoodsMap[xlVipType];
          const currentTime = new Date().getTime();
          const startTime = +item.start_time;
          const endTime = +item.end_time;
          const valid = currentTime >= startTime && currentTime <= endTime;

          if (goodsIds && valid) {
            newVipTypes = this.vipTypes.filter((vt: { price: IVipPrice }) => {
              return goodsIds.includes(vt.price.key);
            });
          }
        }
      } catch (e) {}
      this.activeVipType = newVipTypes[0].price.key;
      this.activeVipInfo = newVipTypes[0];
      return newVipTypes;
    },
  },
  watch: {
    activeVipType(newVal, oldVal) {
      this.fetchQrcode();
    },
    endTime(newTime, oldTime) {
      if (newTime - this.startTime > this.qrcodeDuration) {
        this.clearScanTimer();

        this.qrcodeStatusText = "二维码已失效";
        this.qrcodeStatus = "error";
        this.qrcodeActionText = "刷新";
      }
    },
  },
  async mounted() {
    this.stat("xiaozhan_pc_pay_page_show", {
      ...this.payParams,
    });

    if (this.hasLogin) {
      await this.initPage();
      this.stat("xiaozhan_pc_pay_page_show_success", {
        ...this.payParams,
      });
    } else {
      // TODO: 跳转登录
    }
  },
  beforeDestroy() {
    this.clearScanTimer();
  },
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    async initPage() {
      try {
        this.configLoading = true;
        this.configLoadError = false;

        await api.fetchVipTypes();
        if (window.active_cfg && window.active_cfg.pay) {
          this.configLoading = false;
          const payInfo = window.active_cfg.pay;
          const productKeys = Object.keys(payInfo);
          let productOptions: any[] = [];
          productKeys.map((pid) => {
            const pidInfo = payInfo[pid];
            const newPidInfo = { ...pidInfo };
            const priceInfo = newPidInfo.price;

            const newInfoArray = Object.keys(priceInfo).map((k) => {
              const key = `${pid}_${k}`;
              const price = priceInfo[k];
              price.key = key;
              return { ...pidInfo, price, pid };
            });

            productOptions = [...productOptions, ...newInfoArray];
            return pid;
          });
          this.vipTypes = productOptions;
          this.activeVipType = this.vipTypes[0].price.key;
          this.activeVipInfo = this.vipTypes[0];
        } else {
          this.configLoadError = true;
          this.configLoading = false;
        }
      } catch (e) {
        this.configLoadError = true;
        this.configLoading = false;
        this.$raven.error(e);
      }
    },
    async fetchQrcode() {
      this.clearScanTimer();

      try {
        this.loading = true;
        this.qrcodeStatus = "";
        this.qrcodeStatusText = "";
        this.startTime = new Date().getTime();

        const { accessToken, peerid, sessionId, userId } = this.curUser;
        const newToken = await user.getAccessToken();

        let { aidfrom, referfrom } = this.payParams;
        if (!aidfrom) {
          aidfrom = this.activeVipInfo.aid;
        }

        if (!referfrom) {
          referfrom = this.activeVipInfo.referfrom;
        }
        const { aid_wap, referfrom_wap, activeId, biztype, cashlist } =
          this.activeVipInfo;

        const {
          productName,
          middlePayWapUrl,
          productId,
          productType,
          price,
          month,
        } = this.activeVipInfo.price;

        const num = Number(month);
        const rmb = Number(price) * 100;
        const actid = config.vip.actid;

        // TODO: 确认
        const fgUrl = "https://zhan.xunlei.com/vip/pay/result";
        const mref = "";
        const mAid = "";

        const clientMetaInfo = await getClientMetaInfo({});
        const version = clientMetaInfo.version;
        const ext2 = qs.stringify({
          actid,
          pid: this.activeVipInfo.pid,
          productName,
        });

        const params = {
          userid: userId,
          sessionid: newToken || accessToken || sessionId,
          productType,
          productId,
          rmb,
          num,
          ext2,
          Page_productName: productName,
          wapUrl: decodeURIComponent(middlePayWapUrl),
          aidfrom,
          referfrom,
          xHaobaoId: clientMetaInfo.peerId || peerid,
          xClientVersion: version,
          fgUrl: isPcPlatform() ? "" : fgUrl,
          mref,
          mAid,
          trace_id: getSearch("ui_traceid") || "",
          span_id: genSpanid(),
        };

        const result = await this.$store.dispatch("vip/genPayQrcode", params);
        if (result.ret !== 200) {
          if (result.ret === 401) {
            this.qrcodeStatusText = "登录态已失效";
            this.qrcodeStatus = "unauthorised";
            this.qrcodeActionText = "重新登录";
          } else {
            this.qrcodeStatusText = "二维码加载失败";
            this.qrcodeStatus = "error";
            this.qrcodeActionText = "刷新";
          }

          this.loading = false;
          return result;
        }

        this.loading = false;
        this.poolScanStatus();
        return result;
      } catch (e) {
        this.loading = false;
        this.qrcodeStatus = "error";
        this.qrcodeStatusText = "二维码加载失败";
        this.qrcodeActionText = "刷新";
        this.$raven.error(e);
      }
    },
    poolScanStatus() {
      this.clearScanTimer();

      // 轮询扫码结果
      const qrcodeId = this.qrcodeRes.qrcodeId;
      const params = {
        id: qrcodeId,
      };

      this.scanStatusTimerId = setInterval(async () => {
        this.endTime = new Date().getTime();

        try {
          const statusResult: IScanStatusResult = await this.$store.dispatch(
            "vip/checkQrcodeStatus",
            params
          );

          // 已扫码
          if (statusResult.ret === 200 && statusResult.data.isqrcode === true) {
            if (!this.scanned) {
              this.scanned = true;
              this.stat("xiaozhan_pc_pay_qrcode_scan", {
                ...this.payParams,
              });
            }
          }

          // 已支付
          if (
            statusResult.ret === 200 &&
            statusResult.data.issucc &&
            statusResult.data.orderid
          ) {
            this.clearScanTimer();
            this.checkOrderStatus({
              orderId: statusResult.data.orderid,
            });
          }
        } catch (e) {
          this.$raven.error(e);
        }
      }, 5000);
    },
    async checkOrderStatus(result: { orderId: string }) {
      // 查询订单支付状态
      const { userId, accessToken, sessionId } = this.curUser;
      const newToken = await user.getAccessToken();

      const params = {
        userid: userId,
        sessionid: newToken || accessToken || sessionId,
        orderId: result.orderId,
      };

      const payOrderRes = await this.$store.dispatch(
        "vip/checkOrderIdStatus",
        params
      );

      if (
        payOrderRes.ret === 200 &&
        payOrderRes.data &&
        payOrderRes.data.fpaysucc === 1
      ) {
        this.paySuccess = true;
        this.stat("xiaozhan_pc_pay_success_page_show", {
          ...this.payParams,
        });

        // 主动更新用户信息
        this.getUserInfo();

        // 通知三方合作方图怪兽支付结果
        (window as any).top.postMessage("pay-success", "https://818ps.com");

        (window as any).top.postMessage(
          "pay-success",
          "https://xunleie.818ps.com"
        );

        (window as any).top.postMessage(
          "pay-success",
          "https://xunleieue.818ps.com"
        );
      } else {
        this.$message({
          message: payOrderRes.msg || "查询支付结果异常",
          type: "warning",
        });
        // TODO: 支付失败结果
      }
    },
    refreshQrcode() {
      if (this.loading) {
        return;
      }

      if (this.qrcodeStatus === "unauthorised") {
        this.popLogin("vip");
        return;
      }

      this.stat("xiaozhan_pc_pay_qrcode_refresh", {
        ...this.payParams,
      });

      this.fetchQrcode();
    },
    changeVipType(item: { price: IVipPrice }, e: Event) {
      if (item.price.key === this.activeVipType) {
        return;
      }

      this.activeVipType = item.price.key;
      this.activeVipInfo = item;
    },
    handleKnowClick() {
      eventEmitter.emit(eventMap.PAY_SUCCESS_KNOW, 0, {});

      this.stat("xiaozhan_pc_pay_success_page_click", {
        ...this.payParams,
      });
    },
    clearScanTimer() {
      if (this.scanStatusTimerId) {
        clearInterval(this.scanStatusTimerId);
        this.scanStatusTimerId = null;
      }
    },
  },
});
