import { GetterTree, ActionTree, MutationTree } from "vuex";
import { RootState } from "@/store";
import api from "@/api/user";
import { VipInfo, User } from "@/types/user";
import {
  IAuthStatusParams,
  IAuthStatusResponse,
  IAuthCodeParams,
  IAuthBindPhoneParams,
} from "@/types/user";

export const state = () => ({
  name: "Me",
  showLoginIframe: 0,
  curUser: {
    userId: "0",
  },
  needPhoneAuth: false,
});

export type UserModuleState = ReturnType<typeof state>;

export const getters: GetterTree<UserModuleState, RootState> = {
  name: (state) => state.name,
};

export const mutations: MutationTree<UserModuleState> = {
  SET_CURRENT_USER: (state, newUser: User) => {
    if (!(newUser && newUser.userId)) {
      newUser.userId = "0";
    }
    Object.assign(newUser, { userId: String(newUser.userId) });
    state.curUser = Object.assign({}, state.curUser, newUser);
  },
  SET_LOGIN_IFRAME: (state, type: number) => {
    state.showLoginIframe = type;
  },
  SET_NEED_PHONE_AUTH: (state, isNeed: boolean) => {
    state.needPhoneAuth = isNeed;
  },
};

export const actions: ActionTree<UserModuleState, UserModuleState> = {
  nuxtServerInit({ commit }, { req }) {
    console.log(req.auth);
  },
  checkAuthStatus(
    { commit, state },
    params: IAuthStatusParams = { userid: "" }
  ) {
    return api
      .checkAuthStatus(params)
      .then((res: IAuthStatusResponse) => {
        commit("SET_NEED_PHONE_AUTH", res.result === "NOAUTH");
        return res;
      })
      .catch((e: any) => {
        return {};
      });
  },
  sendAuthCode({ commit, state }, params: IAuthCodeParams) {
    return api.sendAuthCode(params).then((res: any): any => {
      return res;
    });
  },
  bindAuthPhone({ commit, state }, params: IAuthBindPhoneParams) {
    return api.bindAuthPhone(params).then((res: any): any => {
      if (res.result === "OK") {
        commit("SET_NEED_PHONE_AUTH", false);
      }
      return res;
    });
  },
};
