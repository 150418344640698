



























































import Vue from "vue";
import store from "store";
import ssoMixin from "@/mixins/sso";
import { debounce, joinUrlQueryString } from "@/utils/util";
import eventEmitter from "@/utils/eventEmitter";
import eventMap from "@/utils/event-name";

export default Vue.extend({
  name: "search",
  mixins: [ssoMixin],
  props: {
    page: {
      type: String,
      default: "home",
    },
  },
  data() {
    return {
      focused: false,
      searchValue: "",
      clearable: false,
      visible: false,
      searchHistory: [],
      searchType: "typing",
    };
  },
  computed: {
    searchThinkResult() {
      return this.$store.state.post.searchThinkResult;
    },
    hotwords() {
      return this.$store.state.post.hotwords;
    },
    queryStr() {
      const queryObj = this.$route.query;
      const routeName = this.$route.name;
      const queryStr = joinUrlQueryString(queryObj, routeName);
      return queryStr;
    },
  },
  watch: {
    searchValue(newVal) {
      if (newVal) {
        this.clearable = true;
      } else {
        this.clearable = false;
        this.searchType = "typing";
      }
    },
  },
  mounted() {
    if (this.$route.name === "search-keyword") {
      this.searchValue = this.$route.params.keyword;
      this.clearable = true;
    }

    const rawKeywords = this.getSearchHistory();
    this.searchHistory = rawKeywords;

    setTimeout(() => {
      this.$store.dispatch("post/fetchHotword", {});
    }, 1000);
  },
  beforeDestroy() {},
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    getSearchHistory() {
      try {
        const storedKeywords = store.get("keyword_search_history");
        let rawKeywords: string[] = [];
        if (storedKeywords) {
          rawKeywords = JSON.parse(storedKeywords);
        }
        return rawKeywords;
      } catch (e) {
        console.log("set keyword failed", e);
        return [];
      }
    },
    clearSearchHistory() {
      store.remove("keyword_search_history");
      this.searchHistory = [];
    },
    thinkSearch() {
      // TODO: 联想搜索
      this.$store.dispatch("post/thinkSearch", { keyword: this.searchValue });
    },
    debounceHandler: debounce(function () {
      this.thinkSearch();
    }, 300),
    handleChange(e: Event) {
      const value = (e.target as HTMLInputElement).value;
      // this.debounceHandler();
    },
    resetSearchType() {
      this.searchType = "typing";
    },
    handleFocus() {
      this.focused = true;
      this.visible = true;
      this.stat("xiaozhan_search_seleted", {
        from: this.page,
      });
    },
    handleBlur() {
      this.focused = false;
      setTimeout(() => {
        this.visible = false;
      }, 200);
    },
    handleClear() {
      this.searchValue = "";
    },
    hotwordSearch(keyword: string, e: Event, type: string) {
      this.searchValue = keyword;
      this.visible = false;
      this.searchType = type;
      this.submitSearch();
    },
    submitSearch(e: Event, keyword: string) {
      this.stat("xiaozhan_top_bar_click", {
        pageType: this.page,
        clickid: "search",
      });

      if (keyword) {
        this.searchValue = keyword;
      }

      if (!this.searchValue || !this.searchValue.trim()) {
        return;
      }

      this.stat("xiaozhan_search_start", {
        from: this.page,
        keywords: encodeURIComponent(this.searchValue),
        type: this.searchType,
      });

      const curRouteName = this.$route.name;
      const searchRoutePath = `/search/${this.searchValue}`;

      try {
        let rawKeywords = this.getSearchHistory();
        rawKeywords.unshift(this.searchValue);
        rawKeywords = Array.from(new Set(rawKeywords));
        store.set("keyword_search_history", JSON.stringify(rawKeywords));
        this.searchHistory = rawKeywords;
      } catch (e) {
        console.log("set keyword failed >>>", e);
      }

      if (curRouteName === "search-keyword") {
        store.set("keyword_search_type", this.searchType);
        eventEmitter.emit(eventMap.CONTENT_SEARCH, 0, {
          keyword: this.searchValue,
          from: this.page,
        });
        // this.$router.push(searchRoutePath);
      } else {
        store.set("keyword_search_type", this.searchType);
        window.open(
          `${searchRoutePath}?k_from=${this.page}&${this.queryStr}`,
          "_blank",
          "noopener,noreferrer"
        );
      }
    },
  },
});
