export default {
  call (functionName: string, params: any) {
    return new Promise((resolve, reject) => {
      window.WebViewJavascriptBridge.callHandler(functionName, params, (err: any, value: any) => {
        if (err) {
          reject(err)
        } else {
          resolve(value)
        }
      })
    })
  },
  on (eventName: string, callback: Function) {
    try {
      return window.WebViewJavascriptBridge.addEventListener(eventName, callback)
    } catch (e) {
    }
  },
  off (eventName: string, listenId: any) {
    try {
      return window.WebViewJavascriptBridge.removeEventListener(eventName, listenId)
    } catch (e) {
    }
  }
}
